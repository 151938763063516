import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Text, Button, List, position, margin, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const DisclosureStart = () => {
  const user = useContext(UserContext)
  const { Steps, Loaded, DisclosureFee, DisclosureServiceType } = user
  const userTaskDisclosure = Steps.find(step => step.Step.Id === 4)

  return Loaded ? (
    <Layout>
      <Hero icon="doc">Disclosure application</Hero>

      <ContentWrap pad>
        <Text h2>What is this?</Text>

        <Text>
          Before you begin your employment, we’ll just need to do a simple background check. This applies to
          all employees and can take up to two weeks to process.
        </Text>

        {DisclosureFee > 0 && (
          <Text>
            There will be a deduction of £{DisclosureFee} from your first wage to cover the cost of your
            disclosure application.
          </Text>
        )}

        <Panel bgWhiteSm boxed util={[margin.b2, margin.t4]}>
          <Text h4>Before you start...</Text>

          <Text>To complete this section, you will need to provide the following information:</Text>

          <List tick>
            <List item>Driving licence number</List>

            <List item>Passport number</List>

            <List item>National insurance number</List>

            <List item>Last 5 years address history</List>

            {DisclosureServiceType === 1 && (
              <List item>
                Consent to DBS (Disclosure Barring Service) checks. Find out more about DBS{' '}
                <a href="https://www.gov.uk/government/publications/basic-dbs-check-privacy-policy/basic-dbs-check-privacy-policy" target='_blank' rel="noopener noreferrer">
                  privacy policy
                </a>
              </List>
            )}
          </List>
        </Panel>

        <Text>
          To find out more about how Arnold Clark handle your information please see our{' '}
          <a href="https://www.arnoldclark.com/careers/privacy-policy" target='_blank' rel="noopener noreferrer">
            privacy policy
          </a>
        </Text>

        {userTaskDisclosure.Completed ? (
          <Button
            success
            block
            util={[position.floatRight, width.resetSm]}
            onClick={() => navigate('/disclosure/confirm-complete')}
          >
            Overview
          </Button>
        ) : (
          <Button
            success
            block
            util={[position.floatRight, width.resetSm]}
            onClick={() => navigate('/disclosure/personal-details')}
          >
            Start: Disclosure application
          </Button>
        )}

        <Button
          link
          block
          util={[position.floatRight, margin.t0Sm, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DisclosureStart
