// eslint-disable-next-line react/no-deprecated
import React from 'react'
import PropTypes from 'prop-types'
import { Text, margin, scale } from 'chassis-react'

import './styles.scss'

const OptionLabel = ({ value, label }) => (
  <div className="select-menu-item">
    <Text util={[margin.t2, scale.fs3]}>{label}</Text>
    <Text util={[scale.fs3]}>{value.JobTitle} | {value.Branch}</Text>
  </div>
)

OptionLabel.propTypes = {
  value: PropTypes.shape({
    JobTitle: PropTypes.string.isRequired,
    Branch: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string.isRequired
}

export default OptionLabel;
