import moment from 'moment'

const overviewPersonalDetails = data => {
  const formatDate = d => moment.utc(d).format('DD/MM/YYYY')

  if (!Object.keys(data).length) {
    return null
  }
  if(data.ReferralCode ){
    return [
      {
        dt: 'Title',
        dd: data.Title
      },
      {
        dt: 'Full name',
        dd: `${data.Firstname} ${data.Surname}`
      },
      {
        dt: ' Referral Code',
        dd: `${data.ReferralCode}`
      },
      {
        dt: 'Date of birth',
        dd: formatDate(data.DateOfBirth)
      },
      {
        dt: 'NI Number',
        dd: data.NINumber
      },
      {
        dt: 'Passport Expiry Date',
        dd: data.PassportExpiry ? formatDate(data.PassportExpiry) : 'Not set'
      }
    ]
  }
  return [
    {
      dt: 'Title',
      dd: data.Title
    },
    {
      dt: 'Full name',
      dd: `${data.Firstname} ${data.Surname}`
    },
    {
      dt: 'Date of birth',
      dd: formatDate(data.DateOfBirth)
    },
    {
      dt: 'NI Number',
      dd: data.NINumber
    },
    {
      dt: 'Passport Expiry Date',
      dd: data.PassportExpiry ? formatDate(data.PassportExpiry) : 'Not set'
    }
  ]
  
}
export default overviewPersonalDetails
