import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import auth from '../../auth'

const MagicLink = props => {
  const { token } = props

  auth.processMagicLink(token)
  .then(response => {
    const navigateTo = response.status === 200 && '/update-password' || '/'
    navigate(navigateTo)
    
  })  
  return <div />
}

MagicLink.propTypes = {
  token: PropTypes.string
}

export default MagicLink
