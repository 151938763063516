import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Base } from 'chassis-react'

import './style.scss'

const Panel = props => {
  const { bgGreySm, bgWhite, bgWhiteSm, bordered, borderedSm, boxed, children, util } = props

  const classProps = classNames(
    bordered && 'panel--bordered',
    borderedSm && 'panel--bordered-sm',
    boxed && 'panel--boxed',
    bgWhite && 'panel panel--white',
    bgWhiteSm && 'panel panel--white-sm',
    bgGreySm && 'panel panel--grey-sm'
  )

  return (
    <Base className={classProps} util={util}>
      {children}
    </Base>
  )
}

Panel.propTypes = {
  bgGreySm: PropTypes.bool,
  bgWhite: PropTypes.bool,
  bgWhiteSm: PropTypes.bool,
  bordered: PropTypes.bool,
  borderedSm: PropTypes.bool,
  boxed: PropTypes.bool,
  children: PropTypes.node,
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string])
}

export default Panel
