import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

const RoleCard = props => {
  const { details, heading, icon, link } = props

  return (
    <div
      className={classNames(
        'rolecard',
        icon === 'person' && 'rolecard--icon rolecard--icon-person',
        icon === 'calendar' && 'rolecard--icon rolecard--icon-calendar',
        icon === 'location' && 'rolecard--icon rolecard--icon-location',
        icon === 'mobile' && 'rolecard--icon rolecard--icon-mobile'
      )}
    >
      <p className="rolecard__title">{heading}</p>

      <p className="rolecard__details">
        {link ? (
          <a href={link} target="blank" className="rolecard__link">
            {details}
          </a>
        ) : (
          details
        )}
      </p>
    </div>
  )
}

RoleCard.propTypes = {
  details: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string
}

export default RoleCard
