import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Base, Grid, Text, color, display, padding, scale, margin, lineHeight } from 'chassis-react'
import classNames from 'classnames'
import ContentWrap from '../ContentWrap'

import './style.scss'

const welcomeMessageTime = () => {
  const format = 'hh:mm:ss'
  const morning = moment.utc('00:00:00', format)
  const afternoon = moment.utc('12:00:00', format)
  const evening = moment.utc('17:00:00', format)

  if (moment.utc().isBetween(morning, afternoon)) {
    return 'Good morning'
  }

  if (moment.utc().isBetween(afternoon, evening)) {
    return 'Good afternoon'
  }

  return 'Good evening'
}

const Hero = props => {
  const { children, welcome, preTitleMobile, icon, subheading } = props

  return (
    <Base util={[color.bgGrey2, color.black, padding.v4]} className="hero">
      <Grid container>
        <ContentWrap collapse>
          <Grid
            row
            className={classNames(
              'hero__row',
              icon === 'chat' && 'hero__icon hero__icon--chat',
              icon === 'doc' && 'hero__icon hero__icon--doc',
              icon === 'person' && 'hero__icon hero__icon--person',
              preTitleMobile && 'hero__icon--no-mobile'
            )}
          >
            <Grid colXs={icon ? 9 : 12}>
              {preTitleMobile && (
                <Text className="hero__pre-title" util={[display.noneSm, margin.b1]}>
                  {preTitleMobile}
                </Text>
              )}

              <Text util={[scale.fs6, scale.fs8Sm, lineHeight.heading]} className="hero__title">
                {welcome ? (
                  <Fragment>
                    <span className="hero__title--light">{welcomeMessageTime()},</span>
                    <br /> {children}
                  </Fragment>
                ) : (
                  <Fragment>{children}</Fragment>
                )}
              </Text>
              {!!subheading && subheading}
            </Grid>
          </Grid>
        </ContentWrap>
      </Grid>
    </Base>
  )
}

Hero.propTypes = {
  children: PropTypes.node,
  subheading: PropTypes.node,
  welcome: PropTypes.bool,
  preTitleMobile: PropTypes.string,
  icon: PropTypes.string
}

export default Hero
