import api from '../api'

const userService = {
  updatePersonalDetails: data => {
    const state = {
      UserId: data.Id,
      TitleId: data.TitleId,
      Title: data.Title,
      Firstname: data.Firstname,
      Surname: data.Surname,
      DateOfBirth: data.DateOfBirth,
      NINumber: data.NINumber,
      PassportExpiry: data.PassportExpiry,
      Addresses: [
        {
          Line1: data.Addresses[0].Line1,
          Line2: data.Addresses[0].Line2,
          Town: data.Addresses[0].Town,
          Postcode: data.Addresses[0].Postcode
        }
      ],
      TelNumber: data.TelNumber,
      EmailAddress: data.EmailAddress,
      DisclosureFee: data.DisclosureFee,
      UserType: data.UserType
    }

    return api.newStartPersonalDetails.updateDetails(state)
  },

  loadDisclosureAndShapeState: userId =>
    api.newStartDisclosure.getByUserId(userId).then(({ data }) => {
      const state = {
        Id: data.Id,
        UserId: data.UserId,
        GenderId: data.Gender && data.Gender.Id,
        GenderName: data.Gender && data.Gender.Name,
        TitleId: data.Title && data.Title.Id,
        TitleName: data.Title && data.Title.Name,
        Forename: data.Forename,
        MiddleNames: data.MiddleNames,
        Surname: data.Surname,
        KnownByOtherNames: data.KnownByOtherNames,
        OtherNames: data.OtherNames || [],
        DateOfBirth: data.DateOfBirth,
        TownOfBirth: data.TownOfBirth || '',
        CountryOfBirthId: data.CountryOfBirth && data.CountryOfBirth.Id,
        CountryOfBirthName: data.CountryOfBirth && data.CountryOfBirth.CountryName,
        UkNationalInsuranceNumber: data.UkNationalInsuranceNumber,
        HasPassport: data.HasPassport,
        PassportNumber: data.PassportNumber || '',
        PassportCountryOfIssueId: (data.PassportCountryOfIssue && data.PassportCountryOfIssue.Id) || '',
        PassportCountryOfIssueName:
          (data.PassportCountryOfIssue && data.PassportCountryOfIssue.CountryName) || '',
        HasDrivingLicence: data.HasDrivingLicence,
        DrivingLicenceNumber: data.DrivingLicenceNumber || '',
        DrivingLicenceCountryOfIssueId:
          (data.DrivingLicenceCountryOfIssue && data.DrivingLicenceCountryOfIssue.Id) || '',
        DaytimePhoneNumber: data.DaytimePhoneNumber || '',
        EveningPhoneNumber: data.EveningPhoneNumber || '',
        EmailAddress: data.EmailAddress || '',
        AddressHistory: data.AddressHistory || [],
        MothersMaidenName: data.MothersMaidenName,
        HasNationalIdentityCard: data.HasNationalIdentityCard,
        NationalIdentityCardNumber: data.NationalIdentityCardNumber || '',
        NationalIdentityCardCountryOfIssueId:
          (data.NationalIdentityCardCountryOfIssue && data.NationalIdentityCardCountryOfIssue.Id) || '',
        NationalIdentityCardCountryOfIssueName:
          (data.NationalIdentityCardCountryOfIssue && data.NationalIdentityCardCountryOfIssue.CountryName) ||
          '',
        NationalityId: data.Nationality && data.Nationality.Id,
        NationalityName: data.Nationality && data.Nationality.Name
      }

      return state
    }),

  updateDisclosure: data => api.newStartDisclosure.addDetails(data).then(res => res),

  // wages information

  updateWageInformation: data => {
    const state = {
      Id: data.WageInformation.Id,
      UserId: data.Id,
      EmployeeStatementTypeId: data.WageInformation.EmployeeStatementTypeId,
      StudentBeforeSixthApril: data.WageInformation.StudentBeforeSixthApril,
      HasUnpaidStudentLoan: data.WageInformation.HasUnpaidStudentLoan,
      StudentLoanPaidDirectToStudentLoanCompany:
        data.WageInformation.StudentLoanPaidDirectToStudentLoanCompany,
      StudentLoanTypeId: data.WageInformation.StudentLoanTypeId,
      SortCode: data.WageInformation.SortCode,
      AccountNumber: data.WageInformation.AccountNumber,
      AccountHolder: data.WageInformation.AccountHolder,
      HasUnpaidPostgradLoan: data.WageInformation.HasUnpaidPostgradLoan,
      PostgradStudentBeforeSixthApril: data.WageInformation.PostgradStudentBeforeSixthApril,
      PostgradLoanPaidDirectToStudentLoanCompany:
        data.WageInformation.PostgradLoanPaidDirectToStudentLoanCompany,
      WasUndergraduate: data.WageInformation.WasUndergraduate,
      WasPostgraduate: data.WageInformation.WasPostgraduate
    }

    return api.newStartWageInformation.addWageInfo(state).then(res => res)
  },

  // life assurance

  updateLifeAssurance: (data, id) => {
    const state = {
      UserId: id,
      Beneficiaries: [...data]
    }

    return api.newStartLifeAssurance.addBeneficiaries(state).then(res => res)
  },

  // medical

  updateMedicalDetails: (data, id) => {
    const state = {
      Id: data.Id,
      UserId: id,
      NoDaysAbsentInLastTwelveMonths: data.NoDaysAbsentInLastTwelveMonths,
      HowManyPeriodsOfAbsence: data.HowManyPeriodsOfAbsence,
      DetailsOfEachAbsence: data.DetailsOfEachAbsence,
      CirculatoryProblems: data.CirculatoryProblems,
      CirculatoryProblemsStartDate: data.CirculatoryProblemsStartDate || null,
      CirculatoryProblemsEndDate: data.CirculatoryProblemsEndDate || null,
      CirculatoryProblemsDetails: data.CirculatoryProblemsDetails || null,
      HeartProblems: data.HeartProblems,
      HeartProblemsStartDate: data.HeartProblemsStartDate || null,
      HeartProblemsEndDate: data.HeartProblemsEndDate || null,
      HeartProblemsDetails: data.HeartProblemsDetails || null,
      ChestProblems: data.ChestProblems,
      ChestProblemsStartDate: data.ChestProblemsStartDate || null,
      ChestProblemsEndDate: data.ChestProblemsEndDate || null,
      ChestProblemsDetails: data.ChestProblemsDetails || null,
      EpilepsyOrFainting: data.EpilepsyOrFainting,
      EpilepsyOrFaintingStartDate: data.EpilepsyOrFaintingStartDate || null,
      EpilepsyOrFaintingEndDate: data.EpilepsyOrFaintingEndDate || null,
      EpilepsyOrFaintingDetails: data.EpilepsyOrFaintingDetails || null,
      SkinDisorders: data.SkinDisorders,
      SkinDisordersStartDate: data.SkinDisordersStartDate || null,
      SkinDisordersEndDate: data.SkinDisordersEndDate || null,
      SkinDisordersDetails: data.SkinDisordersDetails || null,
      OperationsOrFractures: data.OperationsOrFractures,
      OperationsOrFracturesStartDate: data.OperationsOrFracturesStartDate || null,
      OperationsOrFracturesEndDate: data.OperationsOrFracturesEndDate || null,
      OperationsOrFracturesDetails: data.OperationsOrFracturesDetails || null,
      AnyCurrentMedication: data.AnyCurrentMedication,
      AnyCurrentMedicationStartDate: data.AnyCurrentMedicationStartDate || null,
      AnyCurrentMedicationDetails: data.AnyCurrentMedicationDetails || null,
      BackArthritisRheumatism: data.BackArthritisRheumatism,
      BackArthritisRheumatismStartDate: data.BackArthritisRheumatismStartDate || null,
      BackArthritisRheumatismEndDate: data.BackArthritisRheumatismEndDate || null,
      BackArthritisRheumatismDetails: data.BackArthritisRheumatismDetails || null,
      BonesJointsTendons: data.BonesJointsTendons,
      BonesJointsTendonsStartDate: data.BonesJointsTendonsStartDate || null,
      BonesJointsTendonsEndDate: data.BonesJointsTendonsEndDate || null,
      BonesJointsTendonsDetails: data.BonesJointsTendonsDetails || null,
      OtherSignificantHealthProblems: data.OtherSignificantHealthProblems,
      OtherSignificantHealthProblemsDetails: data.OtherSignificantHealthProblemsDetails || null,
      WorkedWithHighNoiseLevels: data.WorkedWithHighNoiseLevels,
      WorkedWithHighNoiseLevelsDetails: data.WorkedWithHighNoiseLevelsDetails || null,
      IndustrialInjuryClaim: data.IndustrialInjuryClaim,
      IndustrialInjuryClaimDetails: data.IndustrialInjuryClaimDetails || null,
      AnyOtherConditions: data.AnyOtherConditions,
      ShareWithManagers: data.ShareWithManagers
    }

    return api.newStartMedicalForm.addDetails(state).then(res => res)
  },

  addApprenticeForm: data => {
    const dataTransferObject = {
      Id: +data.Apprentice.Id || 0,
      UserId: data.Id,
      BootSize: +data.Apprentice.BootSize,
      OverallsSize: data.Apprentice.OverallsSize,
      PoloShirtsSize: data.Apprentice.PoloShirtsSize,
      TravelRequired: !!data.Apprentice.TravelRequired,
      TravelStation: data.Apprentice.TravelStation,
      AccommodationRequired: !!data.Apprentice.AccommodationRequired,
      DietaryRequirements: data.Apprentice.DietaryRequirements
    }

    return api.newStartApprentice.addDetails(dataTransferObject).then(res => res)
  },

  // AXA healthcare
  refuseTojoinAxaPrivateHealthCare: Id => {
    const dataTransferObject = {
      UserId: Id,
      OptOut: true,
      Dependents: []
    }
    return api.newStartMedicalInsurance.joinAxaHealthCare(dataTransferObject)
  },

  submitJoinAxaHealthCareWithoutDependents: Id => {
    const dataTransferObject = {
      UserId: Id,
      OptOut: false,
      Dependents: []
    }
    return api.newStartMedicalInsurance.joinAxaHealthCare(dataTransferObject)
  },

  getDependents: Id => api.newStartMedicalInsurance.getDependents(Id),

  submitJoinAxaHealthCareWithDependents: ({ Id, dependentList }) => {
    const dataTransferObject = {
      UserId: Id,
      OptOut: false,
      Dependents: [...dependentList]
    }
    return api.newStartMedicalInsurance.joinAxaHealthCare(dataTransferObject)
  },

  // health and safety
  getNewStartHandArmVibration: Id => api.newStartHandArmVibration.getNewStartHandArmVibration(Id),

  submitAddNewStartHandArmVibration: ({
    entryId,
    Id,
    NewStartHandArmVibrationExposures,
    EverUsedHandheldVibratingToolsInYourJob,
    UseOfPowerToolsVibratingEquipmentOutsideWork,
    UseOfPowerToolsVibratingEquipmentOutsideWorkDetails,
    DiagnosedWithRaynaudsSyndrome,
    DiagnosedWithRaynaudsSyndromeDetails,
    AnyTinglingAfterUsingVibratingEquipment,
    TinglingAnyOtherTime,
    OneOrMoreFingersNumbAfterUsingVibratingEquipment,
    FingersWhiteWhenExposedToCold,
    DifficultyGettingFingersWarm,
    FingersWhiteAtAnyOtherTime,
    AnyOtherProblemsWithMusclesOrJointsOfHandsArms,
    DifficultyPickingUpSmallItemsOrOpeningJars,
    EverHadNeckArmOrHandInjuryOrOperation,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
    OnAnyLongTermMedication,
    EverHadNeckArmOrHandInjuryOrOperationDetails,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
    OnAnyLongTermMedicationDetails,
    NighttimePainTinglingOrNumbness,
    HowOftenDoYouUseThisEquipment,
    FingersWhiteAtAnyOtherTimeDetails
  }) => {
    const dataTransferObject = {
      Id: entryId,
      UserId: Id,
      NewStartHandArmVibrationExposures,
      EverUsedHandheldVibratingToolsInYourJob,
      UseOfPowerToolsVibratingEquipmentOutsideWork,
      UseOfPowerToolsVibratingEquipmentOutsideWorkDetails,
      DiagnosedWithRaynaudsSyndrome,
      DiagnosedWithRaynaudsSyndromeDetails,
      AnyTinglingAfterUsingVibratingEquipment,
      TinglingAnyOtherTime,
      OneOrMoreFingersNumbAfterUsingVibratingEquipment,
      FingersWhiteWhenExposedToCold,
      DifficultyGettingFingersWarm,
      FingersWhiteAtAnyOtherTime,
      AnyOtherProblemsWithMusclesOrJointsOfHandsArms,
      DifficultyPickingUpSmallItemsOrOpeningJars,
      EverHadNeckArmOrHandInjuryOrOperation,
      AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
      OnAnyLongTermMedication,
      EverHadNeckArmOrHandInjuryOrOperationDetails,
      AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
      OnAnyLongTermMedicationDetails,
      NighttimePainTinglingOrNumbness,
      HowOftenDoYouUseThisEquipment,
      FingersWhiteAtAnyOtherTimeDetails
    }
    return api.newStartHandArmVibration.addNewStartHandArmVibration(dataTransferObject)
  },

  submitNeverUsedHandArmVibration: ({
    entryId,
    Id,
    EverUsedHandheldVibratingToolsInYourJob,
    UseOfPowerToolsVibratingEquipmentOutsideWork,
    HowOftenDoYouUseThisEquipment
  }) => {
    const dataTransferObject = {
      Id: entryId,
      UserId: Id,
      UseOfPowerToolsVibratingEquipmentOutsideWork,
      EverUsedHandheldVibratingToolsInYourJob,
      HowOftenDoYouUseThisEquipment,
      NewStartHandArmVibrationExposures: [],
      UseOfPowerToolsVibratingEquipmentOutsideWorkDetails: null,
      DiagnosedWithRaynaudsSyndrome: null,
      DiagnosedWithRaynaudsSyndromeDetails: null,
      AnyTinglingAfterUsingVibratingEquipment: null,
      TinglingAnyOtherTime: null,
      OneOrMoreFingersNumbAfterUsingVibratingEquipment: null,
      FingersWhiteWhenExposedToCold: null,
      DifficultyGettingFingersWarm: null,
      FingersWhiteAtAnyOtherTime: null,
      AnyOtherProblemsWithMusclesOrJointsOfHandsArms: null,
      DifficultyPickingUpSmallItemsOrOpeningJars: null,
      EverHadNeckArmOrHandInjuryOrOperation: null,
      AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels: null,
      OnAnyLongTermMedication: null,
      EverHadNeckArmOrHandInjuryOrOperationDetails: null,
      AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails: null,
      OnAnyLongTermMedicationDetails: null,
      NighttimePainTinglingOrNumbness: null,
      FingersWhiteAtAnyOtherTimeDetails: null
    }
    return api.newStartHandArmVibration.addNewStartHandArmVibration(dataTransferObject)
  }
}

export default userService
