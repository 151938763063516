import * as yup from 'yup'

const requiredMessage = 'This field is required'
const maxLengthMessage = max => `This field must be no more than ${max} characters`

const uniformSchema = {
  BootSize: yup
    .number()
    .required(requiredMessage)
    .min(3)
    .max(15)
    .typeError(requiredMessage),
  OverallsSize: yup
    .string()
    .required(requiredMessage)
    .typeError(requiredMessage),
  PoloShirtsSize: yup
    .string()
    .required(requiredMessage)
    .typeError(requiredMessage)
}

const trainingSchema = {
  TravelRequired: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  TravelStation: yup.string().when('TravelRequired', {
    is: true,
    then: yup
      .string()
      .required(requiredMessage)
      .max(100, maxLengthMessage(100))
  }),
  AccommodationRequired: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage)
}

export const apprenticeSchema = yup.object().shape({ ...uniformSchema, ...trainingSchema })
