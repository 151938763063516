import moment from 'moment'

export const totalYearsRequired = 5

export const checkGapsExistInHistory = (history, upTo) => {
  if (history.length === 0) {
    return false
  }
  if (history.every(address => upTo.format('YYYY-MM-DD') > address.DateTo)) {
    return true
  }

  const dateSets = history.map(address => [moment.utc(address.DateFrom), moment.utc(address.DateTo)])
  let gapExists = false
  for (let i = 0; i < dateSets.length - 1; i += 1) {
    const difference = dateSets[i][0].diff(dateSets[i + 1][1], 'days')

    if (difference > 1) {
      gapExists = true
    }
  }
  return gapExists
}

export const getYears = (fromDate, toDate, adjust) => {
  const fromDateMoment = moment.utc(fromDate)
  const toDateMoment = moment.utc(toDate)
  if (adjust) {
    fromDate = fromDateMoment.add(-1, 'days') // to account for each day between addresses add a day
  }
  const diff = toDateMoment.diff(fromDateMoment, 'years', true)
  return diff
}

export const checkRemainingYearsRequiredAddressHistory = (history, yearsRequired, upTo) => {
  const upToFiveYearsAgo = moment(upTo).add(-yearsRequired, 'years')

  const fiveYearsHistory = history.filter(address => 
    upToFiveYearsAgo.isSameOrBefore(address.DateTo)
  )

  if (fiveYearsHistory.length === 0) {
    return yearsRequired
  }
  // Code assumption is latest DateTo is same or after upTo
  const earliestFromDate = fiveYearsHistory.reduce((prev, current) => 
    (prev.DateFrom < current.DateFrom) ? prev : current  
  ).DateFrom;
  
  const earliestFromDateMoment = moment(earliestFromDate);
  if (earliestFromDateMoment <= upToFiveYearsAgo) {
    return 0;
  }
  const remaining = earliestFromDateMoment.diff(upToFiveYearsAgo, 'years', true);
  
  const rounded = Math.ceil(remaining)
  
  return rounded
}

