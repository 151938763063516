import auth from '../../../../auth'
import accessRole from '../../../../services/accessRole'

export const getNextRoute = (currentRoute, userId) => {
  const isAdmin = auth.getSessionValue('IsAdmin') && accessRole.isProfileEditor()
  const routes = ['personal-details', 'birth-details', 'additional-information', 'contact-information']

  const currentRouteIndex = routes.findIndex(route => route === currentRoute)

  const basepath = isAdmin ? `/admin/new-start/${userId}/disclosure` : '/disclosure'
  const nextRoute = routes[currentRouteIndex + 1]

  return `${basepath}/${nextRoute}`
}
