import React, { useContext } from 'react'
import moment from 'moment'
import { Base, Button, Text, border, color, margin, padding, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import DetailsList from '../../../../components/DetailsList'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'

const Details = () => {
  const user = useContext(UserContext)
  const { Addresses, Steps } = user
  const { Line1, Line2, Town, Postcode } = Object.assign({}, Addresses[0])
  const userTaskPersonalDetails = Steps.find(step => step.Step.Id === 2)

  const personalDetails = [
    {
      dt: 'Title',
      dd: user.Title
    },
    {
      dt: 'Full name',
      dd: `${user.Firstname} ${user.Surname}`
    },
    {
      dt: 'Date of birth',
      dd: moment.utc(user.DateOfBirth).format('DD/MM/YYYY')
    },
    {
      dt: 'NI Number',
      dd: user.NINumber
    }
  ]

  const addressDetails = [
    {
      dd: Line1
    },
    {
      dd: Line2
    },
    {
      dd: Town
    },
    {
      dd: Postcode
    }
  ]

  const contactDetails = [
    {
      dt: 'Telephone number',
      dd: user.TelNumber
    },
    {
      dt: 'Email address',
      dd: user.EmailAddress
    }
  ]

  return user.Loaded ? (
    <Layout>
      <Hero icon="person" preTitleMobile="Step 2 of 9">
        Your personal details
      </Hero>

      <ContentWrap pad>
        <Text h4 as="h1">
          Check your details before submitting
        </Text>

        <Base util={[padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
          <DetailsList
            title="Personal details"
            editLink="/details/edit-personal"
            listItems={personalDetails}
          />

          <DetailsList title="Address details" editLink="/details/edit-address" listItems={addressDetails} />

          <DetailsList title="Contact details" editLink="/details/edit-contact" listItems={contactDetails} />
        </Base>

        <Button
          success
          block
          util={[width.resetSm, position.floatRight, margin.t3]}
          onClick={() => user.handleUpdateStep(userTaskPersonalDetails.Id)}
        >
          Confirm personal details
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Details
