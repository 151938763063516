// eslint-disable-next-line react/no-deprecated
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { width, Form, Base, margin } from 'chassis-react'
import buildStyles from './selectStyles'
import OptionLabel from './OptionLabel'
import InputContainer from './InputContainer'

const ManagerSelect = ({ options, selectedOption, formId, selectionChanged, error }) => {
  const customStyles = buildStyles({ hasError: Boolean(error)})
  return (
    <Base util={[width.w8Sm, margin.b2]}>
      <Form label htmlFor={formId}>Line Manager *</Form>
      <Form group error={!!error}>
        {error && <Form validation>{error}</Form>}
      </Form>
      <Select
        styles={customStyles}
        components={{
          ValueContainer: InputContainer,
          IndicatorSeparator: () => null
        }}
        options={options}
        placeholder="Who will their line manager be?"
        formatOptionLabel={OptionLabel}
        onChange={selectionChanged}
        name="MangerId"
        value={selectedOption}
        isSearchable
        isClearable
      />
      
    </Base>
  )
}

const { string, number, arrayOf, shape, func } = PropTypes
const optionPropType = shape({
  value: shape({
    Id: number.isRequired,
    Firstname: string.isRequired,
    Surname: string.isRequired
  }),
  label: string.isRequired
})

ManagerSelect.propTypes = {
  options: arrayOf(optionPropType).isRequired,
  selectedOption: optionPropType,
  selectionChanged: func.isRequired,
  formId: string.isRequired,
  error: string
}

export default ManagerSelect;
