const valueFormat = (dataset, val) => {
  if (dataset === 'int') {
    return +val
  }
  if (dataset === 'bool') {
    return JSON.parse(val)
  }
  return val
}

export default valueFormat
