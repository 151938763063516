import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Button, List, Text, display, margin, padding, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const HealthAndSafety = () => {
  const user = useContext(UserContext)
  const { Loaded, HandArmVibration } = user
  
  const { Id } = HandArmVibration

  return Loaded ? (
    <Layout>
      <Hero icon="doc" preTitleMobile="Step 10 of 10">
        HAVs form
      </Hero>

      <ContentWrap util={[padding.v3, padding.v5Sm]}>
        <Text h4 as="h4" util={[margin.b2, margin.t4]}>
          Hand-arm vibration syndrome (HAVs) is:
        </Text>

        <List util={[margin.b4, margin.b0Sm, margin.l2Sm]}>
          <List item>
            A disorder which affects the blood vessels, nerves, muscles and joints of the hand, wrist and arm.
          </List>
          <List item>Can become severely disabling if ignored.</List>
          <List item>
            It’s best known form is vibration white finger (VWF) which can be triggered by cold or wet weather
            and can cause severe pain in the affected fingers.
          </List>
        </List>

        <Text h4 as="h4" util={[margin.b2, margin.t4]}>
          Signs to look out for in hand-arm vibration syndrome:
        </Text>

        <List util={[margin.b4, margin.b0Sm, margin.l2Sm]}>
          <List item>Tingling and numbness in the fingers.</List>
          <List item>In the cold and wet, fingers go white, then blue, then red and are painful.</List>
          <List item>You can’t feel things with your fingers</List>
          <List item>Pain, tingling or numbness in your hands, wrists and arms.</List>
          <List item>Loss of strength in your hands.</List>
        </List>

        <Panel bgWhiteSm boxed util={[margin.b2, margin.t4]}>
          <Text h4 as="h4">
            Before you start…
          </Text>

          <Text>Before you complete the next step, please advise if:</Text>

          <List tick util={[margin.b4, margin.b0Sm]}>
            <List item>
              You have been using hand-held vibrating tools, machines or hand-fed processes in your job – or
              if this is a review – since your last assessment.
            </List>
            <List item>If have been, please prepare to fill out a detail work history</List>
          </List>
        </Panel>

        <Button
          success
          util={[
            margin.b2,
            margin.b0Sm,
            margin.l2Sm,
            display.block,
            display.inlineBlockSm,
            width.w12,
            width.resetSm,
            position.floatRight
          ]}
          onClick={() =>
            Id === 0 ? navigate('/health-and-safety/entry') : navigate('/health-and-safety/confirm')
          }
        >
          {Id === 0 ? 'Start: HAVs form' : 'Overview'}
        </Button>

        <Button
          link
          util={[position.floatRight, width.w12, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default HealthAndSafety
