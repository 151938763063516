import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import ModalContainer from './ModalContainer'
import './style.scss'


class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.modalRoot = document.getElementById('modal')
    this.el = document.createElement('div')
  }

  componentDidMount() {
    this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el)
  }

  render() {
    const { children, onClick, title } = this.props

    return createPortal(
      <ModalContainer onClick={onClick} title={title}>
        {children}
      </ModalContainer>,

      this.el
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.instanceOf(Function),
  title: PropTypes.string
}

export default Modal
