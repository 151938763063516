import React from 'react'
import PropTypes from 'prop-types'

import { Alert, Grid, List, position } from 'chassis-react'

const ValidationAlertList = ({ errors }) =>
  errors.length > 0 && (
    <Alert wide danger>
      <Grid container>
        <Grid row>
          <Grid colMd={12} util={position.centered}>
            <List>
              {errors.map((item, i) => (
                <List item key={i}>
                  {item.message}
                </List>
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Alert>
  )

ValidationAlertList.propTypes = {
  errors: PropTypes.instanceOf(Array)
}

export default ValidationAlertList
