import React, { useContext, useState, useEffect } from 'react'
import { Button, Form, Text, List, padding, margin, position, width, Alert } from 'chassis-react'

import { navigate } from '@reach/router'
import { UserContext } from '../../../../context/UserContext'

import Layout from '../../../../components/Layout'
import Spinner from '../../../../components/Spinner'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Modal from '../../../../components/Modal'
import Panel from '../../../../components/Panel'
import DocumentAccept from './DocumentAccept'
import api from '../../../../api'
import envelope from '../../../../static/images/icons/envelope.svg'

const ContractDocuments = () => {
  const user = useContext(UserContext)

  const [documents, setDocuments] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [emailDocsClicked, setEmailDocsClicked] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const userTaskSignDocuments = user.Steps.find(step => step.Step.Id === 3)

  useEffect(() => {
    setDocuments(user.Documents)
  })

  const allSigned = documents.every(document => document.DateSigned)

  const handleSubmit = () => {
    setShowModal(true)
  }

  const onConfirm = e => {
    e.preventDefault()
    user.handleUpdateStep(userTaskSignDocuments.Id)
  }

  const handleEmailDocuments = e => {
    e.preventDefault()
    setEmailDocsClicked(true)
    api.newStartDocument.emailDocuments(user.Id).then(res => {
      if (res.status === 200) {
        setEmailSent(true)
      }
    })
  }

  return (
    <Layout>
      {showModal && (
        <Modal util={[margin.b5]} onClick={() => setShowModal(!showModal)}>
          <Text h3>Please confirm you have read and accepted your documents.</Text>

          <Text h4>We suggest that you download your documents for your own records.</Text>

          <Form util={[margin.t6, margin.b3]}>
            <Form
              checkbox
              onClick={() => {
                setIsChecked(!isChecked)
              }}
              defaultChecked={isChecked}
            >
              I confirm that I have read and accept all of the documents.
            </Form>
          </Form>

          <Form group>
            <Button util={[position.floatRight]} success disabled={!isChecked} onClick={onConfirm}>
              Confirm
            </Button>

            <Button link util={[position.floatRight]} onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Form>
        </Modal>
      )}
      <Hero icon="doc">Your documents</Hero>
      <ContentWrap pad>
        <Panel borderedSm bgWhiteSm util={[margin.b3, padding.a3Sm]}>
          <Text h3>What do I need to do?</Text>

          <List tick>
            <List item>Read your documents carefully</List>
            <List item>Accept each document</List>
            <List item>Confirm you have read your documents</List>
            <List item>
              <strong>Email or download your documents for your own records</strong>
            </List>
          </List>
        </Panel>

        {emailSent && (
          <Alert icon="tick" success util={[width.w12, width.w6Sm]}>
            Documents sent to {user.EmailAddress}
          </Alert>
        )}

        <Button
          disabled={emailDocsClicked}
          util={[width.w12, width.resetSm, margin.r2Sm, margin.v2]}
          onClick={handleEmailDocuments}
        >
          <img src={envelope} alt="" /> Email documents
        </Button>

        <div id="documents">
          {!documents.length && <Spinner />}

          {documents.map((item, index) => (
            <DocumentAccept
              key={item.Id}
              // unfortunately we can't access key as a prop
              id={item.Id}
              title={item.Title}
              dateSigned={item.DateSigned}
              onClick={e => {
                e.preventDefault()
                api.newStartDocument.sign(item.Id).then(res => {
                  // using map rather than splice here ensures that only
                  // the changed item re-renders
                  setDocuments(
                    documents.map((doc, j) => {
                      if (index === j) {
                        doc.DateSigned = res.data.DateSigned
                      }
                      return doc
                    })
                  )
                })
              }}
            />
          ))}
        </div>

        <Button
          success
          disabled={!allSigned}
          util={[position.floatRightSm, width.w12, width.resetSm]}
          onClick={handleSubmit}
        >
          {allSigned ? 'Confirm my documents' : 'Accept all to confirm'}
        </Button>

        <Button
          link
          util={[position.floatRightSm, width.w12, width.resetSm, margin.t2, margin.t0Sm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  )
}

ContractDocuments.propTypes = {}

export default ContractDocuments
