import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { Text, margin, display, Base, color, border, position, padding, width } from 'chassis-react'

import './style.scss'

const DetailsList = props => {
  const { title, editLink, listItems, noborder } = props
  return (
    <Base util={margin.b5}>
      <Base util={[border.b0Sm, border.b1, color.bcGrey3, margin.b0Sm, margin.b3]}>
        {title && (
          <Text h3 as="h2" util={display.inlineBlock}>
            {title}
          </Text>
        )}

        {editLink && (
          <Text as={Link} to={editLink} util={[position.floatRight]}>
            Edit
          </Text>
        )}
      </Base>

      {listItems && (
        <Base
          className="details-list"
          util={noborder ? [color.bcGrey3, width.w10] : [padding.l3Sm, border.l1Sm, color.bcGrey3, width.w10]}
        >
          {listItems.map((item, i) => (
            <Fragment key={i}>
              {item && item.dt && <dt className="details-list__dt">{item.dt}</dt>}
              {item && item.dd && <dd className="details__dd">{item.dd}</dd>}
            </Fragment>
          ))}
        </Base>
      )}
    </Base>
  )
}

DetailsList.propTypes = {
  title: PropTypes.string,
  noborder: PropTypes.bool,
  editLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  listItems: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool])
}

export default DetailsList
