import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Base, border, Button, color, margin, padding, position, scale, Text} from 'chassis-react'
import { navigate } from '@reach/router'
import moment from 'moment'

import api from '../../../api'

import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import Layout from '../../../components/Layout'
import accessRole from '../../../services/accessRole'

const ViewInvite = (props) => {
  if (!accessRole.allow(['recruitment', 'itDev'])) return null

  const { id } = props
  const [inviteData, setInviteData] = useState(null)
  const [user, setUser] = useState(null)
  const [loaded, setLoaded] = useState(false)
 
  const name = user && `${user.Firstname} ${user.Surname}`

  const expiryTime = () => {
    if(!inviteData) return "Not Set"
    return moment(inviteData.ExpiryDate).format("ddd Do MMM YYYY")
  }

  useEffect(() => {
    (async () => {
      await api.newStart.getInviteLink(id).then(({ data }) => setInviteData(data))
      await api.newStart.getById(id).then(({ data }) => setUser(data))
      setLoaded(true)
    })()
  }, [])
  
  return loaded ? (
    <Layout>
 
      <ContentWrap util={margin.t3}>
        <Text h1 util={scale.fs8}>
          View Invite URL for {name}
        </Text>
      </ContentWrap>

      <ContentWrap pad>
        {!inviteData.HasCurrent && (
          <Base util={[margin.b3, padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
            <Text h5>There is no invite Currently available</Text>
          </Base>
        )}
        {inviteData.HasCurrent && (
          <Base util={[margin.b3, padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
            <Text h5>Current Invitation URL:</Text>
            <Text p>{inviteData.InviteUri}</Text>
            <Text small>This URL will expire on: {expiryTime()}. Sending a new invite will invalidate this URL.</Text>
          </Base>
        )}

        <Button 
          secondary
          onClick={() => navigate(`/admin/new-start/${id}/overview`)}
          util={[margin.r3, position.floatRight]}
        >
          Return
        </Button>
      </ContentWrap>
    </Layout>
  ):(
    <ContentLoader />
  )
}

ViewInvite.propTypes = {
  id: PropTypes.string
}

export default ViewInvite