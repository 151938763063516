import React from 'react'
import PropTypes from 'prop-types'
import { Text, margin } from 'chassis-react'

const ModalContainer = props => {
  const { children, title, footer, onClick } = props

  return (
    <div className="modal-container">
      <div className="modal-container__inner">
        {title && (
          <div className="modal-container__header">
            <Text h4>{title}</Text>
          </div>
        )}

        <div>{children}</div>

        {footer && (
          <div className="modal-container__footer">
            <Text h4 util={margin.b0}>
              {footer}
            </Text>
          </div>
        )}
      </div>

      <button type="button" className="modal-container__close" onClick={onClick} />
    </div>
  )
}

ModalContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  footer: PropTypes.node,
  onClick: PropTypes.instanceOf(Function)
}

export default ModalContainer
