import React from 'react'
import PropTypes from 'prop-types'

import auth from '../../auth'

const EmpAuth = props => {
  const { location } = props

  auth.adminLogin(location)

  return <div />
}

EmpAuth.propTypes = {
  location: PropTypes.instanceOf(Object)
}

export default EmpAuth
