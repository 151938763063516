import React from 'react'
import { Router } from '@reach/router'

import UserContainer from '../../containers/UserContainer'

import Account from './Steps/Account'
import ApprenticeRoot from './Steps/Apprentice'
import Apprentice from './Steps/Apprentice/Apprentice'
import AuthGate from '../../components/AuthGate'
import ContactRoot from './Contact/index'
import ContactForm from './Contact/ContactForm'
import ContactThanks from './Contact/ContactThanks'
import Dashboard from './Dashboard'
import Details from './Steps/Details/Details'
import DetailsEditAddress from './Steps/Details/DetailsEditAddress'
import DetailsEditContact from './Steps/Details/DetailsEditContact'
import DetailsEditPersonal from './Steps/Details/DetailsEditPersonal'
import DetailsRoot from './Steps/Details'
import DisclosureAdditionalInformation from './Steps/Disclosure/DisclosureAdditionalInformation'
import DisclosureBirthDetails from './Steps/Disclosure/DisclosureBirthDetails'
import DisclosureConfirmComplete from './Steps/Disclosure/DisclosureConfirmComplete'
import DisclosureContactInformation from './Steps/Disclosure/DisclosureContactInformation'
import DisclosurePersonalDetails from './Steps/Disclosure/DisclosurePersonalDetails'
import DisclosureRoot from './Steps/Disclosure'
import DisclosureStart from './Steps/Disclosure/DisclosureStart'
import LifeAssurance from './Steps/LifeAssurance/LifeAssurance'
import LifeAssuranceConfirm from './Steps/LifeAssurance/LifeAssuranceConfirm'
import LifeAssuranceDetails from './Steps/LifeAssurance/LifeAssuranceDetails'
import LifeAssuranceRoot from './Steps/LifeAssurance'

import Medical from './Steps/Medical/Medical'
import MedicalAbsenceDetails from './Steps/Medical/MedicalAbsenceDetails'
import MedicalConfirm from './Steps/Medical/MedicalConfirm'
import MedicalHistory from './Steps/Medical/MedicalHistory'
import MedicalRoot from './Steps/Medical'

import AxaHealthcare from './Steps/AxaHealthcare/AxaHealthcare'
import AxaHealthcareDetails from './Steps/AxaHealthcare/Details'
import AxaHealthcareConfirm from './Steps/AxaHealthcare/Confirm'
import AxaHealthcareRoot from './Steps/AxaHealthcare'

import HealthAndSafetyRoot from './Steps/HealthAndSafety'
import HealthAndSafety from './Steps/HealthAndSafety/HealthAndSafety'
import OccupationalHistory from './Steps/HealthAndSafety/OccupationalHistory'
import HealthQuestion1 from "./Steps/HealthAndSafety/HealthQuestion1"
import HealthQuestion2 from "./Steps/HealthAndSafety/HealthQuestion2"
import HealthAndSafetyConfirm from './Steps/HealthAndSafety/Confirm'
import Entry from './Steps/HealthAndSafety/Entry'

import UpdatePassword from './UpdatePassword'
import WagesConfirmDetails from './Steps/WagesInformation/WagesConfirmDetails'
import WagesEmployeeStatement from './Steps/WagesInformation/WagesEmployeeStatement'
import WagesLanding from './Steps/WagesInformation/WagesLanding'
import WagesPaymentDetails from './Steps/WagesInformation/WagesPaymentDetails'
import WagesRoot from './Steps/WagesInformation'
import WagesStudentLoan from './Steps/WagesInformation/WagesStudentLoan'
import ContractDocuments from './Steps/ContractDocuments'
import PrivacyPolicy from './PrivacyPolicy'
import ThankYou from './ThankYou'

const UserPortal = () => (
  <UserContainer>
    <AuthGate userAccess>
      <Router primary={false}>
        <Dashboard path="/" />

        <ContactRoot path="/contact">
          <ContactForm path="/" />
          <ContactThanks path="/thanks" />
        </ContactRoot>

        <PrivacyPolicy path="/privacy-policy" />

        <UpdatePassword path="/update-password" />

        <Account path="/account" />

        <ContractDocuments path="/contract-documents" />

        <DetailsRoot path="/details">
          <Details path="/" />
          <DetailsEditPersonal path="/edit-personal" />
          <DetailsEditAddress path="/edit-address" />
          <DetailsEditContact path="/edit-contact" />
        </DetailsRoot>

        <ContactRoot path="/contract">
          <ContactForm path="/" />
          <ContactThanks path="/Thanks" />
        </ContactRoot>

        <DisclosureRoot path="/disclosure/">
          <DisclosureStart path="/" />
          <DisclosurePersonalDetails path="/personal-details" />
          <DisclosureBirthDetails path="/birth-details" />
          <DisclosureAdditionalInformation path="/additional-information" />
          <DisclosureContactInformation path="/contact-information" />
          <DisclosureConfirmComplete path="/confirm-complete" />
        </DisclosureRoot>

        <WagesRoot path="/wages">
          <WagesLanding path="/" />
          <WagesEmployeeStatement path="/employee-statement" />
          <WagesStudentLoan path="/student-loan" />
          <WagesPaymentDetails path="/payment-details" />
          <WagesConfirmDetails path="/confirm-details" />
        </WagesRoot>

        <LifeAssuranceRoot path="/life-assurance">
          <LifeAssurance path="/" />
          <LifeAssuranceDetails path="/details" />
          <LifeAssuranceConfirm path="/confirm" />
        </LifeAssuranceRoot>

        <MedicalRoot path="/medical">
          <Medical path="/" />
          <MedicalAbsenceDetails path="/absence-details" />
          <MedicalHistory path="/history" />
          <MedicalConfirm path="/confirm" />
        </MedicalRoot>

        <AxaHealthcareRoot path="/axa-healthcare">
          <AxaHealthcare path="/" />
          <AxaHealthcareDetails path="/details" />
          <AxaHealthcareConfirm path="/confirm" />
        </AxaHealthcareRoot>

        <HealthAndSafetyRoot path="/health-and-safety">
          <HealthAndSafety path="/" />
          <OccupationalHistory path="/occupational-history" />
          <HealthQuestion1 path="/health-question-1" />
          <HealthQuestion2 path="/health-question-2" />          
          <HealthAndSafetyConfirm path="/confirm" /> 
          <Entry path="/entry" />
        </HealthAndSafetyRoot>

        <ApprenticeRoot path="/apprentice">
          <Apprentice path="/" />
        </ApprenticeRoot>

        <ThankYou path="/thank-you" />
      </Router>
    </AuthGate>
  </UserContainer>
)

export default UserPortal
