// This is necessary because chassis-react is not currently doing React.forwardRef.
// If it does support this at some point, this should be replaced with refs.

export default () => {
  setTimeout(() => {
    try {
      const el = 
        document.querySelector('div[class*=--error__] input, div[class*=--error__] select, div[class*=--error__] textarea') ||
        document.querySelector('div[class*=alert--danger]') ||
        document.querySelector('#errorList')
  
      if (el.tagName.toLowerCase() === 'div') {
        el.scrollIntoView()
  
      }else{
        el.focus()
      }
  
    // eslint-disable-next-line no-empty
    } catch(error) {}
  }, 50)
}
