const overviewSenderDetails = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'First name',
    dd: data.SenderFirstname
  },
  {
    dt: 'Surname',
    dd: data.SenderSurname
  },
  ...[data.SenderJobTitle && {
    dt: 'Job title',
    dd: data.SenderJobTitle
  }]
]
}
export default overviewSenderDetails
