import React, { useState, useContext } from 'react'
import { Accordion, Text, padding, Form, position, Button, margin, List } from 'chassis-react'
import { navigate } from '@reach/router'
import { contactSchema } from './schema'
import api from '../../../api/index'

import ContentWrap from '../../../components/ContentWrap'
import Hero from '../../../components/Hero'
import Layout from '../../../components/Layout'
import ValidationAlertList from '../../../components/ValidationAlertList'
import { UserContext } from '../../../context/UserContext'

const ContactForm = () => {
  const user = useContext(UserContext)

  const [message, setMessage] = useState('')
  const [ValidationErrors, setValidationErrors] = useState([])

  const handleClick = e => {
    e.preventDefault()
    setValidationErrors([])

    contactSchema
      .validate({ message })
      .then(() => {
        api.feedback.send({ message }).then(() => navigate('/contact/thanks'))
      })
      .catch(error => setValidationErrors([error]))
  }

  return (
    <Layout>
      <Hero icon="chat">Contact & FAQs</Hero>
      <ValidationAlertList errors={ValidationErrors} />

      <ContentWrap pad>
        <Text h2>FAQs</Text>

        <Accordion>
          <Accordion item summary="Why do I need to complete a disclosure?">
            <Text>
              All new Arnold Clark employees are asked to complete a disclosure application. This will be sent
              on your behalf from our Recruitment department and they will let you know when the results come
              back.
            </Text>

            <Text>
              Disclosure Scotland or the Disclosure Barring Service can take up to 14 working days to provide
              your result.
            </Text>
          </Accordion>

          <Accordion item summary="Why does my offer letter advise me to save a copy of my documents?">
            <Text>
              Arnold Clark only keep your Digital Onboarding profile active for 4 weeks after you’ve started
              so after this time you won’t be able to login. This is why we ask everyone to save a copy of all
              documents for future reference.
            </Text>

            <Text>
              Disclosure Scotland or the Disclosure Barring Service can take up to 14 working days to provide
              your result.
            </Text>
          </Accordion>

          <Accordion item summary="Is there a dress code?">
            <Text>
              Depending on which area of the business you’re joining, a uniform may be supplied. On your first
              day you will be given access to ACE and you can then check your uniform allowance and order.
            </Text>

            <Text>Your recruiter will let you know what to wear before you start.</Text>
          </Accordion>

          <Accordion item summary="I’m starting as a Product Consultant. When will I get my uniform?">
            <Text>
              Your uniform order will be sent directly to the branch so you’ll be able to collect this when
              you start. If you haven’t received your uniform order once you have started then please contact
              the Procurement department.
            </Text>
          </Accordion>

          <Accordion item summary="I'm required to wear PPE whilst on shift. When will I get this?">
            <Text>
              Your PPE order will be sent directly to the branch so you’ll be able to collect this when you
              start. If you haven’t received your uniform order once you have started then please contact the
              Procurement department.
            </Text>
          </Accordion>

          <Accordion item summary="What should I expect from my first day?">
            <Text>
              You’ll be given an onsite induction and introduced to your colleagues. You will also be set up
              with access to ACE, where you can check out some of the latest company news, plus all the
              features and benefits that are now available to you.
            </Text>
          </Accordion>

          <Accordion item summary="When do I get my first wage?">
            <Text>
              Your first wage is determined by your start date. If you start before the 20th of the month, you
              will be paid on the last working day of the month.
            </Text>
          </Accordion>

          <Accordion item summary="I don't want to be enrolled in the pension scheme. How can I opt-out?">
            <Text>
              All new employees are automatically enrolled into the pension scheme. If you do not wish to be
              in the pension scheme, once you start please contact our Payroll department and they can
              organise to have you removed.
            </Text>
          </Accordion>

          <Accordion item summary={`I've completed all my ${user.Steps.length} steps. What next?`}>
            <Text>
              There is nothing further you are required to do, unless you hear from us. Please start on the
              date agreed. Good Luck!
            </Text>
          </Accordion>

          <Accordion item summary="Do I need to print, sign and return my documents?">
            <Text>
              No, as you electronically accept your offer documentation there is no need to print, sign and
              return your documents. Just ensure you save a copy for your own future reference.
            </Text>
          </Accordion>

          <Accordion item summary="I can’t view my offer documents?">
            <Text>
              Please ensure you’re using either Google Chrome or Safari as your web browser as other browsers
              aren’t supported. If you’re still having issues using Google Chrome please try the following:
            </Text>
            <List util={margin.t2}>
              <List item>Type `chrome://flags` in address bar and press return</List>
              <List item>Search for `PDF Isolation`</List>
              <List item>Disable this option and restart Chrome</List>
            </List>
          </Accordion>

          <Accordion item summary="How do I change my start date?">
            <Text>
              If you’d like to change your start date from the original date you were supplied with, please
              get in touch with Recruitment to discuss.
            </Text>
          </Accordion>

          <Accordion item summary="Who do I contact if I have any questions after I start?">
            <Text>
              If you have any questions, you can ask your line manager or send them to your Think Employee
              chairperson by emailing{' '}
              <a href="mailto:think.employee@arnoldclark.com">think.employee@arnoldclark.com</a>.
            </Text>
          </Accordion>
        </Accordion>

        <Text h3 util={padding.t4}>
          Got a question?
        </Text>

        <Text>
          We know starting a new job can be a daunting experience. That’s why at Arnold Clark, we want to make
          sure you are fully prepared and excited about starting your new job. To help you, we’ve put together
          a list of our most frequently asked questions which may help calm any first day nerves.
        </Text>

        <Text>
          If you don’t find what you’re looking for here, don’t worry - just pop your question below and one
          of our Recruitment team will get back to you.
        </Text>

        <Form>
          <Form group>
            <Form label>Your message</Form>

            <Form textarea placeholder="Type your message..." onChange={e => setMessage(e.target.value)} />
          </Form>

          <Button onClick={handleClick} success type="submit" util={position.floatRight}>
            Send
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  )
}

export default ContactForm
