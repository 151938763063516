const toTitleCase = str => {
  if (str !== null) {
    str = str.toLowerCase().split(' ')
    for (let i = 0; i < str.length; i += 1) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
  }
  return null
}

export default toTitleCase
