import React from 'react'
import PropTypes from 'prop-types'
import { Base, color } from 'chassis-react'

const FormDivider = props => {
  const { util } = props

  const hasUtil = []

  hasUtil.push(util)

  return <Base as="hr" util={[color.bcGrey3, ...hasUtil]} />
}

FormDivider.propTypes = {
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string])
}

export default FormDivider
