import React from 'react'
import PropTypes from 'prop-types'
import { Base, Text, Grid, border, color, padding } from 'chassis-react'
import classNames from 'classnames'

import './style.scss'

const InfoBox = props => {
  const { children, title, icon } = props

  return (
    <Base
      util={[border.a1, color.bgWhite, color.bcGrey3, padding.a2, padding.a2Sm]}
      className={classNames(
        'info-box',
        icon === 'question' && ' info-box--question',
        icon === 'mobile' && ' info-box--mobile'
      )}
    >
      <Text h4>{title}</Text>

      <Grid row>
        <Grid colXs={8}>{children}</Grid>
      </Grid>
    </Base>
  )
}

InfoBox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  icon: PropTypes.string
}

export default InfoBox
