import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Button, List, Text, display, margin, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const WagesLanding = () => {
  const user = useContext(UserContext)
  const userTaskWageInfo = user.Steps.find(step => step.Step.Id === 5)

  return user.Loaded ? (
    <Layout>
      <Hero preTitleMobile="Step 6 of 9" icon="doc">
        Wages information
      </Hero>

      <ContentWrap pad>
        <Text h4>Why do we need this information?</Text>

        <Text>
          To make sure you get paid quickly and efficiently and you’re paying the right tax contributions,
          we’ll need to set up your details on our payroll.
        </Text>

        <Text>
          <strong>
            Please note that wages can only be paid into your bank account and you must be the main account
            holder.
          </strong>
        </Text>

        <Panel bgWhiteSm boxed util={[margin.b2, margin.t4]}>
          <Text h4>Before you get started...</Text>

          <Text>To complete this section, you will need to provide the following information:</Text>

          <List tick>
            <List item>Sort code</List>

            <List item>Account number</List>

            <List item>Student loan details (if applicable)</List>
          </List>
        </Panel>

        <Button
          success
          util={[
            margin.b2,
            margin.b0Sm,
            margin.l2Sm,
            position.floatRight,
            display.block,
            display.inlineBlockSm,
            width.w12,
            width.resetSm
          ]}
          onClick={() =>
            navigate(userTaskWageInfo.Completed ? '/wages/confirm-details' : '/wages/employee-statement')
          }
        >
          {userTaskWageInfo.Completed ? 'Overview' : 'Start: Wages information'}
        </Button>

        <Button 
          link 
          util={[position.floatRight, width.w12, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default WagesLanding
