import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Button, List, Text, margin, padding, position, width } from 'chassis-react'
import Pdf from '../../../../static/files/AXAHealthSummary.pdf'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const AxaHealthcare = () => {
  const user = useContext(UserContext)
  const { Loaded, Steps, dependentList } = user
  const userTaskAxaForm = Steps.find(step => step.Step.Id === 9)
  const isCompleted = userTaskAxaForm && userTaskAxaForm.Completed
  return Loaded ? (
    <Layout>
      <Hero icon="person" preTitleMobile="Step 8 of 9">
        AXA Private Healthcare
      </Hero>

      <ContentWrap util={[padding.v3, padding.v5Sm]}>
        <Text h4 as="h1">
          What is this?
        </Text>

        <Text>
          We’re pleased to be able to offer employees private health care in partnership with AXA Health
          (previously known as AXA PPP Healthcare).
        </Text>

        <Text>
          This means that as an Arnold Clark employee, your health cover is paid for by us (subject to a
          modest excess) and will be reported as a taxable benefit on your annual P11D. If you pay an
          additional premium, you can also add your spouse, partner and children (up to the age of 21) living
          at the same address.
        </Text>

        <Panel bgWhiteSm boxed util={[margin.b2, margin.t4]}>
          <Text h4 as="h2">
            Before you start…
          </Text>

          <Text>Before you complete the next step, please advise if:</Text>

          <List tick util={[margin.b4, margin.b0Sm]}>
            <List item>You wish to be enrolled in the private health care scheme.</List>
            <List item>
              If you do wish to be enrolled, please confirm if you wish to add your spouse/partner or children
              to the plan.
            </List>
          </List>
        </Panel>

        {dependentList.length > 0 && (
          <Button
            success
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
            onClick={
              isCompleted
                ? () => navigate('/axa-healthcare/confirm')
                : () => navigate('/axa-healthcare/details')
            }
          >
            {isCompleted ? 'Overview' : 'Start: Private healthcare form'}
          </Button>
        )}

        {dependentList.length === 0 && (
          <Button
            success
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
            onClick={() => navigate('/axa-healthcare/details')}
          >
            {isCompleted ? 'Overview' : 'Start: Private healthcare form'}
          </Button>
        )}

        <Button
          link
          util={[position.floatRight, width.w12, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
      <ContentWrap util={[padding.v3, padding.v5Sm]}>
        <a href={Pdf} target="blank" className="ch-btn ch-btn--link">
          View terms and conditions
        </a>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default AxaHealthcare
