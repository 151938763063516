import React from 'react'
import PropTypes from 'prop-types'

const SendDocumentModalContainer = props => {
  const { children, title, footer, onClick } = props

  return (
    <div className="send-document-modal-container">
      <div className="send-document-modal-container__inner">
        {title && (
          <div className="send-document-modal-container__header">
            <h3>{title}</h3>
          </div>
        )}

        <div>{children}</div>

        {footer && (
          <div className="send-document-modal-container__footer">
            <h3>
              {footer}
            </h3>
          </div>
        )}
      </div>

      <button type="button" className="send-document-modal-container__close" onClick={onClick} />
    </div>
  )
}

SendDocumentModalContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  footer: PropTypes.node,
  onClick: PropTypes.instanceOf(Function)
}

export default SendDocumentModalContainer
