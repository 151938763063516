import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Button, List, Text, display, margin, padding, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const LifeAssurance = () => {
  const user = useContext(UserContext)
  const { Loaded, Steps } = user
  const userTaskLifeAssurance = Steps.find(step => step.Step.Id === 6)
  const Completed = userTaskLifeAssurance && userTaskLifeAssurance.Completed

  return Loaded ? (
    <Layout>
      <Hero icon="doc" preTitleMobile="Step 3 of 9">
        Life assurance form
      </Hero>

      <ContentWrap util={[padding.v3, padding.v5Sm]}>
        <Text h4 as="h1">
          What is this?
        </Text>

        <Text>
          Our Group Life Assurance scheme gives you the peace of mind that if the worst should happen, your
          nominated loved ones will be looked after.
        </Text>

        <Panel bgWhiteSm boxed util={[margin.b2, margin.t4]}>
          <Text h4 as="h2">
            Before you start…
          </Text>

          <Text>To complete the life assurance form you will need the folowing information handy:</Text>

          <List tick util={[margin.b4, margin.b0Sm]}>
            <List item>
              Names of beneficiaries that you wish to leave money to in the event of your death
            </List>
          </List>
        </Panel>

        <Button
          success
          util={[
            margin.b2,
            margin.b0Sm,
            margin.l2Sm,
            display.block,
            display.inlineBlockSm,
            width.w12,
            width.resetSm,
            position.floatRight
          ]}
          onClick={() => navigate(Completed ? '/life-assurance/confirm' : '/life-assurance/details')}
        >
          {Completed ? 'Overview' : 'Start: Life assurance form'}
        </Button>

        <Button
          link
          util={[position.floatRight, width.w12, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default LifeAssurance
