import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'chassis-react'

const FormSelectInput = props => {
  const { children, id, label, hint, error, name, util, ...rest } = props

  return (
    <Form group error={!!error && true}>
      {label && (
        <Form label htmlFor={id}>
          {label}
        </Form>
      )}

      {hint && <Form hint>{hint}</Form>}

      {!!error && <Form validation>{error}</Form>}

      <Form select id={name || id} util={util} name={name} {...rest}>
        {children}
      </Form>
    </Form>
  )
}

FormSelectInput.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string])
}

export default FormSelectInput
