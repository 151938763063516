import * as yup from 'yup'
import '../../../../utilities/toUTC'

export const PersonalDetailsSchema = yup.object().shape({
  TitleId: yup
    .number()
    .min(1, 'This field is required')
    .required('This field is required')
    .typeError('This field is required'),
  Firstname: yup
    .string()
    .required('This field is required'),
  Surname: yup
    .string()
    .required('This field is required'),
  DateOfBirth: yup
    .date()
    .toUTC()
    .required('This field is required')
    .typeError('Please check required date format'),
  NINumber: yup
    .string()
    .required('This field is required')
})

export const AddressValidationSchema = yup.object().shape({
  Postcode: yup
    .string()
    .required('This field is required'),
  Line1: yup
    .string()
    .required('This field is required'),
  Town: yup
    .string()
    .required('This field is required')
})

export const ContactSchema = yup.object().shape({
  TelNumber: yup
    .string()
    .required('Phone number is required')
    // matches a plus sign, followed by 1 - 3 digits. e.g., a dialling code
    .matches(/^\+\d{1,3}/, {
      excludeEmptyString: true,
      message: 'Telephone number must start with a country dialling code. e.g. +44'
    })
    .matches(/^\+?\d+$/, {
      excludeEmptyString: true,
      message: 'Telephone number can only contain the "+" sign and digits'
    })
    .min(6, 'Telephone number must have at least 6 characters')
    .max(13, 'Telephone number must have no more than 13 characters')
})