import React, { Fragment, useContext, useState } from 'react'
import { navigate } from '@reach/router'
import { Alert, Button, Form, Grid, List, Text, margin, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'
import { WagesStudentLoanSchema } from './schema'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'

const WagesStudentLoan = () => {
  const user = useContext(UserContext)

  const [ValidationErrors, setValidationErrors] = useState([])

  const userTaskWageInfo = user.Steps.find(step => step.Step.Id === 5)
  const navigateToPage = () =>
    userTaskWageInfo.Completed ? navigate('/wages/confirm-details') : navigate('/wages/payment-details')

  const handleSubmit = e => {
    e.preventDefault()
    setValidationErrors([])
    const { WageInformation } = user

    WagesStudentLoanSchema.validate(WageInformation, { abortEarly: false })
      .then(() => {
        user.handleSubmitWageInformation(e)
        navigateToPage()
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  return user.Loaded ? (
    <Layout>
      {!userTaskWageInfo.Completed && <Stepper steps={stepperSteps.wages} currentStep={2} />}
      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm boxed>
            <Grid row>
              <Grid colSm={6}>
                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: user.WageInformation.StudentBeforeSixthApril
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: !user.WageInformation.StudentBeforeSixthApril
                    }
                  ]}
                  id="StudentBeforeSixthApril"
                  name="StudentBeforeSixthApril"
                  label="Were you a student in higher eduction before the last 6 April?"
                  data-format="bool"
                  onChange={user.handleChangeWageInformation}
                />

                {user.WageInformation.StudentBeforeSixthApril && (
                  <Fragment>
                    <Text h5>Please choose all that apply:</Text>

                    <Form group error={!!getErrorForField('StudentBeforeSixthApril', ValidationErrors)}>
                      <Form validation>{getErrorForField('StudentBeforeSixthApril', ValidationErrors)}</Form>

                      <Form
                        checkbox
                        name="WasUndergraduate"
                        onClick={e => {
                          user.handleChangeWageInformation(e)
                          setValidationErrors(
                            ValidationErrors.filter(err => err.path !== 'StudentBeforeSixthApril')
                          )
                        }}
                        defaultChecked={user.WageInformation.WasUndergraduate}
                        // If the user is currently marked as WasUndergraduate = true,
                        //  send WasUndergraduate = false to state onClick
                        value={!user.WageInformation.WasUndergraduate}
                        data-format="bool"
                      >
                        I was an undergraduate
                      </Form>

                      <Form
                        checkbox
                        name="WasPostgraduate"
                        onClick={e => {
                          user.handleChangeWageInformation(e)
                          setValidationErrors(
                            ValidationErrors.filter(err => err.path !== 'StudentBeforeSixthApril')
                          )
                        }}
                        defaultChecked={user.WageInformation.WasPostgraduate}
                        // If the user is currently marked as WasPostgraduate = true,
                        //  send WasPostgraduate = false to state onClick
                        value={!user.WageInformation.WasPostgraduate}
                        data-format="bool"
                      >
                        I was a postgraduate
                      </Form>
                    </Form>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Panel>

          {user.WageInformation.WasUndergraduate && (
            <Panel bgGreySm boxed util={margin.t5}>
              <Grid row>
                <Grid colSm={6}>
                  <Text h2>Undergraduate</Text>
                  <Fragment>
                    <FormRadioGroup
                      options={[
                        {
                          label: 'Yes',
                          value: true,
                          checked: user.WageInformation.HasUnpaidStudentLoan
                        },
                        {
                          label: 'No',
                          value: false,
                          checked: !user.WageInformation.HasUnpaidStudentLoan
                        }
                      ]}
                      id="HasUnpaidStudentLoan"
                      name="HasUnpaidStudentLoan"
                      label="Do you have a Student Loan which is not fully repaid?"
                      data-format="bool"
                      onChange={user.handleChangeWageInformation}
                    />

                    {user.WageInformation.HasUnpaidStudentLoan && (
                      <Fragment>
                        <FormRadioGroup
                          options={[
                            {
                              label: 'Yes',
                              value: true,
                              checked: user.WageInformation.StudentLoanPaidDirectToStudentLoanCompany
                            },
                            {
                              label: 'No',
                              value: false,
                              checked: !user.WageInformation.StudentLoanPaidDirectToStudentLoanCompany
                            }
                          ]}
                          id="StudentLoanPaidDirectToStudentLoanCompany"
                          name="StudentLoanPaidDirectToStudentLoanCompany"
                          label="Are you repaying your Student Loan direct to the Student Loans Company by agreed monthly payments?"
                          data-format="bool"
                          onChange={user.handleChangeWageInformation}
                        />

                        {getErrorForField('StudentLoanTypeId', ValidationErrors) && (
                          <Alert danger util={[margin.b3, margin.t3]}>
                            {getErrorForField('StudentLoanTypeId', ValidationErrors)}
                          </Alert>
                        )}

                        <FormRadioGroup
                          options={[
                            {
                              label: (
                                <Fragment>
                                  <Text h4>Plan 1</Text>

                                  <List>
                                    <List item>
                                      You lived in Northern Ireland when you started your course
                                    </List>
                                    <List item>
                                      You lived in England or Wales and started your course before September
                                      2012
                                    </List>
                                  </List>
                                </Fragment>
                              ),

                              value: 1,
                              checked: user.WageInformation.StudentLoanTypeId === 1
                            },
                            {
                              label: (
                                <Fragment>
                                  <Text h4>Plan 2</Text>

                                  <List>
                                    <List item>
                                      You lived in England or Wales and started your course after 1 September
                                      2012
                                    </List>
                                  </List>
                                </Fragment>
                              ),
                              value: 2,
                              checked: user.WageInformation.StudentLoanTypeId === 2
                            },
                            {
                              label: (
                                <Fragment>
                                  <Text h4>Plan 4</Text>

                                  <List>
                                    <List item>
                                      You lived in Scotland and applied through the Student Award Agency
                                      Scotland (SAAS) when you started your course 2012
                                    </List>
                                  </List>
                                </Fragment>
                              ),
                              value: 3,
                              checked: user.WageInformation.StudentLoanTypeId === 3
                            }
                          ]}
                          id="StudentLoanTypeId"
                          name="StudentLoanTypeId"
                          label="What type of Student Loan do you have?"
                          data-format="int"
                          onChange={e => {
                            user.handleChangeWageInformation(e)
                            setValidationErrors(
                              ValidationErrors.filter(error => error.path !== 'StudentLoanTypeId')
                            )
                          }}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                </Grid>
              </Grid>
            </Panel>
          )}

          {user.WageInformation.WasPostgraduate && (
            <Panel bgGreySm boxed util={margin.t5}>
              <Grid row>
                <Grid colSm={6}>
                  <Text h2>Postgraduate</Text>

                  <FormRadioGroup
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                        checked: user.WageInformation.HasUnpaidPostgradLoan
                      },
                      {
                        label: 'No',
                        value: false,
                        checked: !user.WageInformation.HasUnpaidPostgradLoan
                      }
                    ]}
                    id="HasUnpaidPostgradLoan"
                    name="HasUnpaidPostgradLoan"
                    label="Do you have a Postgraduate Loan which is not fully repaid?"
                    data-format="bool"
                    onChange={user.handleChangeWageInformation}
                  />

                  {user.WageInformation.HasUnpaidPostgradLoan && (
                    <FormRadioGroup
                      options={[
                        {
                          label: 'Yes',
                          value: true,
                          checked: user.WageInformation.PostgradStudentBeforeSixthApril
                        },
                        {
                          label: 'No',
                          value: false,
                          checked: !user.WageInformation.PostgradStudentBeforeSixthApril
                        }
                      ]}
                      id="PostgradStudentBeforeSixthApril"
                      name="PostgradStudentBeforeSixthApril"
                      label="Did you complete or leave your Postgraduate studies before 6th April?"
                      data-format="bool"
                      onChange={user.handleChangeWageInformation}
                    />
                  )}

                  {user.WageInformation.PostgradStudentBeforeSixthApril && (
                    <FormRadioGroup
                      options={[
                        {
                          label: 'Yes',
                          value: true,
                          checked: user.WageInformation.PostgradLoanPaidDirectToStudentLoanCompany
                        },
                        {
                          label: 'No',
                          value: false,
                          checked: !user.WageInformation.PostgradLoanPaidDirectToStudentLoanCompany
                        }
                      ]}
                      id="PostgradLoanPaidDirectToStudentLoanCompany"
                      name="PostgradLoanPaidDirectToStudentLoanCompany"
                      label="Are you repaying your Postgraduate Loan direct to the Student Loans Company by direct debit?"
                      data-format="bool"
                      onChange={user.handleChangeWageInformation}
                    />
                  )}
                </Grid>
              </Grid>
            </Panel>
          )}

          <Button success util={[margin.t3, position.floatRight, width.w12, width.resetSm]}>
            {userTaskWageInfo.Completed ? 'Update' : ' Next: Payment details'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default WagesStudentLoan
