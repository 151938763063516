import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import moment from 'moment'
import { Button, position, width } from 'chassis-react'
import ManagerSelect from '../../../components/ManagerSelect'

import api from '../../../api'

import valueFormat from '../../../utilities/valueFormat'

import adminUserService from '../../../services/adminUserService'

import stepperSteps from '../../../config/stepperSteps'

import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import FormRadioGroup from '../../../components/FormRadioGroup'
import FormSelectInput from '../../../components/FormSelectInput'
import FormTextInput from '../../../components/FormTextInput'
import FormWrapper from '../../../components/FormWrapper'
import Layout from '../../../components/Layout'
import Stepper from '../../../components/Stepper'

import { positionDetailsSchema } from './schema'
import getErrorForField from '../../../utilities/getErrorForField'
import focusOnError from '../../../utilities/focusOnError'
import handleBlur from '../../../utilities/handleBlur'

const PositionDetails = ({ id }) => {
  const [loaded, setLoaded] = useState(false)
  const [jobTitles, setJobTitles] = useState([])
  const [branches, setBranches] = useState([])
  const [lineManagers, setLineManagers] = useState([])
  const [departments, setDepartments] = useState([])
  const [workingLocationCategories, setWorkingLocationCategories] = useState([])
  const [regions, setRegions] = useState([])
  const [contractTypes, setContractTypes] = useState([])
  const [typesOfWork, setTypesOfWork] = useState([])
  const [contractReasons, setContractReasons] = useState([])
  const [formData, setFormData] = useState({
    JobTitleId: '',
    BranchId: '',
    DepartmentId: '',
    WorkingLocationCategoryId: '',
    RegionId: '',
    ManagerId: '',
    StartDate: '',
    HoursOfWork: '',
    Flexitime: false,
    ContractTypeId: '',
    WorkTypeId: '',
    TemporaryContractReasonId: '',
    TemporaryContractExpiryDate: '',
    UserId: 0
  })

  const [validationErrors, setValidationErrors] = useState([])
  const [completed, setCompleted] = useState(false)

 

  useEffect(() => {
    ;(async () => {
      await api.jobTitle.getAll().then(({ data }) => setJobTitles(data))
      await api.branch.getAll().then(({ data }) => setBranches(data))
      await api.department.getAll().then(({ data }) => setDepartments(data))
      await api.lineManager.getAll().then(({ data }) => setLineManagers(data))
      await api.workingLocationCategory.getAll().then(({ data }) => setWorkingLocationCategories(data))
      await api.region.getAll().then(({ data }) => setRegions(data))
      await api.contractType.getAll().then(({ data }) => setContractTypes(data))
      await api.contractReason.getAll().then(({ data }) => setContractReasons(data))
      await api.typeOfWork.getAll().then(({ data }) => setTypesOfWork(data))
      await api.newStart.getById(id).then(({ data }) => {
        setCompleted(data.Steps[0].Completed)
        setFormData({
          JobTitleId: +data.JobTitleId,
          BranchId: +data.BranchId,
          DepartmentId: +data.DepartmentId,
          WorkingLocationCategoryId: +data.WorkingLocationCategoryId,
          RegionId: +data.RegionId,
          ManagerName: data.ManagerName,
          ManagerId: +data.ManagerId,
          StartDate: (data.StartDate && moment.utc(data.StartDate).format('YYYY-MM-DD')) || '',
          HoursOfWork: data.HoursOfWork,
          Flexitime: data.Flexitime,
          ContractTypeId: data.ContractTypeId,
          WorkTypeId: +data.WorkTypeId,
          TemporaryContractReasonId: +data.TemporaryContractReasonId || '',
          TemporaryContractExpiryDate:
            (data.TemporaryContractExpiryDate &&
              moment.utc(data.TemporaryContractExpiryDate).format('YYYY-MM-DD')) ||
            '',
          UserId: +data.Id,
          CompanyCar: data.CompanyCar
        })
      })
      setLoaded(true)
    })()
  }, [])

  useEffect(() => {
    if (formData.WorkTypeId === 1) {
      setFormData(existing => ({
        ...existing,
        TemporaryContractReasonId: '',
        TemporaryContractExpiryDate: ''
      }))
    }
  }, [formData.WorkTypeId])

  const updateFormData = (name, value) => {
    setFormData(existing => ({ ...existing, [name]: value }))
  }

  const handleChange = e => {
    const {
      value,
      name,
      dataset: { format }
    } = e.target

    updateFormData(name, valueFormat(format, value))
  }

  const handleSubmit = e => {
    e.preventDefault()
    positionDetailsSchema
      .validate(formData, { abortEarly: false })
      .then(response => {
        adminUserService.positionDetails(response).then(res => {
          if (completed) {
            navigate(`/admin/new-start/${res.data.Id}/overview`)
          } else {
            navigate(`/admin/new-start/${res.data.Id}/contracts`)
          }
        })
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const options = lineManagers.map(lineManager => ({
    value: lineManager,
    label: `${lineManager.Firstname} ${lineManager.Surname} #${lineManager.Id}`
  }))
  const selectedOption = options.find(option => option.value.Id === formData.ManagerId)

  return loaded ? (
    <Layout>
      {!completed && <Stepper steps={stepperSteps.newStart(id)} currentStep={2} />}

      <ContentWrap slim>
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="UserId" value={id} />
          <FormWrapper>
            <FormSelectInput
              name="JobTitleId"
              label="Position *"
              value={formData.JobTitleId || ''}
              onChange={handleChange}
              util={width.w8Sm}
              error={getErrorForField('JobTitleId', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            >
              <option value="0" />

              {jobTitles.map(item => (
                <option key={item.Id} value={item.Id}>
                  {item.Description}
                </option>
              ))}
            </FormSelectInput>

            <FormSelectInput
              name="BranchId"
              label="Branch *"
              value={formData.BranchId || ''}
              onChange={handleChange}
              util={width.w8Sm}
              error={getErrorForField('BranchId', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            >
              <option />
              {branches.map(item => (
                <option key={item.Id} value={item.Id}>
                  {item.Name}
                </option>
              ))}
            </FormSelectInput>

            <FormSelectInput
              name="DepartmentId"
              label="Department *"
              value={formData.DepartmentId || ''}
              onChange={handleChange}
              util={width.w8Sm}
              error={getErrorForField('DepartmentId', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            >
              <option />
              {departments.map(item => (
                <option key={item.Id} value={item.Id}>
                  {item.Name}
                </option>
              ))}
            </FormSelectInput>

            <FormSelectInput
              name="WorkingLocationCategoryId"
              label="Working Location Category *"
              value={formData.WorkingLocationCategoryId || ''}
              onChange={handleChange}
              util={width.w8Sm}
              error={getErrorForField('WorkingLocationCategoryId', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            >
              <option key={-1} value={-1}>
                Default
              </option>
              {workingLocationCategories.map(item => {
                if (item.Active || item.Id === formData.WorkingLocationCategoryId) {
                  return (
                    <option key={item.Id} value={item.Id}>
                      {item.Name}
                    </option>
                  )
                }
                return null
              })}
            </FormSelectInput>

            <FormSelectInput
              name="RegionId"
              label="Region *"
              value={formData.RegionId || ''}
              onChange={handleChange}
              util={width.w8Sm}
              error={getErrorForField('RegionId', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            >
              <option />
              {regions.map(item => (
                <option key={item.Id} value={item.Id}>
                  {item.Name}
                </option>
              ))}
            </FormSelectInput>

            {/* Line Manager ID */}
            <ManagerSelect
              formId={id}
              options={options}
              selectedOption={selectedOption}
              selectionChanged={option => {
                if (option == null) {
                  updateFormData('ManagerId', '')
                  updateFormData('ManagerName', '')
                  return
                }
                updateFormData('ManagerId', option.value.Id)
                updateFormData('ManagerName', `${option.value.Firstname} ${option.value.Surname}`)
              }}
              error={getErrorForField('ManagerId', validationErrors)}
            />

            <FormTextInput
              name="StartDate"
              type="date"
              label="Start date *"
              value={formData.StartDate}
              min={moment
                .utc()
                .subtract(2, 'M')
                .format('YYYY-MM-DD')}
              max={moment
                .utc()
                .add(10, 'Y')
                .format('YYYY-MM-DD')}
              onChange={handleChange}
              util={width.w4Sm}
              placeholder="yyyy-mm-dd"
              pattern="\d{4}-\d{2}-\d{2}"
              error={getErrorForField('StartDate', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            />

            <FormTextInput
              name="HoursOfWork"
              type="text"
              label="Hours of work"
              maxLength="100"
              value={formData.HoursOfWork || ''}
              onChange={handleChange}
              util={width.w4Sm}
            />

            <FormRadioGroup
              options={[
                {
                  label: contractTypes[0].Name,
                  value: contractTypes[0].Id,
                  checked: formData.ContractTypeId === contractTypes[0].Id
                },
                {
                  label: contractTypes[1].Name,
                  value: contractTypes[1].Id,
                  checked: formData.ContractTypeId === contractTypes[1].Id
                }
              ]}
              id="ContractTypeId"
              name="ContractTypeId"
              label="Contract Type*"
              onChange={handleChange}
              data-format="int"
              error={getErrorForField('ContractTypeId', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            />

            <FormRadioGroup
              options={[
                {
                  label: typesOfWork[0].Name,
                  value: typesOfWork[0].Id,
                  checked: formData.WorkTypeId === typesOfWork[0].Id
                },
                {
                  label: typesOfWork[1].Name,
                  value: typesOfWork[1].Id,
                  checked: formData.WorkTypeId === typesOfWork[1].Id
                }
              ]}
              id="WorkTypeId"
              name="WorkTypeId"
              label="Type of work*"
              onChange={handleChange}
              data-format="int"
              error={getErrorForField('WorkTypeId', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            />

            {formData.WorkTypeId === typesOfWork[1].Id && (
              <>
                <FormSelectInput
                  name="TemporaryContractReasonId"
                  label="Contract reason *"
                  value={formData.TemporaryContractReasonId || ''}
                  onChange={handleChange}
                  util={width.w8Sm}
                  error={getErrorForField('TemporaryContractReasonId', validationErrors)}
                  onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
                >
                  <option />
                  {contractReasons.map(item => (
                    <option key={item.Id} value={+item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </FormSelectInput>
                <FormTextInput
                  name="TemporaryContractExpiryDate"
                  type="date"
                  label="Contract end date *"
                  value={formData.TemporaryContractExpiryDate}
                  min={moment
                    .utc()
                    .add(1, 'D')
                    .format('YYYY-MM-DD')}
                  onChange={handleChange}
                  util={width.w4Sm}
                  error={getErrorForField('TemporaryContractExpiryDate', validationErrors)}
                  onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
                />
              </>
            )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: formData.Flexitime || ''
                },
                {
                  label: 'No',
                  value: false,
                  checked: formData.Flexitime === false || ''
                }
              ]}
              id="Flexitime"
              name="Flexitime"
              label="Flexitime *"
              onChange={handleChange}
              data-format="bool"
              error={getErrorForField('Flexitime', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            />

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: formData.CompanyCar || ''
                },
                {
                  label: 'No',
                  value: false,
                  checked: formData.CompanyCar === false || ''
                }
              ]}
              id="CompanyCar"
              name="CompanyCar"
              label="Company Car"
              onChange={handleChange}
              data-format="bool"
              error={getErrorForField('CompanyCar', validationErrors)}
              onBlur={e => handleBlur(e, positionDetailsSchema, formData, setValidationErrors)}
            />
          </FormWrapper>

          <Button success type="submit" util={position.floatRight}>
            {completed ? 'Update details' : 'Next step'}
          </Button>
        </form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

PositionDetails.propTypes = { id: PropTypes.string }

export default PositionDetails
