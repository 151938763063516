/* eslint-disable object-shorthand */
/* eslint-disable func-names */

/* 
  Linting is disabled in this file as we have to use full function syntax to be 
  able to use this.resolve(). (Rather than an arrow function.)
*/

import * as yup from 'yup'

export const WagesStudentLoanSchema = yup.object().shape({
  HasUnpaidStudentLoan: yup.boolean(),
  WasPostgraduate: yup.boolean(),
  WasUndergraduate: yup.boolean(),
  StudentBeforeSixthApril: yup
    .boolean()
    .test({
      name: 'student-type-required',
      test: function(StudentBeforeSixthApril) {
        const WasUndergraduate = this.resolve(yup.ref('WasUndergraduate'))
        const WasPostgraduate = this.resolve(yup.ref('WasPostgraduate'))
        return !StudentBeforeSixthApril || (StudentBeforeSixthApril && (WasUndergraduate || WasPostgraduate))
      },
      message: 'Please choose Undergraduate and / or Postgraduate',
      exclusive: true
    }),
  StudentLoanTypeId: yup.number().when('HasUnpaidStudentLoan', {
      is: true,
      then: yup
        .number()
        .required('Student loan type is required')
        .typeError('Student loan type is required'),
      otherwise: yup.number().nullable()
    })
})

export const BankDetailsSchema = yup.object().shape({
  SortCode: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\d{6}$/, 'This field must contain exactly 6 digits')
    .typeError('This field is required'),
  AccountNumber: yup
    .string()
    .trim()
    .required('This field is required')
    .matches(/^\d{8}$/, 'This field must contain exectly 8 digits')
    .typeError('This field is required'),
  AccountHolder: yup
    .string()
    .required('This field is required')
    .typeError('This field is required')
})