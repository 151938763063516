import React from 'react'
import PropTypes from 'prop-types'
import { Base, Text, margin } from 'chassis-react'

const NonApprenticeThankYou = ({ startDate }) => (
  <>
    <Base util={[margin.b4]}>
      <Text as="h2">Your uniform</Text>
      <Text>
        If you require a uniform or personal protective equipment (PPE), your order will be sent directly to
        the branch. You’ll be able to collect this when you start.
      </Text>
    </Base>

    <Base util={[margin.b4, margin.t4]}>
      <Text as="h2">Next Steps</Text>
      <Text>There is nothing further you are required to do, unless you hear from us.</Text>

      <Text>
        Your start date is <strong>{startDate}</strong>. Good Luck!
      </Text>
    </Base>
  </>
)

NonApprenticeThankYou.propTypes = {
  startDate: PropTypes.string
}

export default NonApprenticeThankYou
