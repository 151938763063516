const overviewApprentice = data => {  
    if (!Object.keys(data).length) {
      return null
    }
    return [
    {
      dt: 'Boot size',
      dd: data.BootSize
    },
    {
      dt: 'Overalls size',
      dd: data.OverallsSize
    },
    {
      dt: 'Polo shirt size',
      dd: data.PoloShirtsSize
    },
    {
      dt: 'Accommodation required',
      dd: `${data.AccommodationRequired ? 'Yes' : 'No'}`
    },
    {
      dt: 'Train travel required',
      dd: `${data.TravelRequired ? 'Yes' : 'No'}`
    },
    {
      dt: 'Nearest train station',
      dd: data.TravelStation
    },
    {
      dt: 'Dietary requirements',
      dd: data.DietaryRequirements
    },
  ]
  }
  export default overviewApprentice
  