import React, { useContext } from 'react'
import {
  Base,
  Button,
  Form,
  List,
  Text,
  border,
  color,
  margin,
  padding,
  position,
  shape,
  width
} from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import DetailsList from '../../../../components/DetailsList'
import Layout from '../../../../components/Layout'
import Stepper from '../../../../components/Stepper'

const MedicalConfirm = () => {
  const user = useContext(UserContext)

  const { Loaded, Medical, Steps, handleUpdateStep } = user
  const {
    NoDaysAbsentInLastTwelveMonths,
    HowManyPeriodsOfAbsence,
    DetailsOfEachAbsence,
    CirculatoryProblems,
    HeartProblems,
    ChestProblems,
    EpilepsyOrFainting,
    SkinDisorders,
    OperationsOrFractures,
    AnyCurrentMedication,
    BackArthritisRheumatism,
    BonesJointsTendons,
    OtherSignificantHealthProblems,
    WorkedWithHighNoiseLevels,
    IndustrialInjuryClaim,
    DetailsOfConditions,
    AnyOtherConditions,
    ShareWithManagers
  } = Medical

  const userTaskMedicalForm = Steps.find(step => step.Step.Id === 7)

  const overview = [
    {
      dt: 'Days absent in last 12 months',
      dd: NoDaysAbsentInLastTwelveMonths
    },
    {
      dt: 'Periods of absence',
      dd: HowManyPeriodsOfAbsence
    },
    {
      dt: 'Details of absence(s)',
      dd: <pre style={{ whiteSpace: 'pre-wrap' }}>{DetailsOfEachAbsence}</pre>
    }
  ]

  const medicalHistory = [
    {
      dt: 'Outstanding medical conditions',
      dd: (
        <List>
          <List item>Circulatory problems: {CirculatoryProblems ? 'Yes' : 'No'}</List>
          <List item>Heart problems: {HeartProblems ? 'Yes' : 'No'}</List>
          <List item>Chest problems: {ChestProblems ? 'Yes' : 'No'}</List>
          <List item>Epilepsy or fainting attacks: {EpilepsyOrFainting ? 'Yes' : 'No'}</List>
          <List item>Skin disorders: {SkinDisorders ? 'Yes' : 'No'}</List>
          <List item>Recent operations or fractures: {OperationsOrFractures ? 'Yes' : 'No'}</List>
          <List item>Any current medication: {AnyCurrentMedication ? 'Yes' : 'No'}</List>
          <List item>Back problems, arthritis and rheumatism: {BackArthritisRheumatism ? 'Yes' : 'No'}</List>
          <List item>Injury to bones, joints or tendons: {BonesJointsTendons ? 'Yes' : 'No'}</List>
          <List item>
            Any other significant health problems: {OtherSignificantHealthProblems ? 'Yes' : 'No'}
          </List>
          <List item>
            Have you worked in an industry with high noise levels: {WorkedWithHighNoiseLevels ? 'Yes' : 'No'}
          </List>
          <List item>
            Have you ever made a claim for industrial injury: {IndustrialInjuryClaim ? 'Yes' : 'No'}
          </List>
        </List>
      )
    },
    {
      dt: 'Details of condition(s)',
      dd: <pre style={{ whiteSpace: 'pre-wrap' }}>{DetailsOfConditions}</pre>
    },
    {
      dt: 'Any other conditions',
      dd: <pre style={{ whiteSpace: 'pre-wrap' }}>{AnyOtherConditions}</pre>
    },
    {
      dt: 'Share this information with relevant managers?',
      dd: <pre style={{ whiteSpace: 'pre-wrap' }}>{ShareWithManagers ? 'Yes' : 'No'}</pre>
    }
  ]

  return Loaded ? (
    <Layout>
      {!userTaskMedicalForm.Completed && <Stepper steps={stepperSteps.medical} currentStep={3} />}

      <ContentWrap pad>
        <Text h4>Confirm details before submitting</Text>
        <Form>
          <Base
            util={[padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3, shape.rounded, margin.b3]}
          >
            <DetailsList title="Absence details" editLink="/medical/absence-details" listItems={overview} />

            <DetailsList title="Medical history" editLink="/medical/history" listItems={medicalHistory} />
          </Base>
        </Form>

        <Button
          success
          util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          onClick={() => handleUpdateStep(userTaskMedicalForm.Id)}
        >
          Confirm medical details
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default MedicalConfirm
