import React from 'react'
import PropTypes from 'prop-types'
import { Router, Location } from '@reach/router'
import ReactGA from 'react-ga'

import Admin from './pages/Admin'
import UserPortal from './pages/UserPortal'
import MagicLink from './pages/MagicLink'
import EmpAuth from './pages/EmpAuth'
import ForgotPassword from './components/AuthGate/forgotPassword'

import './static/styles/reset.css'

ReactGA.initialize('UA-76124910-23')

const App = () => (
  <>
    <link rel="stylesheet" href={process.env.FONT_URL} />
    <Location>
      {props => {
        window.scrollTo(0, 0)
        const {
          location: { href, search }
        } = props
        ReactGA.pageview(href + search)
      }}
    </Location>
    <Router>
      <Admin path="/admin/*" />
      <UserPortal path="/*" />
      <MagicLink path="/magiclink/:token" />
      <EmpAuth path="/empauth/*" />
      <ForgotPassword path="/forgot-password" />
    </Router>
  </>
)

App.propTypes = {
  location: PropTypes.instanceOf('Object')
}

export default App
