import React, { useContext } from 'react'

import moment from 'moment'
import { navigate } from '@reach/router'
import { Button, Card, Text, margin, Grid, Base, display } from 'chassis-react'

import { UserContext } from '../../../context/UserContext'

import Hero from '../../../components/Hero'
import Layout from '../../../components/Layout'
import ContentWrap from '../../../components/ContentWrap'
import ApprenticeThankYou from './ApprenticeThankYou'
import NonApprenticeThankYou from './NonApprenticeThankYou'
import { UserTypes } from '../../../config/UserTypes'
import chatLg from '../../../static/components/info-box/chat-icon-lg.svg'
import chatMobile from '../../../static/components/info-box/chat-icon.svg'

import './style.scss'

const ThankYou = () => {
  const { StartDate, UserType } = useContext(UserContext)
  if (!StartDate) return null

  const formattedStartDate = moment(StartDate).format('LL')
  const isApprentice = UserType === UserTypes.APPRENTICE

  const subheading = (
    <Text as="h4" util={margin.t2}>
      You have completed the onboarding process
    </Text>
  )

  return (
    <Layout>
      <Hero icon="person" subheading={subheading}>
        You&apos;re all set!
      </Hero>
      <ContentWrap pad>
        <Card>
          <Card content>
            {isApprentice && <ApprenticeThankYou startDate={formattedStartDate} />}
            {!isApprentice && <NonApprenticeThankYou startDate={formattedStartDate} />}

            <Button onClick={() => navigate('/')}>
              Back to dashboard
            </Button>
          </Card>
        </Card>
        <Card util={[margin.t4]} className="feedback">
          <Card content>
            <Grid row>
              <Grid colSm={10}>
                <Base util={[display.block, display.noneSm, margin.b2]}>
                  <img src={chatMobile} alt="feedback" />
                </Base>
                <Text h3>
                  Help us improve!
                </Text>
                <Text>Tell us what you thought about the Digital Onboarding process.</Text>
                <a href="https://forms.gle/Fo6AH9QuMmY28Xjg6" target="_blank" rel="noopener noreferrer">
                  Leave your feedback
                </a>
              </Grid>
              <Grid colSm={2} util={[display.blockSm, display.none]}>
                <img src={chatLg} alt="feedback" />
              </Grid>
            </Grid>
          </Card>
        </Card>
      </ContentWrap>
    </Layout>
  )
}

export default ThankYou
