import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { Navbar, Base } from 'chassis-react'
import accessRole from '../../services/accessRole'
import auth from '../../auth'

import logo from '../../static/images/branding/digital-onboarding-logo.svg'
import Footer from '../Footer'

import '../../static/styles/reset.css'

const Layout = props => {
  const { minimal, children } = props

  const clickLogout = () => auth.logout()

  const sessionIsAdmin = auth.getSessionValue('IsAdmin')
  const isAdmin = sessionIsAdmin === true
  const isUser = sessionIsAdmin === false

  return (
    <Fragment>
      <Navbar>
        {minimal ? (
          <img src={logo} alt="Digital Onboarding" style={{ height: '36px', margin: '18px 0' }} />
        ) : (
          <Navbar as={Link} logo to={isAdmin ? '/admin' : '/'}>
            <img src={logo} alt="Digital Onboarding" />
          </Navbar>
        )}

        <Navbar menu>
          {isUser && !minimal && (
            <Navbar item>
              <Base as="a">Account</Base>

              <Navbar menu>
                <Navbar item>
                  <Link to="/update-password">Update password</Link>
                </Navbar>

                <Navbar item>
                  <Base as="a" onClick={clickLogout}>
                    Logout
                  </Base>
                </Navbar>
              </Navbar>
            </Navbar>
          )}

          {isAdmin && (
            <>
              <Navbar item>
                <Base as={Link} to="/admin">
                  Admin dashboard
                </Base>
              </Navbar>
              {accessRole.allow(['recruitment', 'itDev']) && (
                <Navbar item>
                  <Base as={Link} to="/admin/terms/list">
                    Internal T&amp;C Letters
                  </Base>
                </Navbar>
              )}
              <Navbar item>
                <Base as="a" onClick={clickLogout}>
                  Logout
                </Base>
              </Navbar>
            </>
          )}
        </Navbar>
      </Navbar>

      {children}

      <Footer />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  minimal: PropTypes.bool
}

export default Layout
