import React, { Fragment } from 'react'
import moment from 'moment'

const overviewDisclosureDetails = data => {
  if (!Object.keys(data).length) {
    return null
  }
  return [
    {
      dt: 'Gender',
      dd: data.Gender && data.Gender.Name
    },
    {
      dt: 'Title',
      dd: data.Title && data.Title.Name
    },
    data.MiddleNames && {
      dt: 'Middle name',
      dd: data.MiddleNames
    },
    {
      dt: 'Forename',
      dd: data.Forename
    },
    {
      dt: 'Surname',
      dd: data.Surname
    },
    ...data.OtherNames.map((otherName, i) => ({
      dt: `Other name ${i + 1}`,
      dd: (
        <Fragment>
          <div>{otherName.TitleName}</div>
          <div>{otherName.Firstname}</div>
          <div>{otherName.Middlename}</div>
          <div>{otherName.Surname}</div>
          <div>
            <small>
              {moment.utc(otherName.UsedFrom).format('DD/MM/YYYY')} -{' '}
              {moment.utc(otherName.UsedTo).format('DD/MM/YYYY')}
            </small>
          </div>
        </Fragment>
      )
    }))
  ]
}
export default overviewDisclosureDetails
