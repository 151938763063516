import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from '@reach/router'
import { Alert, Grid, Form, Button, Table, Text, margin, width, position } from 'chassis-react'
import api from '../../../api'
import auth from '../../../auth'
import ContentLoader from '../../../components/ContentLoader'
import Layout from '../../../components/Layout'
import './style.scss'
import imgCloseCircled from '../../../static/images/icons/close-circled.svg'
import imgTickCircled from '../../../static/images/icons/tick-circled.svg'

const Letters = () => {
  const initialCompletedEmployee = auth.getSessionValue('RecentlyCompletedLetter')
  const [loaded, setLoaded] = useState(false)
  const [filteredTermsConditions, setFilteredTermsConditions] = useState([])

  const [disappearingAlert, setDisappearingAlert] = useState(true)
  const [completedEmployee, setCompletedEmployee] = useState(initialCompletedEmployee)

  useEffect(() => {
    ;(async () => {
      await api.terms.getAll().then(response => {
        setFilteredTermsConditions(response.data)
      })
      setLoaded(true)
    })()
  }, [])

  useEffect(() => {
    if (completedEmployee) {
      api.terms.getByEmployeeId(completedEmployee).then(response => {
        setCompletedEmployee(response.data)
      })

      setTimeout(() => {
        auth.updateSession({ RecentlyCompletedLetter: null })
        setDisappearingAlert(false)
      }, 30000)
    }
  }, [])

  return loaded ? (
    <Layout>
      {completedEmployee && disappearingAlert && (
        <Grid container>
          <Grid row>
            <Grid colMd={12} util={[margin.t3, position.centered]}>
              <Alert icon success>
                <Text util={margin.b0}>
                  The Terms &amp; Conditions letter was sent to{' '}
                  <strong>
                    {completedEmployee.EmployeeFirstname} {completedEmployee.EmployeeSurname} #
                    {completedEmployee.EmployeeId}
                  </strong>
                </Text>
              </Alert>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid row>
          <Grid colMd={8}>
            <Text h1 util={[margin.v4]}>
              Internal T&amp;C Letters
            </Text>
          </Grid>
          <Grid colMd={4}>
            <Form group util={position.floatRightSm}>
              <Button util={[margin.v4]} success as={Link} to="/admin/terms/">
                  Send an internal T&amp;C Letter
              </Button>
            </Form>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Table bordered striped util={width.w12}>
          <Table head>
            <Table row>
              <Table header>
                Employee name
              </Table>
              <Table header>Employee ID</Table>
              <Table header>Job Title</Table>
              <Table header>
                Date sent
              </Table>
              <Table header>
                Status
              </Table>
              <Table header>Viewed</Table>
              <Table header>Action</Table>
            </Table>
          </Table>

          <Table body id="users">
            {filteredTermsConditions.map((item, i) => (
              <Table row key={i}>
                <Table cell>{`${item.EmployeeFirstname} ${item.EmployeeSurname}`}</Table>
                <Table cell>{item.EmployeeId}</Table>
                <Table cell>{item.EmployeeJobTitle}</Table>
                <Table cell>{item.DateSent}</Table>
                <Table cell>
                  <span
                    className={`${item.Status === 'Not Accepted' && 'terms-not-accepted'} ${item.Status ===
                      'Accepted' && 'terms-accepted'} terms-muted`}
                  >
                    {item.Status}
                  </span>
                </Table>
                <Table cell>{item.DateViewed  
                  ? (<><img style={{ marginRight: 6 }} src={imgTickCircled} alt="" />{moment(item.DateViewed).format('DD/MM/YYYY')}</>)
                  : (<><img style={{ marginRight: 6 }} src={imgCloseCircled} alt="" /> Unread</>)
                }
                </Table>
                <Table cell>
                  <Link to={`/admin/terms/${item.Id}/overview`}>View details</Link>
                </Table>
              </Table>
            ))}
          </Table>
        </Table>
        
      </Grid>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Letters
