import React, { useContext, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

import { navigate } from '@reach/router'

import { UserContext } from '../../../../context/UserContext'
import UserContainer from '../../../../containers/UserContainer'
import auth from '../../../../auth'
import accessRole from '../../../../services/accessRole'

const DisclosureRoot = props => {
  const { children, id } = props

  const user = useContext(UserContext)
  const { handleLoadDisclosure } = user

  useEffect(() => {
    handleLoadDisclosure()
  }, [])

  // If we've got props.id, we've come in through the admin dashboard
  if (id) {
    const canEditDisclosure = auth.getSessionValue('IsAdmin') && accessRole.isProfileEditor()
    if (canEditDisclosure) {
      return <UserContainer id={+id}>{children}</UserContainer>
    }
    navigate('/admin')
  }

  return <Fragment>{children}</Fragment>
}

DisclosureRoot.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
}

export default DisclosureRoot
