import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Grid, padding } from 'chassis-react'

const FormSearchInput = props => {
  const { id, label, hint, afterText, error, name, textarea, children, util, onClick, ...rest } = props

  return (
    <Form group error={!!error && true}>
      <Grid row>
        <Grid colSm={8}>
          <Grid style={{ paddingLeft: '0' }} colXs={8}>
            {label && (
              <Form label htmlFor={id}>
                {label}
              </Form>
            )}

            {hint && <Form hint>{hint}</Form>}

            <Form input id={name || id} util={util} name={name} {...rest} />

            {afterText && (
              <Form label style={{ marginLeft: '5px', marginTop: '-3px' }}>
                {afterText}
              </Form>
            )}
          </Grid>

          <Grid colXs={4} util={padding.a0Sm}>
            <Button primary block onClick={onClick}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {!!error && (
        <Form style={{ marginTop: 10 }} validation>
          {error}
        </Form>
      )}
    </Form>
  )
}

FormSearchInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  afterText: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.string,
  textarea: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string])
}

export default FormSearchInput
