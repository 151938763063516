import * as yup from 'yup'

export const LifeAssuranceDetailsSchema = yup.object().shape({
  Name: yup.string().required('This field is required'),
  Relationship: yup.string().required('This field is required'),
  Percentage: yup
    .number()
    .required('This field is required')
    .positive()
    .typeError('This field is required')
})