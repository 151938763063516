import React, { Fragment } from 'react'
import moment from 'moment'

const overviewDisclosureContactInformation = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'Daytime phone number',
    dd: data.DaytimePhoneNumber
  },
  {
    dt: 'Evening phone number',
    dd: data.EveningPhoneNumber
  },
  {
    dt: 'Email address',
    dd: data.EmailAddress
  },
  ...data.AddressHistory.map((address, i) => ({
    dt: `Address ${i + 1}`,
    dd: (
      <Fragment>
        <div>{address.Line1}</div>
        <div>{address.Line2}</div>
        <div>{address.Town}</div>
        <div>{address.CountryName}</div>
        <div>{address.Postcode}</div>
        <div>
          <small>
            {moment.utc(address.DateFrom).format('DD/MM/YYYY')} - {moment.utc(address.DateTo).format('DD/MM/YYYY')}
          </small>
        </div>
      </Fragment>
    )
  }))
]
}
export default overviewDisclosureContactInformation
