import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Button, List, Text, margin, padding, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const Medical = () => {
  const user = useContext(UserContext)
  const { Loaded, Steps } = user
  const userTaskMedicalForm = Steps.find(step => step.Step.Id === 7)

  return Loaded ? (
    <Layout>
      <Hero icon="person" preTitleMobile="Step 8 of 9">
        Medical form
      </Hero>

      <ContentWrap util={[padding.v3, padding.v5Sm]}>
        <Text h4 as="h1">
          What is this?
        </Text>

        <Text>
          To assess your health history and accommodate any ongoing medical conditions, we’ll need to ask a
          few short questions.
        </Text>

        <Text>
          This information will remain confidential and will only be used to assess any reasonable adjustments
          required for your working environment.
        </Text>

        <Panel bgWhiteSm boxed util={[margin.b2, margin.t4]}>
          <Text h4 as="h2">
            Before you get started…
          </Text>

          <Text>To complete this section, please provide the following information:</Text>

          <List tick util={[margin.b4, margin.b0Sm]}>
            <List item>Details of absences for the last 12 months</List>

            <List item>Details of any medical disorders</List>
          </List>
        </Panel>

        <Button
          success
          util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          onClick={() =>
            userTaskMedicalForm.Completed
              ? navigate('/medical/confirm')
              : navigate('/medical/absence-details')
          }
        >
          {userTaskMedicalForm.Completed ? 'Overview' : 'Start: Medical form'}
        </Button>

        <Button
          link
          util={[position.floatRight, width.w12, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Medical
