const stepperSteps = {
  newStart: id => [
    {
      step: 1,
      title: 'Personal details',
      link: `/admin/new-start/${id}`
    },
    {
      step: 2,
      title: 'Position details',
      link: `/admin/new-start/${id}/position-details`
    },
    {
      step: 3,
      title: 'Contracts',
      link: `/admin/new-start/${id}/contracts`
    },
    {
      step: 4,
      title: 'Overview',
      link: `/admin/new-start/${id}/overview`
    }
  ],

  lifeAssurance: [
    {
      step: 1,
      title: 'Beneficiary details'
    },
    {
      step: 2,
      title: 'Confirm details'
    }
  ],

  disclosure: [
    {
      step: 1,
      title: 'Personal details',
      link: '/disclosure/personal-details'
    },
    {
      step: 2,
      title: 'Birth details',
      link: '/disclosure/birth-details'
    },
    {
      step: 3,
      title: 'Additional information',
      link: '/disclosure/additional-information'
    },
    {
      step: 4,
      title: 'Contact information',
      link: '/disclosure/contact-information'
    },
    {
      step: 5,
      title: 'Confirm & complete',
      link: '/disclosure/confirm-complete'
    }
  ],

  medical: [
    {
      step: 1,
      title: 'Absence details'
    },
    {
      step: 2,
      title: 'Medical history'
    },
    {
      step: 3,
      title: 'Confirm details'
    }
  ],

  healthCare: [
    {
      step: 1,
      title: 'Private healthcare form'
    },
    {
      step: 2,
      title: 'Confirm details'
    }
  ],
  healthAndSafety: [
    {
      step: 1,
      title: 'Health surveillance'
    },
    {
      step: 2,
      title: 'Confirm details'
    }
  ],

  healthAndSafetyQuestionnaire: [
    {
      step: 1,
      title: 'Health surveillance'
    },
    {
      step: 2,
      title: 'Health Question 1'
    },
    {
      step: 3,
      title: 'Health Question 2'
    },

    {
      step: 4,
      title: 'Occupational history'
    },
    {
      step: 5,
      title: 'Confirm details'
    }
  ],

  wages: [
    {
      step: 1,
      title: 'Employee statement'
    },
    {
      step: 2,
      title: 'Student loan'
    },
    {
      step: 3,
      title: 'Payment details'
    },
    {
      step: 4,
      title: 'Confirm details'
    }
  ]
}

export default stepperSteps
