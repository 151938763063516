import moment from 'moment'

const overviewDisclosureBirthDetails = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'Date of birth',
    dd: moment.utc(data.DateOfBirth).format('DD/MM/YYYY')
  },
  {
    dt: 'Town of birth',
    dd: data.TownOfBirth
  },
  {
    dt: 'Country of birth',
    dd: data.CountryOfBirth && data.CountryOfBirth.CountryName
  },
  {
    dt: 'Nationality',
    dd: data.Nationality && data.Nationality.Name
  },
  {
    dt: 'Mother‘s maiden name',
    dd: data.MothersMaidenName
  }
]
}
export default overviewDisclosureBirthDetails
