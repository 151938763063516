import React from 'react'
import { navigate } from '@reach/router';
import { Text, margin, Button } from 'chassis-react'

import ContentWrap from '../../../components/ContentWrap'
import Hero from '../../../components/Hero'
import Layout from '../../../components/Layout'

const ContactThanks = () => (
  <Layout>
    <Hero icon="chat">
      Contact & FAQs
    </Hero>

    <ContentWrap pad>
      <Text h1>
        Message submitted
      </Text>

      <Text p>Thank you for your message. We will be in touch as soon as possible.</Text>

      <Button
        primary
        onClick={() => navigate('/')}
        util={margin.t5}
      >
        Home
      </Button>
    </ContentWrap>
  </Layout>
)

export default ContactThanks