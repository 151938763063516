import React from 'react'
import { Base, text, margin } from 'chassis-react'

import Layout from '../Layout'
import Spinner from '../Spinner'

import './style.scss'

const ContentLoader = () => (
  <Layout>
    <Base util={[text.center, margin.v10]}>
      <Spinner />
    </Base>
  </Layout>
)

export default ContentLoader
