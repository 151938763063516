import React, { useContext } from 'react'

import { Base, Button, Text, border, color, margin, padding, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import DetailsList from '../../../../components/DetailsList'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Stepper from '../../../../components/Stepper'

const LifeAssuranceConfirm = () => {
  const user = useContext(UserContext)
  const { Loaded, Steps, handleUpdateStep } = user
  const userTaskLifeAssurance = Steps.find(step => step.Step.Id === 6)
  const Completed = userTaskLifeAssurance && userTaskLifeAssurance.Completed
  const lifeAssuranceDetail = (name, relationship, percentage) => [
    {
      dt: 'Name',
      dd: name
    },
    {
      dt: 'Relationship',
      dd: relationship
    },
    {
      dt: 'Percentage',
      dd: `${percentage}%`
    }
  ]
  const lifeAssuranceList = () => {
    const { LifeAssurance } = user
    return LifeAssurance.map(item => lifeAssuranceDetail(item.Name, item.Relationship, item.Percentage))
  }

  return Loaded ? (
    <Layout>
      <Hero icon="person" preTitleMobile="Step 2 of 9">
        Your personal details
      </Hero>

      {!Completed && <Stepper steps={stepperSteps.lifeAssurance} currentStep={2} />}

      <ContentWrap pad>
        <Text h4 as="h1">
          Check your details before submitting
        </Text>

        <Base util={[padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
          {lifeAssuranceList().map((item, i) => (
            <DetailsList
              key={i}
              title={`Beneficiary ${i + 1}`}
              editLink="/life-assurance/details"
              listItems={item}
            />
          ))}
        </Base>

        <Button
          success
          block
          util={[width.resetSm, margin.t3, position.floatRight]}
          onClick={() => handleUpdateStep(userTaskLifeAssurance.Id)}
        >
          Confirm life assurance details
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default LifeAssuranceConfirm
