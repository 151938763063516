const overviewLifeAssurance = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'Name',
    dd: data.Name
  },
  {
    dt: 'Relationship',
    dd: data.Relationship
  },
  {
    dt: 'Percentage',
    dd: `${data.Percentage} %`
  }
]
}
export default overviewLifeAssurance
