import React, { useContext } from 'react'
import { Button, Form, Text, margin, padding, position, width, shape } from 'chassis-react'
import { navigate } from '@reach/router'
import { UserContext } from '../../../../context/UserContext'
import stepperSteps from '../../../../config/stepperSteps'
import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormTextInput from '../../../../components/FormTextInput'

import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import './style.scss'

const HealthQuestion1 = () => {
  const user = useContext(UserContext)

  const { Loaded, handleChangeTools, HandArmVibration } = user
  const {
    AnyOtherProblemsWithMusclesOrJointsOfHandsArms,
    DifficultyPickingUpSmallItemsOrOpeningJars,
    EverHadNeckArmOrHandInjuryOrOperation,
    EverHadNeckArmOrHandInjuryOrOperationDetails,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
    OnAnyLongTermMedicationDetails,
    OnAnyLongTermMedication
  } = HandArmVibration

  const handleSubmit = e => {
    e.preventDefault()
    navigate('/health-and-safety/occupational-history')
  }

  return Loaded ? (
    <Layout>
      {<Stepper steps={stepperSteps.healthAndSafetyQuestionnaire} currentStep={3} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>

            <Text util={[padding.t2]}>
              Are you experiencing any other problems with the muscles or joints of the hands or arms?
            </Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: AnyOtherProblemsWithMusclesOrJointsOfHandsArms === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: AnyOtherProblemsWithMusclesOrJointsOfHandsArms === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="AnyOtherProblemsWithMusclesOrJointsOfHandsArms"
              data-format="bool"
            />

            <Text util={[padding.t2]}>
              Do you have difficulty picking up very small objects (such as screws or buttons) or opening
              tight jars?
            </Text>
            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: DifficultyPickingUpSmallItemsOrOpeningJars === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: DifficultyPickingUpSmallItemsOrOpeningJars === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="DifficultyPickingUpSmallItemsOrOpeningJars"
              data-format="bool"
            />

            <Text util={[padding.t2]}>Have you ever had a neck, arm or hand injury or operation?</Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: EverHadNeckArmOrHandInjuryOrOperation === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: EverHadNeckArmOrHandInjuryOrOperation === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="EverHadNeckArmOrHandInjuryOrOperation"
              data-format="bool"
            />
            {EverHadNeckArmOrHandInjuryOrOperation === true && (
              <>
                <Text util={[padding.t2]}>Please give details of this</Text>
                <Form hint>maximum length of 500 characters</Form>
                <FormTextInput
                  onChange={handleChangeTools}
                  required
                  value={EverHadNeckArmOrHandInjuryOrOperationDetails}
                  name="EverHadNeckArmOrHandInjuryOrOperationDetails"
                  textarea
                  rows="5"
                />
              </>
            )}
            <Text util={[padding.t2]}>
              Have you ever had any serious diseases of your joints, skin, nerves, heart or blood vessels?
            </Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels"
              data-format="bool"
            />
            {AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels === true && (
              <>
                <Text util={[padding.t2]}>Please give details of this</Text>
                <Form hint>maximum length of 500 characters</Form>
                <FormTextInput
                  onChange={handleChangeTools}
                  name="AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails"
                  textarea
                  rows="5"
                  value={AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails}
                  required
                />
              </>
            )}
            <Text util={[padding.t2]}>Are you on any long-term medication?</Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: OnAnyLongTermMedication === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: OnAnyLongTermMedication === false
                }
              ]}
              onChange={handleChangeTools}
              name="OnAnyLongTermMedication"
              data-format="bool"
              required
            />
            {OnAnyLongTermMedication === true && (
              <>
                <Text util={[padding.t2]}>Please give details of this</Text>
                <Form hint>maximum length of 500 characters</Form>
                <FormTextInput
                  onChange={handleChangeTools}
                  required
                  name="OnAnyLongTermMedicationDetails"
                  textarea
                  rows="5"
                  value={OnAnyLongTermMedicationDetails}
                />
              </>
            )}
          </Panel>

          <Button
            type="buttom"
            success
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          >
            Next: Occupational history
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default HealthQuestion1
