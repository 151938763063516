import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import FileBase64 from 'react-file-base64'

import './style.scss'

class DocumentUpload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bg: ''
    }
  }

  changeBg = () => {
    this.setState({
      bg: '#e5e5e5'
    })
  }

  revertBg = () => {
    this.setState({
      bg: ''
    })
  }

  render() {
    const { name, onDone } = this.props
    const { bg } = this.state

    return (
      <Fragment>
        <label
          htmlFor={name}
          className="file-upload"
          style={{ background: bg }}
          onDragEnter={this.changeBg}
          onDragLeave={this.revertBg}
          onMouseLeave={this.revertBg}
        >
          Drop file to upload, or click to browse
          <FileBase64 name={name} id={name} multiple onDone={onDone} />
        </label>
      </Fragment>
    )
  }
}

DocumentUpload.propTypes = {
  name: PropTypes.string,
  onDone: PropTypes.func
}

export default DocumentUpload
