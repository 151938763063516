import moment from 'moment'

const overviewDocuments = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'Title',
    dd: data.Title
  },
  {
    dt: 'File name',
    dd: data.Filename
  },
  {
    dt: 'Uploaded by',
    dd: data.UploadedBy
  },
  {
    dt: 'Uploaded date',
    dd: moment.utc(data.UploadedDate).format('DD/MM/YYYY')
  },
  {
    dt: 'Signed date',
    dd: data.DateSigned ? moment.utc(data.DateSigned).format('DD/MM/YYYY') : 'Not signed yet'
  }
]
}
export default overviewDocuments
