const overviewDisclosureAdditionalDetails = data => {  
  if (!Object.keys(data).length) {
    return null
  }

  return [
  {
    dt: 'UK National insurance number',
    dd: data.UkNationalInsuranceNumber
  },
  {
    dt: 'Do you have a passport?',
    dd: data.HasPassport ? 'Yes' : 'No'
  },
  {
    dt: 'Passport number',
    dd: data.PassportNumber
  },
  {
    dt: 'Passport country of issue',
    dd: data.PassportCountryOfIssue && data.PassportCountryOfIssue.CountryName || ''
  },
  {
    dt: 'Do you have a driving licence?',
    dd: data.HasDrivingLicence ? 'Yes' : 'No'
  },
  {
    dt: 'Driving licence number',
    dd: data.DrivingLicenceNumber
  },
  {
    dt: 'Driving licence country of issue',
    dd: data.DrivingLicenceCountryOfIssue && data.DrivingLicenceCountryOfIssue.CountryName
  },
  {
    dt: 'National ID card',
    dd: data.HasNationalIdentityCard ? 'Yes' : 'No'
  },
  {
    dt: 'National ID card country of issue',
    dd: data.NationalIdentityCardCountryOfIssue && data.NationalIdentityCardCountryOfIssue.CountryName
  },
  {
    dt: 'National ID card number',
    dd: data.NationalIdentityCardNumber
  },
  
]
}

export default overviewDisclosureAdditionalDetails
