import React from 'react'
import PropTypes from 'prop-types'

import { Base, Text, margin, padding } from 'chassis-react'

import FormTextInput from '../../../../../components/FormTextInput'
import Panel from '../../../../../components/Panel'

import getErrorForField from '../../../../../utilities/getErrorForField'
import handleBlur from '../../../../../utilities/handleBlur'

const DietaryRequirementsForm = ({ user, errors, setErrors, schema }) => {
  const { Apprentice } = user

  return (
    <Panel borderedSm bgWhiteSm util={[margin.b3, padding.a3Sm]}>
      <Text h3>Dietary requirements</Text>
      <Text>
        Do you have any dietary requirements? If so, please enter in the box below. If you have none, please
        leave blank.
      </Text>

      <Base util={margin.b3}>
        <FormTextInput
          error={getErrorForField('DietaryRequirements', errors)}
          onBlur={e => handleBlur(e, schema, Apprentice, setErrors)}
          id="DietaryRequirements"
          label="Dietary requirements"
          name="DietaryRequirements"
          type="text"
          max={250}
          value={Apprentice.DietaryRequirements}
          onChange={user.handleChangeApprentice}
        />
      </Base>
    </Panel>
  )
}

DietaryRequirementsForm.propTypes = {
  user: PropTypes.shape({
    Apprentice: PropTypes.shape({
      dietaryRequirements: PropTypes.string
    })
  }),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      message: PropTypes.string
    })
  ),
  setErrors: PropTypes.func,
  schema: PropTypes.shape({})
}

export default DietaryRequirementsForm
