const overviewContactDetails = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'Telephone number',
    dd: data.TelNumber
  },
  {
    dt: 'Email address',
    dd: data.EmailAddress
  }
]
}
export default overviewContactDetails
