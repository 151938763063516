import React from 'react'
import PropTypes from 'prop-types'
import { Base, Text } from 'chassis-react'

const ApprenticeThankYou = ({ startDate }) => (
  <>
    <Base>
      <Text as="h2">Next Steps</Text>
      <Text>
        Thank you for all the information. If we need anything else from you, then we will get in touch by
        phone, if not we&apos;ll see you soon!
      </Text>

      <Text>
        Your start date is <strong>{startDate}</strong>. Good Luck!
      </Text>
    </Base>
  </>
)

ApprenticeThankYou.propTypes = {
  startDate: PropTypes.string
}

export default ApprenticeThankYou
