import React, { useState, useContext } from 'react'
import {
  Button,
  Card,
  display,
  text,
  color,
  Form,
  Grid,
  Text,
  margin,
  List,
  padding,
  position,
  width,
  shape
} from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormTextInput from '../../../../components/FormTextInput'
import FormSelectInput from '../../../../components/FormSelectInput'
import ValidationAlertList from '../../../../components/ValidationAlertList'
import FormDivider from '../../../../components/FormDivider'

import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import relationship from './helper/index'

const History = () => {
  const user = useContext(UserContext)
  const [isAgreedToAddSpouse, setAgreeToAddSpouse] = useState('')
  const [Firstname, setFirstname] = useState('')
  const [Surname, setSurname] = useState('')
  const [Relationship, setRelationship] = useState('')
  const [DateOfBirth, setDateOfBirth] = useState('')
  const [Gender, setGender] = useState('')
  const [isFormUpdated, setIsFormUpdated] = useState(false)

  const {
    Loaded,
    handleAddDependents,
    handleAddNewDependent,
    handleDeleteDependent,
    Steps,
    dependentList,
    handleSubmitJoinAxaHealthcareWithoutDependents,
    handleSubmitJoinAxaHealthcareWithDependents,
    handleSubmitRefuseTojoinAxaHealthcare,
    handleUpdateJoinAxaHealthcare,
    isJoinedAxaHealthcare,
    AxaHealthCareValidationErrors
  } = user
  const userTAxaForm = Steps.find(step => step.Step.Id === 9)

  const handleSubmitFormWithDependents = () => {
    handleSubmitJoinAxaHealthcareWithDependents()
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleAddDependents(dependentList)
  }

  const resetMemberForm = () => {
    setFirstname('')
    setSurname('')
    setDateOfBirth('')
    setGender('')
    setRelationship('')
  }
  const handleChangeSpouse = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    setAgreeToAddSpouse(value === 'true')
  }
  const handleChangeAxa = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    handleUpdateJoinAxaHealthcare(value === 'true')
  }

  const handleChangeFirstname = e => {
    e.preventDefault()
    const { value } = e.target
    setFirstname(value)
  }

  const handleChangeSurname = e => {
    e.preventDefault()
    const { value } = e.target
    setSurname(value)
  }

  const handleChangeRelationship = e => {
    e.preventDefault()
    const { value } = e.target
    setRelationship(value)
  }

  const handleChangeGender = e => {
    const { value } = e.target

    setGender(value)
  }
  const handleChangeDOB = e => {
    e.preventDefault()
    const { value } = e.target
    setDateOfBirth(value)
  }

  const handleAddMember = () => {
    setIsFormUpdated(true)

    handleAddNewDependent({
      Id: 0,
      Firstname,
      Surname,
      Relationship,
      DateOfBirth,
      Gender
    })
    resetMemberForm()
  }

  const handleDelete = i => {
    setIsFormUpdated(true)
    handleDeleteDependent(i)
  }

  const submitAxa = () => {
    setIsFormUpdated(true)
    if (isJoinedAxaHealthcare) {
      if (isAgreedToAddSpouse) {
        return handleSubmitFormWithDependents()
      }
      return handleSubmitJoinAxaHealthcareWithoutDependents()
    }
    handleSubmitRefuseTojoinAxaHealthcare()
  }

  return Loaded ? (
    <Layout>
      {!userTAxaForm.Completed && <Stepper steps={stepperSteps.healthCare} currentStep={1} />}

      {AxaHealthCareValidationErrors && <ValidationAlertList errors={AxaHealthCareValidationErrors} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: isJoinedAxaHealthcare
                },
                {
                  label: 'No',
                  value: false,
                  checked: isJoinedAxaHealthcare === false
                }
              ]}
              name="OtherSignificantHealthProblems"
              onChange={handleChangeAxa}
              label="Would you like to be enrolled in the AXA Health plan?"
              data-format="bool"
            />
            {isJoinedAxaHealthcare && (
              <div>
                <Text h5 as="h5">
                  Would you like to add a spouse/partner or children (up to the age of 21) living at the same
                  address to your plan?
                </Text>
                <Text>
                  You are allowed to add your spouse/partner and children to your healthcare scheme at your
                  own cost, provided they live with you and fulfil the age criteria of the healthcare scheme.
                </Text>
                <Text>If you’re adding your spouse/partner, they must be under 65.</Text>
                <Text>Children must be under 21.</Text>
                <Text>
                  With the exception of the age limitations detailed above ,family members’ enrolment in the
                  Group plan is on exactly the same terms as for employees. These are:
                </Text>
                <List util={[margin.b4, margin.b0Sm]}>
                  <List item>
                    There is a £120 benefit limitation for each person on a 12-month rolling basis.
                  </List>
                  <List item>
                    The rules of the scheme exclude benefits for any medical condition which was already in
                    existence when you joined; that exclusion continues for two years.
                  </List>
                  <List item>
                    All treatment should be pre-authorised before incurring costs to confirm eligibility. You
                    need to contact us prior to each stage of treatment to choose a selected provider to treat
                    you and ensure the trustee will cover the whole cost of treatment.
                  </List>
                </List>

                <Text h6 as="h6" util={[margin.t3, margin.b0Sm]}>
                  The cost to employees
                </Text>

                <Text util={[margin.t3]}>
                  If you wish to add your family members, please tick the appropriate box. The contributions
                  quoted are annual but the payment will be by salary deduction on a monthly basis.
                </Text>

                <Text h6 as="h6">
                  Category Annual subscription
                </Text>

                <List util={[margin.b2]}>
                  <List item>Spouse/Partner: £185 (£15.42 monthly)</List>
                  <List item>
                    Spouse/Partner plus any number of children living with you, up to age 21: £300 (£25.00
                    monthly)
                  </List>
                  <List item>
                    Single parent for any number of children living with you up to age 21: £150 (£12.50
                    monthly)
                  </List>
                </List>

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: isAgreedToAddSpouse === true
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: isAgreedToAddSpouse === false
                    }
                  ]}
                  name="spouse"
                  onChange={handleChangeSpouse}
                  data-format="bool"
                />

                {isAgreedToAddSpouse === false ||
                (isAgreedToAddSpouse === '' && dependentList.length === 0) ? (
                  ''
                ) : (
                  <>
                    <FormDivider />
                    <Grid row>
                      <Grid colSm={6}>
                        <Text h3 as="h3" util={[margin.b3]}>
                          Please provide details
                        </Text>
                        <FormTextInput
                          label="First name"
                          name="Firstname"
                          onChange={handleChangeFirstname}
                          value={Firstname}
                          disabled={!isAgreedToAddSpouse}
                        />

                        <FormTextInput
                          label="Last name"
                          name="Surname"
                          onChange={handleChangeSurname}
                          value={Surname}
                          disabled={!isAgreedToAddSpouse}
                        />

                        <FormSelectInput
                          name="AddressSelect"
                          label="Relationship to eligible employee"
                          onChange={handleChangeRelationship}
                          value={Relationship}
                          disabled={!isAgreedToAddSpouse}
                        >
                          <option value="" disabled={!isAgreedToAddSpouse}>
                            Please select...
                          </option>
                          {relationship.map((item, i) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </FormSelectInput>

                        <FormTextInput
                          name="DateOfBirth"
                          type="date"
                          label="Date of birth (DD/MM/YYYY)"
                          value={DateOfBirth}
                          onChange={handleChangeDOB}
                          placeholder="yyyy-mm-dd"
                          pattern="\d{4}-\d{2}-\d{2}"
                          disabled={!isAgreedToAddSpouse}
                        />

                        <FormRadioGroup
                          options={[
                            {
                              label: 'Male',
                              value: 'Male',
                              checked: Gender !== '' && Gender !== 'Female'
                            },
                            {
                              label: 'Female',
                              value: 'Female',
                              checked: Gender !== '' && Gender !== 'Male'
                            }
                          ]}
                          disabled={!isAgreedToAddSpouse}
                          name="Gender"
                          id="Gender"
                          label="Gender"
                          onChange={handleChangeGender}
                        />

                        <Button
                          primary
                          util={margin.t1}
                          type="button"
                          onClick={handleAddMember}
                          disabled={!Firstname || !Surname || !Relationship || !DateOfBirth || !Gender}
                        >
                          {dependentList.length > 0 ? 'Add another member to your policy' : 'Add member'}
                        </Button>

                        {dependentList &&
                          dependentList.length > 0 &&
                          dependentList.map((item, i) => (
                            <ul>
                              <li key={i}>
                                <Card util={margin.t4}>
                                  <Card content>
                                    <strong>First name</strong>
                                    <br />
                                    {item.Firstname}
                                  </Card>

                                  <Card content>
                                    <strong>Last name</strong>
                                    <br />
                                    {item.Surname}
                                  </Card>

                                  <Card content>
                                    <strong>Relationship</strong>
                                    <br />
                                    {item.Relationship}
                                  </Card>

                                  <Card content>
                                    <strong>Date of birth</strong>
                                    <br />
                                    {item.DateOfBirth}
                                  </Card>

                                  <Card content>
                                    <strong>Gender</strong>
                                    <br />
                                    {item.Gender}
                                  </Card>

                                  <Card content util={text.right}>
                                    <Button
                                      type="button"
                                      link
                                      danger
                                      small
                                      util={[display.inlineBlock, color.acRed]}
                                      onClick={() => handleDelete(i)}
                                    >
                                      Remove
                                    </Button>
                                  </Card>
                                </Card>
                              </li>
                            </ul>
                          ))}
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            )}
          </Panel>

          <Button
            type="buttom"
            onClick={submitAxa}
            success
            disabled={
              isJoinedAxaHealthcare === '' ||
              (isFormUpdated === false && userTAxaForm.Completed) ||
              (isAgreedToAddSpouse && dependentList.length === 0)
            }
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          >
            {userTAxaForm.Completed ? 'Update' : 'Confirm: Private healthcare details'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default History
