import * as yup from 'yup'

export default (() => {
  function toUTC() {
    return this.transform((_value, originalValue) =>
      new Date(new Date(originalValue).toUTCString())
    )
  }
  
  yup.addMethod(yup.date, 'toUTC', toUTC)
})()