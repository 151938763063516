import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from '@reach/router'

import './style.scss'

const linkList = [
  { StepId: 1, url: 'account' },
  { StepId: 2, url: 'details' },
  { StepId: 3, url: 'contract-documents' },
  { StepId: 4, url: 'disclosure' },
  { StepId: 5, url: 'wages' },
  { StepId: 6, url: 'life-assurance' },
  { StepId: 7, url: 'medical' },
  { StepId: 8, url: 'apprentice' },
  { StepId: 9, url: 'axa-healthcare' },
  { StepId: 10, url: 'health-and-safety' }

]

const excludedIds = stepId => {
  const stepIdArray = [1, 4, 5, 8]
  const stepIdArrayReturnBoolValue = stepIdArray.includes(stepId)
  return stepIdArrayReturnBoolValue
}

const StepperList = ({ steps }) => {
  if (!steps) return null

  const findLinkFor = item => {
    const link = linkList.find(linkItem => linkItem.StepId === item.StepId) 
    if(link.url){
      return link.url
    }
    return '/'
  }

  return (
    <ul className="stepper-list">
      {steps.map((item, i) => (
        <li
          className={classNames(
            'stepper-list__item',
            item.Completed && 'stepper-list__item--completed',
            item.IsActive && 'stepper-list__item--active'
          )}
          key={item.StepId}
          data-id={i + 1}
        >
          {item.IsActive || (item.Completed && !excludedIds(item.StepId)) ? (
            <Link style={{ display: 'inline-block' }} to={findLinkFor(item)}>
              {item.Step.Name}
            </Link>
          ) : (
            item.Step.Name
          )}
        </li>
      ))}
    </ul>
  )
}

StepperList.propTypes = {
  steps: PropTypes.instanceOf(Array)
}

export default StepperList
