import React, { useState, useContext } from 'react'
import {
  Button,
  Card,
  Form,
  Grid,
  Text,
  color,
  display,
  margin,
  padding,
  position,
  shape,
  text,
  width
} from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import { LifeAssuranceDetailsSchema } from './schema'
import getErrorForField from '../../../../utilities/getErrorForField'
import handleBlur from '../../../../utilities/handleBlur'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import ValidationAlertList from '../../../../components/ValidationAlertList'

import focusOnError from '../../../../utilities/focusOnError'

const LifeAssuranceDetails = () => {
  const user = useContext(UserContext)
  const {
    Loaded,
    Steps,
    handleSubmitLifeAssurance,
    LifeAssurance,
    handleDeleteLifeAssurance,
    handleAddLifeAssuranceBeneficiary
  } = user
  const userTaskLifeAssurance = Steps.find(step => step.Step.Id === 6)
  const Completed = userTaskLifeAssurance && userTaskLifeAssurance.Completed
  const [name, setName] = useState('')
  const [relationship, setRelationship] = useState('')
  const [percentage, setPercentage] = useState('')
  const [ValidationErrors, setValidationErrors] = useState([])

  const calculatePercent = int => {
    const percentageArray = []
    const reducer = (accumulator, currentValue) => accumulator + currentValue
    LifeAssurance.forEach(el => {
      percentageArray.push(el.Percentage)
    })
    if (percentageArray.length > 0) {
      return int - percentageArray.reduce(reducer)
    }
    return int
  }

  const handleAddBeneficiaryButtonClick = e => {
    e.preventDefault()
    setValidationErrors([])

    LifeAssuranceDetailsSchema
      .validate(
        {
          Name: name,
          Relationship: relationship,
          Percentage: percentage
        },
        { abortEarly: false }
      )
      .then(() => {
        handleAddLifeAssuranceBeneficiary(e, name, relationship, percentage)
        setName('')
        setRelationship('')
        setPercentage('')
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const handleSubmit = e => {
    setValidationErrors([])
    if (!LifeAssurance.length) {
      e.preventDefault()
      setValidationErrors([{ 
        path: 'General',
        message: 'You must add at least one beneficiary' 
      }])
      return focusOnError()
    }

    if (LifeAssurance.reduce((accum, current) => accum + current.Percentage, 0) !== 100) {
      e.preventDefault()
      setValidationErrors([{ 
        path: 'General',
        message: 'The percentage for all beneficiaries must total 100%' 
      }])
      return focusOnError()
    }
  }

  const percentAtZero = calculatePercent(100) <= 0

  return Loaded ? (
    <Layout>
      {getErrorForField('General', ValidationErrors) && (
        <ValidationAlertList errors={ValidationErrors} />
      )}

      {!Completed && <Stepper steps={stepperSteps.lifeAssurance} currentStep={1} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmitLifeAssurance}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <Text>
              In the event of my death I would like to nominate the following person(s) as beneficiaries from
              any benefits under the above scheme.
            </Text>

            <Grid row>
              <Grid colSm={6}>
                <FormTextInput
                  label="Name"
                  name="Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  disabled={percentAtZero}
                  error={getErrorForField('Name', ValidationErrors)}
                  onBlur={e => handleBlur(e, LifeAssuranceDetailsSchema, {Name: name}, setValidationErrors)}
                />

                <FormTextInput
                  label="Relationship"
                  name="Relationship"
                  value={relationship}
                  onChange={e => setRelationship(e.target.value)}
                  disabled={percentAtZero}
                  error={getErrorForField('Relationship', ValidationErrors)}
                  onBlur={e => handleBlur(e, LifeAssuranceDetailsSchema, {Relationship: relationship}, setValidationErrors)}
                />

                <FormTextInput
                  label="Percentage"
                  name="Percentage"
                  type="number"
                  hint="Enter a figure between 1 and 100"
                  afterText={`/ ${calculatePercent(100)}%`}
                  util={[display.inline, width.w3]}
                  value={percentage}
                  max={`${calculatePercent(100)}`}
                  min="0"
                  onChange={e => setPercentage(+e.target.value)}
                  disabled={percentAtZero}
                  error={getErrorForField('Percentage', ValidationErrors)}
                  onBlur={e => handleBlur(e, LifeAssuranceDetailsSchema, {Percentage: percentage}, setValidationErrors)}
                />

                <Button
                  primary
                  util={margin.t1}
                  onClick={handleAddBeneficiaryButtonClick}
                  disabled={percentAtZero || calculatePercent(100) - percentage < 0}
                >
                  {LifeAssurance.length > 0 ? 'Add another beneficiary' : 'Add beneficiary'}
                </Button>

                <ul>
                  {LifeAssurance.map((item, i) => (
                    <li key={i}>
                      <Card util={margin.t4}>
                        <Card content>
                          <strong>Name</strong>
                          <br />
                          {item.Name}
                        </Card>

                        <Card content>
                          <strong>Relationship</strong>
                          <br />
                          {item.Relationship}
                        </Card>

                        <Card content>
                          <strong>Amount</strong>
                          <br />
                          {item.Percentage}%
                        </Card>

                        <Card content util={text.right}>
                          <Button
                            link
                            danger
                            small
                            util={[display.inlineBlock, color.acRed]}
                            onClick={e => handleDeleteLifeAssurance(e, i)}
                          >
                            Remove
                          </Button>
                        </Card>
                      </Card>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Panel>

          <Button success util={position.floatRight} onClick={handleSubmit}>
            {Completed ? 'Update' : 'Next: Confirm life assurance details'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default LifeAssuranceDetails
