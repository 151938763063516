import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link, navigate } from '@reach/router'
import { Base, Button, Card, Text, position, margin, scale } from 'chassis-react'
import Modal from '../../../components/Modal'
import SendDocumentModal from '../../../components/SendDocumentModal'
import api from '../../../api'
import auth from '../../../auth'
import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import DetailsList from '../../../components/DetailsList'
import Layout from '../../../components/Layout'
import overviewDetailsSender from './TermsOverview/overviewDetailsSender'
import overviewDetailsEmployee from './TermsOverview/overviewDetailsEmployee'

import './style.scss'

const TermsOverview = ({ id, isConfirming }) => {
  const [userDetails, setUserDetails] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [pdfData, setPdfData] = useState(null)
  const [missingDocumentId, setMissingDocumentId] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (id) {
        await api.terms.getById(id).then(responses => {
          
            const { data } = responses
            setUserDetails(data)

            if (!isConfirming) {
              if(data.PTCMSEmployeeDocumentId){
                api.terms.getDocument(data.PTCMSEmployeeDocumentId).then(docResponses => {
                  setPdfData(docResponses.data)
                })
              } else {
                setMissingDocumentId(true)
              }
            }
            setLoaded(true)
          
        })
      } else {
        setLoaded(true)
      }
    })()
  }, [])

  const handleConfirm = e => {
    e.preventDefault()
    setLoaded(false)

    api.terms.getConfirmLetter(userDetails.Id).then(() => {
        auth.updateSession({ RecentlyCompletedLetter: +userDetails.EmployeeId })
        navigate(`/admin/terms/list/`)
        setLoaded(true)
    })
  }

  const togglePreviewModal = () => setShowPreviewModal(!showPreviewModal)

  const closeSendDocumentModal = e => {
    e.preventDefault()
    setShowConfirmModal(false)
  }

  return loaded ? (
    <Layout>
      <ContentWrap util={margin.t6}>
        <Text p>
          <Link to="/admin/terms">&lt; Back to internal T &amp; C letters</Link>
        </Text>

        <Text h1 util={[scale.fs5, margin.b6]}>
          View document details
        </Text>

        {!isConfirming && (
          <div className="terms-defs">
            <p className="terms-def">
              <strong>Date sent</strong>: {userDetails.DateSent}
            </p>
            <p className="terms-def">
              <strong>Status:</strong>{' '}
              <span
                className={`${userDetails.Status === 'Not Accepted' &&
                  'terms-not-accepted'} ${userDetails.Status === 'Accepted' && 'terms-accepted'} terms-muted`}
              >
                {userDetails.Status}
              </span>
            </p>

            <p className="terms-def">
              <strong>Date viewed</strong>:{' '}
              {userDetails.DateViewed ? (
                moment(userDetails.DateViewed).format('DD/MM/YYYY HH:mm')
              ) : (
                <span className="terms-not-accepted">Unread</span>
              )}
            </p>
          </div>
        )}

        <Card>
          <Card content>
            <DetailsList
              title="Employee details"
              editLink={isConfirming && `/admin/terms/${id}`}
              listItems={overviewDetailsEmployee(userDetails)}
            />
            <DetailsList
              title="Sender details"
              editLink={isConfirming && `/admin/terms/${id}`}
              listItems={overviewDetailsSender(userDetails)}
            />
          </Card>
        </Card>
        {(isConfirming || !!missingDocumentId) ? (
          <Button
            onClick={() => setShowConfirmModal(true)}
            success
            type="submit"
            util={[position.floatRight, margin.h1, margin.v3]}
          >
            Send document
          </Button>
        ) : (
          <>
            {pdfData && (
              <Base util={[margin.t3, position.floatRight]}>
                <Button secondary onClick={togglePreviewModal} util={[margin.r1]}>
                  View document
                </Button>
                <Button
                  as="a"
                  secondary
                  href={`data:${pdfData.ContentType};base64,${pdfData.Data}`}
                  download={pdfData.Filename}
                >
                  Download document
                </Button>
              </Base>
            )}
          </>
        )}

        {showConfirmModal && (
          <SendDocumentModal title="Are you sure?" onClick={closeSendDocumentModal}>
            <div>
              <p>We reccommend double checking the document details before sending to the employee.</p>
              <form onSubmit={handleConfirm}>
                <Button block success type="submit">
                  Confirm and send document
                </Button>
                <Button onClick={closeSendDocumentModal} block>
                  Cancel
                </Button>
              </form>
            </div>
          </SendDocumentModal>
        )}

        {pdfData && showPreviewModal && (
          <Modal onClick={() => setShowPreviewModal(!showPreviewModal)}>
            {
              <object
                data={`data:${pdfData.ContentType};base64,${pdfData.Data}`}
                type={pdfData.ContentType}
                width="100%"
                height="100%"
              >
                This browser does not support inline PDFs.
              </object>
            }
          </Modal>
        )}
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

TermsOverview.propTypes = {
  id: PropTypes.string,
  isConfirming: PropTypes.bool
}

export default TermsOverview
