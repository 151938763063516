import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import { Grid, Form, Button, Table, margin, width, position, cursor, color, text } from 'chassis-react'
import moment from 'moment'

import api from '../../../api'

import toTitleCase from '../../../utilities/toTitleCase'

import ContentLoader from '../../../components/ContentLoader'
import Layout from '../../../components/Layout'

import './style.scss'
import imgLocked from './locked.svg'
import accessRole from '../../../services/accessRole'

const Dashboard = () => {
  const [regions, setRegions] = useState([])
  const [newStarts, setNewStarts] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState()
  const [filteredNewStarts, setFilteredNewStarts] = useState([])
  const [sortOrderAsc, setSortOrderAsc] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (!regions.length) {
        await api.region.getAll().then(response => {
          setRegions(response.data)
        })
      }
      const method = selectedRegion ? 'getByRegion' : 'getAll'
      await api.newStart[method](selectedRegion).then(response => {
        setNewStarts(response.data)
        setFilteredNewStarts(response.data)
      })
      setLoaded(true)
    })()
  }, [selectedRegion])

  const searchFilter = e => {
    e.preventDefault()
    const { value } = e.target
    const filtered = newStarts.filter(newStart =>
      newStart.Surname.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredNewStarts(filtered)
  }

  const compareByText = key => (a, b) => {
    setSortOrderAsc(!sortOrderAsc)
    if (sortOrderAsc) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1
    }
    if (!sortOrderAsc) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return 1
      if (a[key].toLowerCase() > b[key].toLowerCase()) return -1
    }
    return 0
  }

  const compareByStatus = key => (a, b) => {
    setSortOrderAsc(!sortOrderAsc)
    const compareA = a[key].filter(step => step.Completed === true).length
    const compareB = b[key].filter(step => step.Completed === true).length
    if (sortOrderAsc) {
      if (compareA < compareB) return -1
      if (compareA > compareB) return 1
    }
    if (!sortOrderAsc) {
      if (compareA < compareB) return 1
      if (compareA > compareB) return -1
    }
    return 0
  }

  const compareByCompleted = key => (a, b) => {
    setSortOrderAsc(!sortOrderAsc)

    if (sortOrderAsc) {
      if (a[key][4].Completed < b[key][4].Completed) return -1
      if (a[key][4].Completed > b[key][4].Completed) return 1
    }
    if (!sortOrderAsc) {
      if (a[key][4].Completed < b[key][4].Completed) return 1
      if (a[key][4].Completed > b[key][4].Completed) return -1
    }
    return 0
  }

  const compareByDate = key => (a, b) => {
    setSortOrderAsc(!sortOrderAsc)

    const dateA = new Date(a[key]).getTime()
    const dateB = new Date(b[key]).getTime()

    if (dateA === dateB) {
      return 0
    }

    const retVal = dateA > dateB ? 1 : -1

    return sortOrderAsc ? retVal : retVal / -1
  }

  const sortByText = key => {
    const arrayCopy = [...filteredNewStarts]
    arrayCopy.sort(compareByText(key))
    setFilteredNewStarts(arrayCopy)
  }

  const sortByStatus = key => {
    const arrayCopy = [...filteredNewStarts]
    arrayCopy.sort(compareByStatus(key))
    setFilteredNewStarts(arrayCopy)
  }

  const sortByCompleted = key => {
    const arrayCopy = [...filteredNewStarts]
    arrayCopy.sort(compareByCompleted(key))
    setFilteredNewStarts(arrayCopy)
  }

  const sortByDate = key => {
    const arrayCopy = [...filteredNewStarts]
    arrayCopy.sort(compareByDate(key))
    setFilteredNewStarts(arrayCopy)
  }

  const disclosureStatus = steps => {
    if (!steps.length) return '--'
    return steps.find(s => s.StepId === 4 && s.Completed) ? 'Completed' : '--'
  }

  const stepsCompleted = newStart => newStart.Steps.filter(step => step.Completed === true).length

  const handleDownloadReport = () => {
    api.wagesExport.get().then(({ data }) => {
      const d = new Date()
      const filename = `report-${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}.csv`

      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
      hiddenElement.target = '_blank'
      hiddenElement.download = filename
      document.body.appendChild(hiddenElement)
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  const handleMedicalInsuranceDownloadReport = () => {
    api.medicalInsuranceExport.get().then(({ data }) => {
      const d = new Date()
      const filename = `report-medical-insurance-${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}.csv`

      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
      hiddenElement.target = '_blank'
      hiddenElement.download = filename
      document.body.appendChild(hiddenElement)
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  const handleDownloadApprenticeReport = () => {
    api.apprenticeFormExport.get().then(({ data }) => {
      const d = new Date()
      const filename = `report-apprentice-forms-${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}.csv`

      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
      hiddenElement.target = '_blank'
      hiddenElement.download = filename
      document.body.appendChild(hiddenElement)
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  const handleDownloadHavsReport = () => {
    api.havsFormExport.get().then(({ data }) => {
      const d = new Date()
      const filename = `report-havs-data-${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}.csv`

      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
      hiddenElement.target = '_blank'
      hiddenElement.download = filename
      document.body.appendChild(hiddenElement)
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  return loaded ? (
    <Layout>
      <Grid container>
        <Form inline util={[margin.v4]} onSubmit={e => e.preventDefault()}>
          <Form group>
            <Form select onChange={e => setSelectedRegion(e.target.value)} id="selectRegion">
              <Form option value="">
                Select region...
              </Form>

              {regions &&
                regions.map(item => (
                  <Form option key={item.Id} value={item.Id}>
                    {item.Name}
                  </Form>
                ))}
            </Form>
          </Form>

          <Form group>
            <Form input onChange={searchFilter} placeholder="Search by surname" />
          </Form>

          <Form group util={position.floatRightSm}>
            {accessRole.allow(['recruitment', 'itDev']) && (
              <Button success as={Link} to="new-start">
                Create new profile
              </Button>
            )}

            {accessRole.allow('wages') && (
              <Button success onClick={handleDownloadReport} util={margin.l2}>
                Download Report
              </Button>
            )}

            {accessRole.allow('wages') && (
              <Button success onClick={handleMedicalInsuranceDownloadReport} util={margin.l2}>
                Download AXA OptOut Report
              </Button>
            )}
            {accessRole.allow('recruitment') && (
              <Button success onClick={handleDownloadApprenticeReport} util={margin.l2}>
                Download Apprentice Report
              </Button>
            )}
            {accessRole.isHealthAndSafetyUser && (
              <Button success onClick={handleDownloadHavsReport} util={margin.l2}>
                Download HAVS Report
              </Button>
            )}
          </Form>
        </Form>

        <Table bordered striped util={width.w12}>
          <Table head>
            <Table row>
              <Table header onClick={() => sortByText('Surname')} util={cursor.hand}>
                <span className="sortableColumn">Name</span>
              </Table>
              <Table header>NI number</Table>
              <Table header>Region</Table>
              <Table header onClick={() => sortByCompleted('Steps')} util={cursor.hand}>
                <span className="sortableColumn">Disclosure status</span>
              </Table>
              <Table header onClick={() => sortByStatus('Steps')} util={cursor.hand}>
                <span className="sortableColumn">Overall status</span>
              </Table>
              <Table header onClick={() => sortByDate('StartDate')} util={cursor.hand}>
                <span className="sortableColumn">Start date</span>
              </Table>
              <Table header onClick={() => sortByDate('LastLoginTime')} util={cursor.hand}>
                <span className="sortableColumn">Last Login</span>
              </Table>
              {accessRole.isMedicalConditionViewer() && (
                <Table header onClick={() => sortByText('HasMedicalCondition')} util={cursor.hand} id="hasMedicalCondition">
                  <span className="sortableColumn">Medical Condition</span>
                </Table>
              )}
              <Table header>View & edit</Table>
            </Table>
          </Table>

          <Table body id="users">
            {filteredNewStarts.map((item, i) => (
              <Table row key={i} util={item.IsAccountLocked ? color.bgAcYellow : null}>
                <Table cell>
                  {item.IsAccountLocked && (
                    <img src={imgLocked} alt="Locked account" className="locked-account-icon" />
                  )}
                  {`${toTitleCase(item.Title)} ${item.Firstname} ${item.Surname}`}
                </Table>
                <Table cell>{item.NINumber}</Table>
                <Table cell>{item.RegionName}</Table>
                <Table cell>{disclosureStatus(item.Steps)}</Table>
                <Table cell>
                  {stepsCompleted(item)} of {item.Steps.length} steps
                </Table>
                <Table cell>{item.StartDate ? moment(item.StartDate).format('DD/MM/YYYY') : 'Not set'}</Table>
                <Table cell>
                  {item.LastLoginTime ? moment.utc(item.LastLoginTime).format('DD MMM YYYY') : 'Not set'}
                </Table>
                {accessRole.isMedicalConditionViewer() && (
                  <Table cell util={[text.center]}>
                    {item.HasMedicalCondition}
                  </Table>
                )}
                <Table cell>
                  <Link to={`new-start/${item.Id}/overview`}>View/Edit</Link>
                </Table>
              </Table>
            ))}
          </Table>
        </Table>
      </Grid>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Dashboard
