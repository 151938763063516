import React, { useContext } from 'react'
import { Base, Button, border, color, display, margin, padding, position, text, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import DetailsList from '../../../../components/DetailsList'
import Layout from '../../../../components/Layout'
import Stepper from '../../../../components/Stepper'

const WagesConfirmDetails = () => {
  const user = useContext(UserContext)
  const userTaskWageInfo = user.Steps.find(step => step.Step.Id === 5)

  const employeeStatement = [
    {
      dd: user.WageInformation.EmployeeStatementType
    }
  ]

  const student = [
    {
      dt: 'Were you a student in higher eduction before the last 6 April?',
      dd: user.WageInformation.StudentBeforeSixthApril ? 'Yes' : 'No'
    },
    user.WageInformation.HasUnpaidStudentLoan && {
      dt: 'Do you have a Student Loan which is not fully repaid?',
      dd: 'Yes'
    },
    user.WageInformation.HasUnpaidStudentLoan && {
      dt:
        'Are you repaying your Student Loan direct to the Student Loans Company by agreed monthly payments?',
      dd: user.WageInformation.StudentLoanPaidDirectToStudentLoanCompany ? 'Yes' : 'No'
    },
    user.WageInformation.HasUnpaidStudentLoan && {
      dt: 'What type of Student Loan do you have?',
      dd: user.WageInformation.StudentLoanType
    },
    user.WageInformation.HasUnpaidPostgradLoan && {
      dt: 'Do you have a Postgraduate Loan which is not fully repaid?',
      dd: 'Yes'
    },
    user.WageInformation.HasUnpaidPostgradLoan && {
      dt: 'Did you complete or leave your Postgraduate studies before 6th April?',
      dd: user.WageInformation.PostgradStudentBeforeSixthApril ? 'Yes' : 'No'
    },
    user.WageInformation.PostgradStudentBeforeSixthApril && {
      dt: 'Are you repaying your Postgraduate Loan direct to the Student Loans Company by direct debit?',
      dd: user.WageInformation.PostgradLoanPaidDirectToStudentLoanCompany ? 'Yes' : 'No'
    }
  ]

  const paymentDetails = [
    {
      dt: 'Sort code',
      dd: user.WageInformation.SortCode
    },
    {
      dt: 'Account number',
      dd: user.WageInformation.AccountNumber
    },
    {
      dt: 'Account holder',
      dd: user.WageInformation.AccountHolder
    }
  ]

  return user.Loaded ? (
    <Layout>
      {!userTaskWageInfo.Completed && <Stepper steps={stepperSteps.wages} currentStep={4} />}

      <ContentWrap pad>
        <Base util={[padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
          <DetailsList
            title="Employee statement"
            editLink="/wages/employee-statement"
            listItems={employeeStatement}
          />

          <DetailsList title="Student loan" editLink="/wages/student-loan" listItems={student} />

          <DetailsList title="Payment details" editLink="/wages/payment-details" listItems={paymentDetails} />
        </Base>

        <Base util={[position.floatRightSm, text.center]}>
          <Button
            success
            util={[
              margin.t2,
              margin.b2,
              margin.b0Sm,
              display.block,
              display.inlineBlockSm,
              width.w12,
              width.resetSm
            ]}
            onClick={() => user.handleUpdateStep(userTaskWageInfo.Id)}
          >
            Confirm wages information
          </Button>
        </Base>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default WagesConfirmDetails
