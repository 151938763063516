const overviewAddressDetails = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'Line1',
    dd: data.Addresses && data.Addresses[0].Line1
  },
  {
    dt: 'Line2',
    dd: data.Addresses && data.Addresses[0].Line2
  },
  {
    dt: 'Town',
    dd: data.Addresses && data.Addresses[0].Town
  },
  {
    dt: 'Postcode',
    dd: data.Addresses && data.Addresses[0].Postcode
  }
]
}

export default overviewAddressDetails
