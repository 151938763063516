import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import { navigate } from '@reach/router'
import { Alert, Button, Form, Grid, color, Card, Text, margin, position, padding } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormDivider from '../../../../components/FormDivider'
import FormLookupInput from '../../../../components/FormLookupInput'
import FormSelectInput from '../../../../components/FormSelectInput'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import locationImg from '../../../../static/images/icons/icon--location.svg'
import Modal from '../../../../components/Modal'

import { createDisclosureContactSchema, createAddressSchema } from './schema'
import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'
import handleBlur from '../../../../utilities/handleBlur'
import {
  checkRemainingYearsRequiredAddressHistory,
  checkGapsExistInHistory
} from '../../../../utilities/validateAddressHistory'
import auth from '../../../../auth'
import accessRole from '../../../../services/accessRole'
import ValidationAlertList from '../../../../components/ValidationAlertList'

const DisclosureContactInformation = () => {
  const user = useContext(UserContext)
  const {
    Disclosure: { AddressHistory },
    Steps,
    Loaded
  } = user
  const userTaskDisclosure = Steps.find(step => step.Step.Id === 4)
  const [ValidationErrors, setValidationErrors] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedAddressRemove, setSelectedAddressRemove] = useState('')
  const [gapsExistInHistory, setGapsExistInHistory] = useState(false)

  const totalYearsRequired = 5
  const [currentYearsRequired, setCurrentYearsRequired] = useState(totalYearsRequired)

  const DisclosureContactSchema = createDisclosureContactSchema(AddressHistory)

  const getPreviousDate = date =>
    moment
      .utc(date)
      .subtract(1, 'd')
      .format('YYYY-MM-DD')

  const mostRecentAddress =
    AddressHistory && AddressHistory.length
      ? AddressHistory.sort((a, b) => a.DateTo >= b.DateTo)[0]
      : undefined

  // unfortunately moment.utc needs to take undefined rather than null if no date.
  const defaultToDate =
    AddressHistory && AddressHistory.length
      ? getPreviousDate(AddressHistory[AddressHistory.length - 1].DateFrom)
      : undefined

  const initialSelectedAddress = {
    Postcode: '',
    Line1: '',
    Line2: '',
    Town: '',
    County: '',
    CountryId: '',
    DateFrom: '',
    DateTo: moment.utc(defaultToDate).format('YYYY-MM-DD')
  }

  const [selectedAddress, setSelectedAddress] = useState(initialSelectedAddress)

  useEffect(() => {
    user.handleLoadDisclosure()
  }, [])

  useEffect(() => {
    const gapsExist = checkGapsExistInHistory(AddressHistory, moment().utc())
    setGapsExistInHistory(gapsExist)
    if (gapsExist && mostRecentAddress) {
      selectedAddress.DateFrom = moment
        .utc(mostRecentAddress.DateTo)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    }
  }, [AddressHistory.length])

  useEffect(() => {
    const remaining = checkRemainingYearsRequiredAddressHistory(
      AddressHistory,
      totalYearsRequired,
      moment().utc()
    )
    setCurrentYearsRequired(remaining)
  }, [AddressHistory.length])

  const handleSubmit = e => {
    e.preventDefault()
    setValidationErrors([])
    const { Disclosure } = user

    DisclosureContactSchema.validate(Disclosure, { abortEarly: false })
      .then(async () => {
        await user.handleSubmitDisclosure().then(res => {
          if (res === 200) {
            const isAdminEditing = auth.getSessionValue('IsAdmin') && accessRole.isProfileEditor()
            const newLocation = isAdminEditing
              ? `/admin/new-start/${user.Id}/overview#Disclosure`
              : '/disclosure/confirm-complete'

            navigate(newLocation)
          }
        })
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const handleAddAddress = e => {
    e.preventDefault()

    setValidationErrors([])

    const addressSchema = createAddressSchema(selectedAddress)

    addressSchema
      .validate(selectedAddress, { abortEarly: false })
      .then(() => {
        user.handleDisclosureAddToAddressHistory({
          DisclosureId: user.Disclosure.Id,
          Postcode: selectedAddress.Postcode,
          Line1: selectedAddress.Line1,
          Line2: selectedAddress.Line2,
          Town: selectedAddress.Town,
          County: selectedAddress.County,
          CountryId: selectedAddress.CountryId,
          DateFrom: selectedAddress.DateFrom,
          DateTo: selectedAddress.DateTo
        })

        const previousDate = getPreviousDate(selectedAddress.DateFrom)
        setSelectedAddress({ ...initialSelectedAddress, DateTo: previousDate })
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const handleAddressRemove = index => {
    user.handleDisclosureRemoveFromAddressHistory(index)

    const previousDate = AddressHistory.length
      ? getPreviousDate(AddressHistory[AddressHistory.length - 1].DateFrom)
      : moment.utc().format('YYYY-MM-DD')

    setSelectedAddress({
      ...initialSelectedAddress,
      DateTo: previousDate
    })
    setShowModal(false)
  }

  const handleAddressModal = index => {
    setShowModal(true)
    setSelectedAddressRemove(index)
  }

  const addressError = getErrorForField('AddressHistory', ValidationErrors)

  return Loaded ? (
    <Layout>
      {showModal && (
        <Modal util={[margin.b5]} onClick={() => setShowModal(!showModal)}>
          <Text util={[margin.t4, margin.t0Sm]}>
            By removing this address it will remove all past addresses too.
          </Text>
          <Button danger util={[margin.r2]} onClick={() => handleAddressRemove(selectedAddressRemove)}>
            Remove
          </Button>
          <Button util={[]} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal>
      )}
      <Stepper steps={stepperSteps.disclosure} currentStep={4} />

      {user.DisclosureApiValidationErrors && (
        <ValidationAlertList errors={user.DisclosureApiValidationErrors} />
      )}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel borderedSm boxed bgGreySm>
            <Grid row>
              <Grid colSm={6}>
                <FormTextInput
                  label="Daytime phone number"
                  name="DaytimePhoneNumber"
                  type="tel"
                  placeholder="+44 7890 *** ***"
                  hint="Country code is mandatory, e.g. +44"
                  value={user.Disclosure.DaytimePhoneNumber}
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('DaytimePhoneNumber', ValidationErrors)}
                  onBlur={e => handleBlur(e, DisclosureContactSchema, user.Disclosure, setValidationErrors)}
                />

                <FormTextInput
                  label="Evening phone number"
                  name="EveningPhoneNumber"
                  type="tel"
                  placeholder="+44 141 *** ****"
                  hint="Country code is mandatory, e.g. +44"
                  value={user.Disclosure.EveningPhoneNumber}
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('EveningPhoneNumber', ValidationErrors)}
                  onBlur={e => handleBlur(e, DisclosureContactSchema, user.Disclosure, setValidationErrors)}
                />

                <FormTextInput
                  label="Email address"
                  name="EmailAddress"
                  type="email"
                  value={user.Disclosure.EmailAddress}
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('EmailAddress', ValidationErrors)}
                  onBlur={e => handleBlur(e, DisclosureContactSchema, user.Disclosure, setValidationErrors)}
                />

                <FormDivider />
              </Grid>
            </Grid>

            <Grid row>
              <Grid colSm={6}>
                <Text h4>Address details</Text>

                <Text>
                  Please provide details of your residence for the past {totalYearsRequired} years.{' '}
                  {!AddressHistory.length && (
                    <strong>
                      Start with your present address and make sure there are no gaps in your address history.
                    </strong>
                  )}
                  {!!AddressHistory.length && !gapsExistInHistory && currentYearsRequired > 0 && (
                    <strong>
                      Disclosure needs another{' '}
                      {currentYearsRequired > 1 && !!AddressHistory.length && currentYearsRequired} year
                      {currentYearsRequired > 1 && 's'} of address history.
                    </strong>
                  )}
                  {currentYearsRequired <= 0 && (
                    <strong>Thank you, we&apos;ve got all the address history we need.</strong>
                  )}
                </Text>

                {addressError && (
                  <Alert id="errorList" danger util={[margin.t3, margin.b3]}>
                    <Text util={margin.b0}>{addressError}</Text>
                  </Alert>
                )}

                <FormLookupInput
                  label="Postcode"
                  name="Postcode"
                  value={selectedAddress.Postcode}
                  onChange={e =>
                    setSelectedAddress({
                      ...selectedAddress,
                      Postcode: e.target.value.toUpperCase()
                    })
                  }
                  onClick={e => {
                    user.handleAddressSearch(e, selectedAddress.Postcode)
                    setSelectedAddress({ ...initialSelectedAddress, DateTo: selectedAddress.DateTo })
                  }}
                  error={getErrorForField('Postcode', ValidationErrors)}
                />

                {user.AddressList.length > 0 && (
                  <FormSelectInput
                    name="AddressSelect"
                    label="Choose Address"
                    onChange={e => {
                      const address = user.AddressList[e.target.value]
                      setSelectedAddress({
                        Postcode: address.Postcode,
                        Line1: address.Line1,
                        Line2: address.Line2,
                        Town: address.Town,
                        County: '',
                        CountryId: 235,
                        DateFrom: '',
                        DateTo: selectedAddress.DateTo
                      })
                      user.handleDisclosureSelectAddress()
                    }}
                  >
                    <option value={null}>Please select...</option>
                    {user.AddressList.map((item, i) => (
                      <option key={item.Line1} value={i}>
                        {item.Line1}
                      </option>
                    ))}
                  </FormSelectInput>
                )}

                <FormTextInput
                  label="Address line 1"
                  name="Line1"
                  value={selectedAddress.Line1 || ''}
                  onChange={e =>
                    setSelectedAddress({
                      ...selectedAddress,
                      [e.target.name]: e.target.value
                    })
                  }
                  error={getErrorForField('Line1', ValidationErrors)}
                />

                <FormTextInput
                  label="Address line 2"
                  name="Line2"
                  value={selectedAddress.Line2 || ''}
                  onChange={e => {
                    setSelectedAddress({
                      ...selectedAddress,
                      [e.target.name]: e.target.value
                    })
                  }}
                  error={getErrorForField('Line2', ValidationErrors)}
                />

                <FormTextInput
                  label="Town"
                  name="Town"
                  value={selectedAddress.Town || ''}
                  onChange={e =>
                    setSelectedAddress({
                      ...selectedAddress,
                      [e.target.name]: e.target.value
                    })
                  }
                  error={getErrorForField('Town', ValidationErrors)}
                />

                <FormTextInput
                  label="County"
                  name="County"
                  value={selectedAddress.County || ''}
                  onChange={e =>
                    setSelectedAddress({
                      ...selectedAddress,
                      [e.target.name]: e.target.value
                    })
                  }
                  error={getErrorForField('County', ValidationErrors)}
                />

                <FormSelectInput
                  label="Country"
                  name="CountryId"
                  value={selectedAddress.CountryId || ''}
                  onChange={e =>
                    setSelectedAddress({
                      ...selectedAddress,
                      [e.target.name]: parseInt(e.target.value, 10)
                    })
                  }
                  error={getErrorForField('CountryId', ValidationErrors)}
                >
                  <option />
                  {user.CountryList.map(item => (
                    <option key={item.Id} value={item.Id}>
                      {item.CountryName}
                    </option>
                  ))}
                </FormSelectInput>

                <FormTextInput
                  label="Resident from"
                  name="DateFrom"
                  type="date"
                  value={selectedAddress.DateFrom ? selectedAddress.DateFrom : ''}
                  max={moment.utc().format('YYYY-MM-DD')}
                  onChange={e =>
                    setSelectedAddress({
                      ...selectedAddress,
                      [e.target.name]: e.target.value
                    })
                  }
                  error={getErrorForField('DateFrom', ValidationErrors)}
                  placeholder="yyyy-mm-dd"
                  pattern="\d{4}-\d{2}-\d{2}"
                />

                <FormTextInput
                  label="Resident to"
                  name="DateTo"
                  type="date"
                  value={selectedAddress.DateTo}
                  max={moment.utc().format('YYYY-MM-DD')}
                  onChange={e =>
                    setSelectedAddress({
                      ...selectedAddress,
                      [e.target.name]: e.target.value
                    })
                  }
                  readOnly
                  error={getErrorForField('DateTo', ValidationErrors)}
                  placeholder="yyyy-mm-dd"
                  pattern="\d{4}-\d{2}-\d{2}"
                />

                <Button primary type="button" util={position.floatRight} onClick={handleAddAddress}>
                  Add this address
                </Button>
              </Grid>

              <Grid colSm={6} colXs={12} util={[margin.t10Sm, padding.t6]}>
                {user.Disclosure.AddressHistory !== null &&
                  user.Disclosure.AddressHistory.map((address, i) => (
                    <Card key={i} util={margin.t3}>
                      <Card content>
                        <img src={locationImg} style={{ float: 'right' }} alt="" />
                        <h5>
                          {moment.utc(address.DateFrom).format('DD/MM/YYYY')} -{' '}
                          {address.DateTo === moment.utc().format('YYYY-MM-DD')
                            ? 'Present'
                            : moment.utc(address.DateTo).format('DD/MM/YYYY')}
                        </h5>
                        <ul>
                          <li>{address.Line1}</li>
                          <li>{address.Line2}</li>
                          <li>{address.Town}</li>
                          <li>{address.County}</li>
                          <li>
                            {(user.CountryList &&
                              user.CountryList.find(country => country.Id === address.CountryId)
                                .CountryName) ||
                              ''}
                          </li>
                          <li>{address.Postcode}</li>
                        </ul>
                      </Card>
                      <Card content util={position.clearfix}>
                        <Button
                          link
                          util={[color.acRed, position.floatRight, padding.a0]}
                          onClick={() => handleAddressModal(i)}
                          type="button"
                        >
                          Remove
                        </Button>
                      </Card>
                    </Card>
                  ))}
              </Grid>
            </Grid>
          </Panel>

          <Button success util={[position.floatRight, margin.t3]}>
            {userTaskDisclosure && userTaskDisclosure.Completed ? 'Update' : 'Next: Confirm details'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DisclosureContactInformation
