import * as yup from 'yup'
import '../../../utilities/toUTC'

export const employeeDetailsSchema = yup.object().shape({
  EmployeeId: yup
    .number()
    .positive()
    .min(1)
    .required('This field is required')
    .typeError('EmployeeId must be a number'),
  EmployeeJobTitle: yup
    .string()
    .required('This field is required')
    .max(50)
    .nullable(),
  DateEffectiveFrom: yup
    .date()
    .required('This field is required'),
  EmployeeWorkHours: yup
    .string()
    .required('This field is required')
    .max(250),
  EmployeeHiringManager: yup
    .string()
    .required('This field is required')
    .max(100)
    .nullable(),
  EmployeeSalary: yup
    .string()
    .required('This field is required')
    .max(50)
    .nullable(),
  EmployeeLocation: yup
    .string()
    .required('This field is required')
    .max(100)
    .nullable(),
  SenderToggle: yup.boolean(),
  SenderFirstname: yup.string().when('SenderToggle', {
    is: false,
    then: yup.string().max(25).required('This field is required')
  }),
  SenderSurname: yup.string().when('SenderToggle', {
    is: false,
    then: yup.string().max(25).required('This field is required')
  }),
  SenderJobTitle: yup.string().when('SenderToggle', {
    is: false,
    then: yup.string().max(50)
  })
})
