import React from 'react'
import PropTypes from 'prop-types'
import { Form, display, margin } from 'chassis-react'

const FormRadioGroup = props => {
  const { error, hint, id, label, large, name, onChange, options, ...rest } = props

  return (
    <Form group error={!!error}>
      {label && (
        <Form label util={[display.block]}>
          {label}
        </Form>
      )}

      {hint && <Form hint>{hint}</Form>}

      {!!error && <Form validation>{error}</Form>}

      {options.map((option, i) => (
        <Form
          large={large || null}
          radio
          key={i}
          id={`${id || name}-${i}`}
          name={name}
          value={option.value}
          onChange={onChange}
          util={[margin.r1, margin.b2]}
          checked={option.checked}
          error={null}
          {...rest}
        >
          {option.label}
        </Form>
      ))}
    </Form>
  )
}

FormRadioGroup.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hint: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  large: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array)
}

export default FormRadioGroup
