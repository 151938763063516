import React, { useState } from 'react'
import { Alert, Button, Card, Grid, Form, Text, margin, position } from 'chassis-react'
import FormTextInput from '../FormTextInput'
import Layout from '../Layout'

import { forgotPasswordSchema } from './schema'
import getErrorForField from '../../utilities/getErrorForField'
import auth from '../../auth'

const ForgotPassword = () => {
  const [Email, setEmail] = useState('')
  const [sent, setSent] = useState(false)
  const [ValidationErrors, setValidationErrors] = useState([])

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault()
    forgotPasswordSchema.validate({ Email }, { abortEarly: false })
      .then(() => {
        setValidationErrors([])
        auth.forgotPassword(Email)
        setSent(true)
      })
      .catch(err => setValidationErrors(err.inner))
  }

  return (
    <Layout>
      {sent && (
        <Alert wide success>
          <Grid container>
            <Grid row>
              <Grid colMd={12} util={position.centered}>
                <Text util={margin.b0}>
                  Thank you. Please check your email for further instructions.
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Alert>
      )}
      <Grid container>
        <Grid row>
          <Grid colSm={6} colMd={4} util={[position.centered, margin.t10]}>
            <Card>
              <Form onSubmit={handleForgotPasswordSubmit}>
                <Card content>
                  <Text h3 util={margin.b0}>
                    Password Reset
                  </Text>
                </Card>

                <Card content grey>
                  <Form group>
                    <Form label>Email</Form>
                    <FormTextInput
                      input 
                      type="email" 
                      name="Email"
                      onChange={e => setEmail(e.target.value)}
                      error={getErrorForField('Email', ValidationErrors)}
                    />
                  </Form>

                  <Button
                    success
                    block
                    disabled={sent}
                  >
                    Submit
                  </Button>
                </Card>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ForgotPassword