import React from 'react'
import PropTypes from 'prop-types'
import { Base, border, color, margin, padding, shape } from 'chassis-react'

const FormWrapper = ({ children }) => (
  <Base
    util={[
      border.a1,
      color.bcGrey3,
      color.bgGrey2,
      margin.t5,
      margin.b3,
      padding.h2,
      padding.v3,
      padding.h5Sm,
      padding.v6Sm,
      shape.rounded
    ]}
  >
    {children}
  </Base>
)

FormWrapper.propTypes = {
  children: PropTypes.node
}

export default FormWrapper
