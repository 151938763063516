import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const TermsStart = props => {
  const { children } = props

  return <Fragment>{children}</Fragment>
}

TermsStart.propTypes = {
  children: PropTypes.node
}

export default TermsStart
