import React, { useContext } from 'react'
import { Button, Text, Form, Grid, margin, padding, position, width, shape } from 'chassis-react'
import { navigate } from '@reach/router'

import { UserContext } from '../../../../context/UserContext'
import stepperSteps from '../../../../config/stepperSteps'
import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormSelectInput from '../../../../components/FormSelectInput'

import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

const duration = ['Infrequently', 'Weekly', 'Monthly']

const Entry = () => {
  const user = useContext(UserContext)

  const { Loaded, HandArmVibration, handleChangeTools } = user
  const {
    EverUsedHandheldVibratingToolsInYourJob,
    UseOfPowerToolsVibratingEquipmentOutsideWork,
    HowOftenDoYouUseThisEquipment
  } = HandArmVibration

  const handleSubmit = e => {
    e.preventDefault()
    if (EverUsedHandheldVibratingToolsInYourJob || UseOfPowerToolsVibratingEquipmentOutsideWork) {
      return navigate('/health-and-safety/health-question-1')
    }
    return navigate('/health-and-safety/confirm')
  }
  return Loaded ? (
    <Layout>
      {<Stepper steps={stepperSteps.healthAndSafety} currentStep={1} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: EverUsedHandheldVibratingToolsInYourJob === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: EverUsedHandheldVibratingToolsInYourJob === false
                }
              ]}
              required
              name="EverUsedHandheldVibratingToolsInYourJob"
              label="Have you ever used hand-held vibrating tools, machines or hand-fed processes in 
              your job?"
              data-format="bool"
              onChange={handleChangeTools}
            />

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: UseOfPowerToolsVibratingEquipmentOutsideWork === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: UseOfPowerToolsVibratingEquipmentOutsideWork === false
                }
              ]}
              required
              name="UseOfPowerToolsVibratingEquipmentOutsideWork"
              label="Do you use any power tool or other vibrating equipment in your personal time/out
              with work?"
              data-format="bool"
              onChange={handleChangeTools}
            />

            {UseOfPowerToolsVibratingEquipmentOutsideWork === true && (
              <>
                <Text h4 as="h4">
                  How often do you use this equipment?
                </Text>
                <Grid row>
                  <Grid colSm={6}>
                    <FormSelectInput
                      onChange={handleChangeTools}
                      value={HowOftenDoYouUseThisEquipment}
                      name="HowOftenDoYouUseThisEquipment"
                      required
                    >
                      <option value="">Please select...</option>
                      {duration.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </FormSelectInput>
                  </Grid>
                </Grid>
              </>
            )}
          </Panel>

          <Button
            type="buttom"
            success
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          >
            {EverUsedHandheldVibratingToolsInYourJob || UseOfPowerToolsVibratingEquipmentOutsideWork
              ? 'Next: Health questions 1'
              : 'Next: Confirm HAVs details'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Entry
