import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'
import { Button, List, Text, margin, padding, position, width } from 'chassis-react'

import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import { UserContext } from '../../../../context/UserContext'
import { apprenticeSchema } from './schema'

import ClothingForm from './Forms/ClothingForm'
import TrainingForm from './Forms/TrainingForm'
import DietaryRequirementsForm from './Forms/DietaryRequirementsForm'
import ContentLoader from '../../../../components/ContentLoader'

const Apprentice = () => {
  const user = useContext(UserContext)
  const { Apprentice: ApprenticeData, Loaded } = user

  const [errors, setErrors] = useState([])

  const handleSubmit = () => {
    setErrors([])

    apprenticeSchema
      .validate(ApprenticeData, { abortEarly: false })
      .then(() => {
        user.handleSubmitApprentice().then(() => {
          navigate('/')
        })
      })
      .catch(err => {
        if (err.inner) {
          setErrors(err.inner)
        }
      })
  }

  const props = { user, errors, setErrors, schema: apprenticeSchema }

  return Loaded ? (
    <Layout>
      <Hero icon="person">Your Apprenticeship</Hero>

      <ContentWrap pad>
        <Panel borderedSm bgWhiteSm util={[margin.b3, padding.a3Sm]}>
          <Text h3>What do I need to do?</Text>

          <List tick>
            <List item>Please complete all sections fully.</List>
          </List>
        </Panel>

        <ClothingForm {...props} />

        <TrainingForm {...props} />

        <DietaryRequirementsForm {...props} />

        <Button success util={[position.floatRightSm, width.w12, width.resetSm]} onClick={handleSubmit}>
          Confirm apprentice details
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Apprentice
