import React, { useContext } from 'react'
import { Base, Button, Text, border, color, margin, padding, position, width } from 'chassis-react'

// import { navigate } from '@reach/router'

import { UserContext } from '../../../../context/UserContext'
import stepperSteps from '../../../../config/stepperSteps'
import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import ValidationAlertList from '../../../../components/ValidationAlertList'

import Layout from '../../../../components/Layout'
import Stepper from '../../../../components/Stepper'
import DetailsList from '../../../../components/DetailsList'

const Confirm = () => {
  const user = useContext(UserContext)
  const {
    Loaded,
    HandArmVibration,
    handleSubmitNewStartHandArmVibration,
    submitNeverUsedHandArmVibration,
    HandArmVibrationErrors
  } = user

  const {
    NewStartHandArmVibrationExposures,
    EverUsedHandheldVibratingToolsInYourJob,
    UseOfPowerToolsVibratingEquipmentOutsideWork,
    HowOftenDoYouUseThisEquipment,
    DiagnosedWithRaynaudsSyndrome,
    DiagnosedWithRaynaudsSyndromeDetails,
    AnyTinglingAfterUsingVibratingEquipment,
    TinglingAnyOtherTime,
    OneOrMoreFingersNumbAfterUsingVibratingEquipment,
    FingersWhiteWhenExposedToCold,
    DifficultyGettingFingersWarm,
    FingersWhiteAtAnyOtherTime,
    AnyOtherProblemsWithMusclesOrJointsOfHandsArms,
    DifficultyPickingUpSmallItemsOrOpeningJars,
    EverHadNeckArmOrHandInjuryOrOperation,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
    OnAnyLongTermMedication,
    EverHadNeckArmOrHandInjuryOrOperationDetails,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
    OnAnyLongTermMedicationDetails
  } = HandArmVibration

  const occupationalHistoryDetail = ({ Address, Company, DateFrom, DateTo, JobTitle }) => [
    {
      dt: 'Date from:',
      dd: DateFrom || ''
    },
    {
      dt: 'Date to:',
      dd: DateTo || ''
    },
    {
      dt: 'Job title:',
      dd: JobTitle || ''
    },
    {
      dt: 'Company:',
      dd: Company || ''
    },
    {
      dt: 'Address:',
      dd: Address || ''
    }
  ]
  const occupationalHistory = () =>
    NewStartHandArmVibrationExposures.map(item => {
      const { Address, Company, DateFrom, DateTo, JobTitle } = item
      return occupationalHistoryDetail({ Address, Company, DateFrom, DateTo, JobTitle })
    })

  const healthSurveillanceDetail = [
    {
      dt: 'Have you ever used hand-held vibrating tools, machines or hand-fed processes in your job?',
      dd: EverUsedHandheldVibratingToolsInYourJob ? 'Yes' : 'No'
    },
    {
      dt: 'Do you use and power tools or other vibrating equipment should move',
      dd: UseOfPowerToolsVibratingEquipmentOutsideWork ? 'Yes' : 'No'
    },
    UseOfPowerToolsVibratingEquipmentOutsideWork && HowOftenDoYouUseThisEquipment && {
      dt: 'How often do you use this equipment?',
      dd: HowOftenDoYouUseThisEquipment
    }
  ]

  const healthQuestions1 = [
    DiagnosedWithRaynaudsSyndrome !== null && {
      dt: 'Have you been diagnosed with Raynaud’s syndrome (primary or secondary)?',
      dd: DiagnosedWithRaynaudsSyndrome ? 'Yes' : 'No'
    },
    DiagnosedWithRaynaudsSyndromeDetails !== null && {
      dt: 'Please give details of this?',
      dd: DiagnosedWithRaynaudsSyndromeDetails
    },
    AnyTinglingAfterUsingVibratingEquipment !== null && {
      dt:
        'Do you have any tingling of the fingers lasting more than 20 minutes after using vibrating equipment?',
      dd: AnyTinglingAfterUsingVibratingEquipment ? 'Yes' : 'No'
    },
    TinglingAnyOtherTime !== null && {
      dt: 'Do you have tingling of the fingers at any other time?',
      dd: TinglingAnyOtherTime ? 'Yes' : 'No'
    },
    OneOrMoreFingersNumbAfterUsingVibratingEquipment !== null && {
      dt: 'Do one or more of your fingers go numb more than 20 minutes after using vibrating equipment?',
      dd: OneOrMoreFingersNumbAfterUsingVibratingEquipment ? 'Yes' : 'No'
    },
    FingersWhiteWhenExposedToCold !== null && {
      dt: 'Have any of your fingers gone white* when exposed to cold?',
      dd: FingersWhiteWhenExposedToCold ? 'Yes' : 'No'
    },
    DifficultyGettingFingersWarm !== null && {
      dt: 'If yes, do you have difficulty getting them warm again?',
      dd: DifficultyGettingFingersWarm ? 'Yes' : 'No'
    },
    FingersWhiteAtAnyOtherTime !== null && {
      dt: 'Do your fingers go white at any other time?',
      dd: FingersWhiteAtAnyOtherTime ? 'Yes' : 'No'
    }
  ]
  const healthQuestions2 = [
    AnyOtherProblemsWithMusclesOrJointsOfHandsArms !== null && {
      dt: 'Are you experiencing any other problems with the muscles or joints of the hands or arms?',
      dd: AnyOtherProblemsWithMusclesOrJointsOfHandsArms ? 'Yes' : 'No'
    },
    DifficultyPickingUpSmallItemsOrOpeningJars !== null && {
      dt:
        'Do you have difficulty picking up very small objects (such as screws or buttons) or opening tight jars?',
      dd: DifficultyPickingUpSmallItemsOrOpeningJars ? 'Yes' : 'No'
    },
    EverHadNeckArmOrHandInjuryOrOperation !== null && {
      dt: 'Have you ever had a neck, arm or hand injury or operation?',
      dd: EverHadNeckArmOrHandInjuryOrOperation ? 'Yes' : 'No'
    },
    EverHadNeckArmOrHandInjuryOrOperationDetails !== null && {
      dt: 'Please give more details of this',
      dd: EverHadNeckArmOrHandInjuryOrOperationDetails
    },
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels !== null && {
      dt: 'Have you ever had any serious diseases of your joints, skin, nerves, heart or blood vessels?',
      dd: AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels ? 'Yes' : 'No'
    },
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails !== null && {
      dt: 'Please give more details of this',
      dd: AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails
    },
    OnAnyLongTermMedication !== null && {
      dt: 'Are you on any long-term medication?',
      dd: OnAnyLongTermMedication ? 'Yes' : 'No'
    },
    OnAnyLongTermMedicationDetails !== null && {
      dt: 'Please give more details of this',
      dd: OnAnyLongTermMedicationDetails
    }
  ]

  const haveNotUsedToolsAndEquipments = !EverUsedHandheldVibratingToolsInYourJob && !UseOfPowerToolsVibratingEquipmentOutsideWork

  const handleSubmit = () => {
    if (haveNotUsedToolsAndEquipments) {
      return submitNeverUsedHandArmVibration()
    }
    handleSubmitNewStartHandArmVibration()
  }
  return Loaded ? (
    <Layout>
      {<Stepper steps={stepperSteps.healthAndSafety} currentStep={6} />}
      <Base util={[padding.v4, padding.h3]}>
        {HandArmVibrationErrors && <ValidationAlertList errors={HandArmVibrationErrors} />}
      </Base>
      <ContentWrap pad>
        <Text h4 as="h4">
          Check your details before submitting
        </Text>
        <Base util={[padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
          <DetailsList
            title="Health surveillance"
            editLink="/health-and-safety/entry"
            listItems={healthSurveillanceDetail}
          />
          {!haveNotUsedToolsAndEquipments && (
            <DetailsList
              title="Health Questions 1"
              editLink="/health-and-safety/health-question-1"
              listItems={healthQuestions1}
            />
          )}
          {!haveNotUsedToolsAndEquipments && (
            <DetailsList
              title="Health Questions 2"
              editLink="/health-and-safety/health-question-2"
              listItems={healthQuestions2}
            />
          )}
          {!haveNotUsedToolsAndEquipments && (
            <Base util={[border.a1, color.bcGrey3, padding.v4, padding.h3]}>
              {occupationalHistory().map((item, i) => (
                <DetailsList
                  noborder
                  key={i}
                  title={`Occupational history ${i + 1}`}
                  editLink="/health-and-safety/occupational-history"
                  listItems={item}
                />
              ))}
            </Base>
          )}
        </Base>
        <Button success block util={[width.resetSm, margin.t3, position.floatRight]} onClick={handleSubmit}>
          Confirm HAVs form
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Confirm
