import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Base } from 'chassis-react'

import './style.scss'
import splitCamelCase from '../../utilities/splitCamelCase'

const TabList = ({ list, util }) => {
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    // Activates the current tab based on window.location.hash
    const {
      location: { hash }
    } = window
    const searchHash = decodeURI(hash).substring(1, hash.length)
    const index = list.findIndex(tabItem => tabItem.title === searchHash)

    if (index > -1) {
      setCurrentTab(index)
    }
  }, [window.location.hash])

  return (
    <div className="tablist">
      <div className="tablist__nav">
        {list.map(
          (item, i) =>
            item.active && (
              <a
                className={`tablist__nav-item ${currentTab === i && 'tablist__nav-item--active'}`}
                key={i}
                onClick={() => setCurrentTab(i)}
                href={`#${splitCamelCase(item.title).trim()}`}
              >
                {item.title}
              </a>
            )
        )}
      </div>

      <div className="tablist__content">
        {list.map(
          (item, i) =>
            item.active && (
              <div key={`tablistContent-${i}`}>
                <Base
                  className={`tablist__content-item ${i === currentTab && 'tablist__content-item-selected'}`}
                  util={util}
                >
                  {item.body}
                </Base>
              </div>
            )
        )}
      </div>
    </div>
  )
}

TabList.propTypes = {
  list: PropTypes.instanceOf(Array),
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string])
}

export default TabList
