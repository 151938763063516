const formatTitles = titles => {
  const titlesToFind = ['MR', 'MRS', 'MISS', 'MS', 'MX']

  const sortedTitles = []

  titlesToFind.forEach(titleToFind => {
    // Looping backwards since we're mutating `titles`
    for (let i = titles.length - 1; i >= 0; i -= 1) {
      const title = titles[i]

      if (titleToFind.toLowerCase() === title.Name.toLowerCase()) {
        sortedTitles.push(title)
        titles.splice(i, 1)
      }
    }
  })

  return [...sortedTitles, ...titles]
}

export default formatTitles
