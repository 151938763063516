import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Base, Button, Text, padding, shape, margin, border, color, position } from 'chassis-react'

import api from '../../../../api'

import Modal from '../../../../components/Modal'

const DocumentAccept = ({ title, id, children, dateSigned, onClick }) => {
  const [showModal, setShowModal] = useState(false)
  const [fileData, setFileData] = useState({})

  useEffect(() => {
    api.newStartDocument.getByDocId(id).then(res => setFileData(res.data))
  }, [])

  const handleViewDocument = e => {
    e.preventDefault()
    setShowModal(true)
  }

  return (
    <Base
      util={[
        padding.h3,
        padding.t3,
        padding.b1,
        shape.rounded,
        margin.b3,
        border.a1,
        color.bcGrey3,
        color.bgWhite
      ]}
    >
      {showModal && (
        <Modal onClick={() => setShowModal(false)}>
          {fileData.DocumentFileType && 
            // check if it's an image or a pdf
            fileData.DocumentFileType.split('/')[1] === 'pdf' && (
              <object
                data={`data:${fileData.DocumentFileType};base64,${fileData.Document}`}
                type={fileData.DocumentFileType}
                width="100%"
                height="100%"
              >
                This browser does not support inline PDFs. Please download the PDF to view it:{' '}
                <a href={fileData.Document}>Download PDF</a>
              </object>
            )}
            {fileData.DocumentFileType && 
              fileData.DocumentFileType.split('/')[0] === 'image' && (
              <img
                src={`data:${fileData.DocumentFileType};base64,${fileData.Document}`}
                alt={fileData.Title}
              />
            )}
        </Modal>
      )}

      <Text h3>{title}</Text>

      <Base>{children}</Base>
      <Base util={[position.floatRight]}>
        {dateSigned && (
          <Button util={[padding.l4, padding.r4]} success disabled>
            Accepted
          </Button>
        )}
        
        {!dateSigned && (
          <Button util={[padding.l4, padding.r4]} secondary onClick={onClick}>
            Accept
          </Button>
        )}
      </Base>

      <Base util={[margin.t3]}>
        <Button as="a" link onClick={handleViewDocument} util={[padding.l0]}>
          View document
        </Button>
        <Button as="a" link href={`data:${fileData.DocumentFileType};base64,${fileData.Document}`} download={fileData.Filename}>
          Download document
        </Button>
      </Base>
    </Base>
  )
}

DocumentAccept.propTypes = {
  children: PropTypes.node,
  dateSigned: PropTypes.string,
  id: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
}

export default DocumentAccept
