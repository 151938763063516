const overviewWageInformation = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  {
    dt: 'Employee statement',
    dd: data.EmployeeStatementType
  },
  {
    dt: 'Was a student before the 6th of April',
    dd: data.StudentBeforeSixthApril ? 'Yes' : 'No'
  },
  {
    dt: 'Was an undergraduate',
    dd: data.WasUndergraduate ? 'Yes' : 'No'
  },
  {
    dt: 'Was a postgraduate',
    dd: data.WasPostgraduate ? 'Yes' : 'No'
  },
  {
    dt: 'Has unpaid student loans',
    dd: data.HasUnpaidStudentLoan ? 'Yes' : 'No'
  },
  {
    dt: 'Student loan paid directly to the Student Loan Company',
    dd: data.StudentLoanPaidDirectToStudentLoanCompany ? 'Yes' : 'No'
  },
  {
    dt: 'Student loan type',
    dd: data.StudentLoanType
  },
  {
    dt: 'Has unpaid postgrad loan',
    dd: data.HasUnpaidPostgradLoan ? 'Yes' : 'No'
  },
  {
    dt: 'Postgrad student before the 6th of April',
    dd: data.PostgradStudentBeforeSixthApril ? 'Yes' : 'No'
  },
  {
    dt: 'Postgrad loan paid directly to the Student Loan Company',
    dd: data.PostgradLoanPaidDirectToStudentLoanCompany ? 'Yes' : 'No'
  },
  {
    dt: 'Account holder',
    dd: `${data.AccountHolder} (Employee name: ${data.Firstname} ${data.Surname})`
  },
  {
    dt: 'AccountNumber',
    dd: data.AccountNumber
  },
  {
    dt: 'SortCode',
    dd: data.SortCode
  }
]
}
export default overviewWageInformation
