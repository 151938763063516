// eslint-disable-next-line react/no-deprecated
import React from 'react'
import PropTypes from 'prop-types'
import { Text, margin, scale } from 'chassis-react'
import { components } from 'react-select'

const InputContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const value = getValue();
  
    const content = value.length > 0 
      ?  <Text util={[margin.t2, margin.l1, scale.fs3]}>{value[0].label}</Text>
      : children;
  
    return (
      <components.ValueContainer {...props}>
        {content}
      </components.ValueContainer>
    );
  };

  InputContainer.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired,
    getValue: PropTypes.func.isRequired
  }

export default InputContainer
