import React from 'react'
import PropTypes from 'prop-types'
import { Form, Grid, Button, padding } from 'chassis-react'

const FormLookupInput = props => {
  const { error, hint, id, label, name, onClick, util, ...rest } = props

  return (
    <Form group error={!!error && true} util={util}>
      {label && (
        <Form label htmlFor={id}>
          {label}
        </Form>
      )}

      {hint && <Form hint>{hint}</Form>}

      {!!error && <Form validation>{error}</Form>}

      <Grid row>
        <Grid colXs={7} util={padding.r0}>
          <Form input id={name || id} name={name} {...rest} />
        </Grid>
        <Grid colXs={5}>
          <Button block onClick={onClick}>
            Look up
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

FormLookupInput.propTypes = {
  error: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string])
}

export default FormLookupInput
