import React, { useState, useContext } from 'react'
import {
  Button,
  Card,
  display,
  text,
  color,
  Grid,
  margin,
  padding,
  position,
  width,
  shape
} from 'chassis-react'
import { navigate } from '@reach/router'

import { UserContext } from '../../../../context/UserContext'
import stepperSteps from '../../../../config/stepperSteps'
import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormTextInput from '../../../../components/FormTextInput'

import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

const OccupationalHistory = () => {
  const user = useContext(UserContext)

  const {
    Loaded,
    handleAddNewStartHandArmVibrationExposures,
    handleDeleteOccupationalHistory,
    HandArmVibration
  } = user
  const { NewStartHandArmVibrationExposures } = HandArmVibration

  const [DateFrom, setDateFrom] = useState('')
  const [DateTo, setDateTo] = useState('')
  const [JobTitle, setJobTitle] = useState('')
  const [Company, setCompany] = useState('')
  const [Address, setAddress] = useState('')

  const handleChangeDateFrom = e => {
    const { value } = e.target
    setDateFrom(value)
  }

  const handleChangeDateTo = e => {
    const { value } = e.target
    setDateTo(value)
  }

  const handleChangeJobTitle = e => {
    const { value } = e.target
    setJobTitle(value)
  }

  const handleChangeCompany = e => {
    const { value } = e.target
    setCompany(value)
  }
  const clearMe = () => {
    setDateFrom('')
    setDateTo('')
    setJobTitle('')
    setCompany('')
    setAddress('')
  }
  const handleAddDetails = () => {
    handleAddNewStartHandArmVibrationExposures({
      DateFrom,
      DateTo,
      JobTitle,
      Company,
      Address,
    })
    clearMe()
  }

  const handleChangAddress = e => {
    const { value } = e.target
    setAddress(value)
  }

  const handleDelete = i => {
    handleDeleteOccupationalHistory(i)
  }

  return Loaded ? (
    <Layout>
      {<Stepper steps={stepperSteps.healthAndSafetyQuestionnaire} currentStep={4} />}
      <ContentWrap pad>
        <div>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <Grid row>
              <Grid colSm={4}>
                <FormTextInput
                  label="Date from"
                  onChange={handleChangeDateFrom}
                  name="DateFrom"
                  type="date"
                  value={DateFrom}
                />
              </Grid>
              <Grid colSm={4}>
                <FormTextInput
                  value={DateTo}
                  type="date"
                  label="Date to"
                  name="DateTo"
                  onChange={handleChangeDateTo}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid colSm={8}>
                <FormTextInput
                  value={JobTitle}
                  label="Job title"
                  name="JobTitle"
                  onChange={handleChangeJobTitle}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid colSm={12}>
                <FormTextInput
                  value={Company}
                  label="Company"
                  name="Company"
                  onChange={handleChangeCompany}
                />
              </Grid>
            </Grid>
            <Grid row>
              <Grid colSm={12}>
                <FormTextInput value={Address} label="Address" name="Address" onChange={handleChangAddress} />
              </Grid>
            </Grid>
            <Grid row>
              <Grid colSm={12}>
                <Button
                  primary
                  type="button"
                  onClick={handleAddDetails}
                  disabled={
                    DateFrom === '' || DateTo === '' || JobTitle === '' || Company === '' || Address === ''
                  }
                >
                  Add details
                </Button>
              </Grid>
              <Grid row util={margin.a3Sm}>
                <Grid colSm={8}>
                  {NewStartHandArmVibrationExposures &&
                    NewStartHandArmVibrationExposures.length > 0 &&
                    NewStartHandArmVibrationExposures.map((item, i) => (
                      <ul>
                        <li key={i}>
                          <Card util={margin.t4}>
                            <Card content>
                              <strong>Date from</strong>
                              <br />
                              {item.DateFrom}
                            </Card>

                            <Card content>
                              <strong>Date to</strong>
                              <br />
                              {item.DateTo}
                            </Card>

                            <Card content>
                              <strong>Job title</strong>
                              <br />
                              {item.JobTitle}
                            </Card>

                            <Card content>
                              <strong>Company</strong>
                              <br />
                              {item.Company}
                            </Card>

                            <Card content>
                              <strong>Address</strong>
                              <br />
                              {item.Address}
                            </Card>

                            <Card content util={text.right}>
                              <Button
                                type="button"
                                link
                                danger
                                small
                                util={[display.inlineBlock, color.acRed]}
                                onClick={() => handleDelete(i)}
                              >
                                Remove
                              </Button>
                            </Card>
                          </Card>
                        </li>
                      </ul>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Panel>

          <Button
            type="buttom"
            success
            disabled={NewStartHandArmVibrationExposures && NewStartHandArmVibrationExposures.length === 0}
            onClick={() => navigate('/health-and-safety/confirm')}
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          >
            Next: Confirm HAVs form
          </Button>
        </div>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default OccupationalHistory
