function getDisclosureText(disclosureType) {
  switch (disclosureType) {
    case 1:
      return 'Disclosure & Barring Service'
    case 2:
      return 'Disclosure Scotland'
    case 0:
    default:
      return 'None Set'
  }
}

const overviewDisclosureAdminDetails = data => {
  if (!Object.keys(data).length) {
    return null
  }
  return [
    {
      dt: 'Disclosure Service',
      dd: getDisclosureText(data.DisclosureServiceType)
    },
    {
      dt: 'Disclosure Fee',
      dd: `£${data.DisclosureFee}`
    }
  ]
}

export default overviewDisclosureAdminDetails
