import React, { Fragment, useContext, useState } from 'react'
import moment from 'moment'
import {
  Base,
  Button,
  margin,
  position,
  padding,
  color,
  border,
  display,
  width,
} from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import DetailsList from '../../../../components/DetailsList'
import Layout from '../../../../components/Layout'
import Stepper from '../../../../components/Stepper'
import ValidationAlertList from '../../../../components/ValidationAlertList'
import { disclosureConsentSchema } from '../../../Admin/NewStart/schema'
import focusOnError from '../../../../utilities/focusOnError'
import FormCheckboxInput from '../../../../components/FormCheckboxInput'
import getErrorForField from '../../../../utilities/getErrorForField'

const listDetail = (title, data) => {
  const list = {
    dt: title,
    dd: data
  }
  if (data) {
    return list
  }
  return false
}

const userAddressesDetail = props =>
  props && (
    <Fragment>
      <Fragment>{props}</Fragment>
      <br />
    </Fragment>
  )

const DisclosureConfirmComplete = () => {
  const user = useContext(UserContext)
  const [ValidationErrors, setValidationErrors] = useState()
  const userTaskDisclosure = user.Steps.find(step => step.Step.Id === 4)
  const { DisclosureServiceType } = user

  const handleSubmit = e => {
    e.preventDefault()
    setValidationErrors([])
    const { Disclosure } = user
    const { DBSPrivacyPolicyConsent, DBSResultThirdPartyConsent, DBSArnoldClarkStoreConsent } = Disclosure
    disclosureConsentSchema
      .validate(
        { DBSPrivacyPolicyConsent, DBSResultThirdPartyConsent, DBSArnoldClarkStoreConsent },
        { abortEarly: false }
      )
      .then(async () => {
        await user.handleSubmitDisclosure().then(res => {
          if (res === 200){
            user.handleDisclosureCompleteStep(userTaskDisclosure.Id)
          }
        })
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const userAddresses = user.Disclosure.AddressHistory.map((address, i) => ({
    dt: `Address ${i + 1}`,
    dd: (
      <Fragment>
        {userAddressesDetail(address.Line1)}
        {userAddressesDetail(address.Line2)}
        {userAddressesDetail(address.Town)}
        {userAddressesDetail(address.CountryName)}
        {userAddressesDetail(address.Postcode)}
        <Base as="small" util={[display.block, margin.t1]}>
          {moment.utc(address.DateFrom).format('DD/MM/YYYY')} -{' '}
          {moment.utc(address.DateTo).format('DD/MM/YYYY')}
        </Base>
      </Fragment>
    )
  }))

  const otherNameDetail = (title, data) => {
    const list = (
      <Fragment>
        <small>{title}</small>
        <br />
        <div>{data}</div>
      </Fragment>
    )
    if (data) {
      return list
    }
    return false
  }

  const otherNames = user.Disclosure.OtherNames.map((otherName, i) => ({
    dt: `Other name ${i + 1}`,
    dd: (
      <Fragment>
        {otherNameDetail('Title', otherName.TitleName)}
        {otherNameDetail('Forename', otherName.Firstname)}
        {otherNameDetail('Middlename', otherName.Middlename)}
        {otherNameDetail('Surname', otherName.Surname)}
        {otherNameDetail('Used from', moment.utc(otherName.UsedFrom).format('DD/MM/YYYY'))}
        {otherNameDetail('Used to', moment.utc(otherName.UsedTo).format('DD/MM/YYYY'))}
      </Fragment>
    )
  }))

  const personalDetails = [
    listDetail('Gender', user.Disclosure.GenderName),
    listDetail('Title', user.Disclosure.TitleName),
    listDetail('Forename', user.Disclosure.Forename),
    listDetail('Middle name(s)', user.Disclosure.MiddleNames),
    listDetail('Surname', user.Disclosure.Surname),
    listDetail('Mothers maiden name', user.Disclosure.MothersMaidenName),
    ...otherNames
  ]

  const birthDetails = [
    listDetail('Date of birth', moment.utc(user.Disclosure.DateOfBirth).format('DD/MM/YYYY')),
    listDetail('Town of birth', user.Disclosure.TownOfBirth),
    listDetail('Country of birth', user.Disclosure.CountryOfBirthName),
    listDetail('Nationality', user.Disclosure.NationalityName)
  ]

  const additionalInformation = [
    listDetail('UK National insurance number', user.Disclosure.UkNationalInsuranceNumber),
    listDetail('Passport number', user.Disclosure.PassportNumber),
    listDetail('Passport country of issue', user.Disclosure.PassportCountryOfIssueName),
    listDetail('Driving licence', user.Disclosure.HasDrivingLicence ? 'Yes' : 'No'),
    listDetail('Driving licence number', user.Disclosure.DrivingLicenceNumber),
    listDetail(
      'Driving licence country of issue',
      user.CountryList.find(country => country.Id === user.Disclosure.DrivingLicenceCountryOfIssueId) &&
        user.CountryList.find(country => country.Id === user.Disclosure.DrivingLicenceCountryOfIssueId)
          .CountryName
    ),
    listDetail('National ID card', user.Disclosure.HasNationalIdentityCard ? 'Yes' : 'No'),
    listDetail(
      'National ID card country of issue',
      user.Disclosure.NationalIdentityCardCountryOfIssueId &&
        user.CountryList.find(country => country.Id === user.Disclosure.NationalIdentityCardCountryOfIssueId)
          .CountryName
    ),
    listDetail('National ID card number', user.Disclosure.NationalIdentityCardNumber)
  ]

  const contactInformation = [
    listDetail('Daytime phone number', user.Disclosure.DaytimePhoneNumber),
    listDetail('Evening phone number', user.Disclosure.EveningPhoneNumber),
    listDetail('Email address', user.Disclosure.EmailAddress),
    ...userAddresses
  ]

  return user.Loaded ? (
    <Layout>
      <Stepper steps={stepperSteps.disclosure} currentStep={5} />

      {user.DisclosureApiValidationErrors && (
        <ValidationAlertList errors={user.DisclosureApiValidationErrors} />
      )}

      <ContentWrap pad>
        <Base util={[padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
          <DetailsList
            title="Personal details"
            editLink="/disclosure/personal-details"
            listItems={personalDetails}
          />

          <DetailsList title="Birth details" editLink="/disclosure/birth-details" listItems={birthDetails} />

          <DetailsList
            title="Additional information"
            editLink="/disclosure/additional-information"
            listItems={additionalInformation}
          />

          <DetailsList
            title="Contact information"
            editLink="/disclosure/contact-information"
            listItems={contactInformation}
          />
        </Base>

        {DisclosureServiceType === 1 ? (
          <Base util={margin.t6}>
            <form onSubmit={handleSubmit}>
              <FormCheckboxInput
                name="DBSPrivacyPolicyConsent"
                onClick={user.handleChangeDisclosure}
                error={getErrorForField('DBSPrivacyPolicyConsent', ValidationErrors)}
                defaultChecked={user.Disclosure.DBSPrivacyPolicyConsent}
                value={!user.Disclosure.DBSPrivacyPolicyConsent}
                urlHint
                href="https://www.gov.uk/government/publications/basic-dbs-check-privacy-policy/basic-dbs-check-privacy-policy"
                urlHintText="DBS Privacy Policy"
                data-format="bool"
              >
                I have read the Privacy Policy and I understand how DBS will process my personal data.
              </FormCheckboxInput>
              <FormCheckboxInput
                name="DBSResultThirdPartyConsent"
                onClick={user.handleChangeDisclosure}
                error={getErrorForField('DBSResultThirdPartyConsent', ValidationErrors)}
                defaultChecked={user.Disclosure.DBSResultThirdPartyConsent}
                value={!user.Disclosure.DBSResultThirdPartyConsent}
                data-format="bool"
              >
                I agree to receive my e-result and share this with necessary third parties involved in the
                recruitment process.
              </FormCheckboxInput>
              <FormCheckboxInput
                name="DBSArnoldClarkStoreConsent"
                onClick={user.handleChangeDisclosure}
                error={getErrorForField('DBSArnoldClarkStoreConsent', ValidationErrors)}
                defaultChecked={user.Disclosure.DBSArnoldClarkStoreConsent}
                value={!user.Disclosure.DBSArnoldClarkStoreConsent}
                data-format="bool"
              >
                I agree that my data will be stored by Arnold Clark for 1 year.
              </FormCheckboxInput>

              <Button success block util={[width.resetSm, position.floatRight, margin.t3]} type="submit">
                Confirm Disclosure application
              </Button>
            </form>
          </Base>
        ) : (
          <Button
            success
            block
            util={[width.resetSm, position.floatRight, margin.t3]}
            onClick={e => {
              e.preventDefault()
              user.handleDisclosureCompleteStep(userTaskDisclosure.Id)
            }}
          >
            Confirm Disclosure application
          </Button>
        )}
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DisclosureConfirmComplete
