import moment from 'moment'

const overviewEmployeeDetails = data => {

  const formatDate = d => moment.utc(d).format('DD/MM/YYYY')

  if (!Object.keys(data).length) {
    return null
  }
 
  return [
    {
      dt: 'Employee ID',
      dd: +data.EmployeeId
    },
    {
      dt: 'First name',
      dd: `${data.EmployeeFirstname}`
    },
    {
      dt: 'Surname',
      dd: `${data.EmployeeSurname}`
    },
    {
      dt: 'Job title',
      dd: `${data.EmployeeJobTitle}`
    },
    {
      dt: 'Date Effective From',
      dd: `${formatDate(data.DateEffectiveFrom)}`
    },
    {
      dt: 'Work hours',
      dd: `${data.EmployeeWorkHours}`
    },
    {
      dt: 'Hiring manager',
      dd: `${data.EmployeeHiringManager}`
    },
    {
      dt: 'Location',
      dd: `${data.EmployeeLocation}`
    },
    {
      dt: 'Salary',
      dd: `${data.EmployeeSalary}`
    },
  ]
  
}
export default overviewEmployeeDetails
