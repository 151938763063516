import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from '@reach/router'

import './style.scss'

const Stepper = props => {
  const { currentStep, steps } = props

  const progressWidth = `${(100 / steps.length) * currentStep}%`

  const isDone = itemStep => {
    if (currentStep > itemStep || steps.length === currentStep) {
      return true
    }
    return false
  }

  const isCurrent = itemStep => {
    if (currentStep === itemStep) {
      return true
    }
    return false
  }

  return (
    <Fragment>
      <ul className="stepper">
        {steps.map(item => (
          <li
            key={item.step}
            className={classNames(
              'stepper__item',
              isCurrent(item.step) && 'stepper__item--current',
              isDone(item.step) && 'stepper__item--done'
            )}
          >
            {item.link ? (
              <Link
                to={item.link || ''}
                className={classNames(
                  'stepper__link',
                  isCurrent(item.step) && 'stepper__link--current',
                  isDone(item.step) && 'stepper__link--done'
                )}
              >
                <span
                  className={classNames(
                    'stepper__item-number',
                    isCurrent(item.step) && 'stepper__item-number--current',
                    isDone(item.step) && 'stepper__item-number--done'
                  )}
                >
                  {currentStep <= item.step && item.step}
                </span>

                <span
                  className={classNames(
                    'stepper__item-title',
                    isCurrent(item.step) && 'stepper__item-title--current',
                    isDone(item.step) && 'stepper__item-title--done'
                  )}
                >
                  {item.title}
                </span>
              </Link>
            ) : (
              <>
                <span
                  className={classNames(
                    'stepper__item-number',
                    isCurrent(item.step) && 'stepper__item-number--current',
                    isDone(item.step) && 'stepper__item-number--done'
                  )}
                >
                  {currentStep <= item.step && item.step}
                </span>

                <span
                  className={classNames(
                    'stepper__item-title',
                    isCurrent(item.step) && 'stepper__item-title--current',
                    isDone(item.step) && 'stepper__item-title--done'
                  )}
                >
                  {item.title}
                </span>
              </>
            )}
          </li>
        ))}
      </ul>

      <span className="stepper-progress" style={{ width: progressWidth }} />
    </Fragment>
  )
}

Stepper.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.instanceOf(Array)
}

export default Stepper
