import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { Button, Card, Grid, Form, Text, Alert, margin, position } from 'chassis-react'

import auth from '../../auth'

import Layout from '../Layout'
import api from '../../api'

class AuthGate extends Component {
  constructor(props) {
    super(props)

    const sessionValues = auth.getAppData()

    this.state = {
      isAdmin: sessionValues.IsAdmin,
      isUser: !sessionValues.IsAdmin,
      UserId: sessionValues.Id,
      AuthToken: sessionValues.AuthToken,
      AllowUser: false,
      AllowAdmin: false,
      Email: '',
      Password: '',
      loginFailed: false
    }
  }

  componentDidMount() {
    const { userAccess, adminAccess } = this.props
    const { isAdmin, isUser, UserId, AuthToken } = this.state

    if (userAccess && isUser && UserId && AuthToken) {
      this.setState({ AllowUser: true })
    }

    if (adminAccess && isAdmin && UserId && AuthToken) {
      this.setState({ AllowAdmin: true })
    }
  }

  handleAdminLoginClick = () => {
    auth.clearSession()
    // console.log('API URL = ' + process.env.API_URL)
    // eslint-disable-next-line no-restricted-globals
    api.employeeAuth.getAuthUrl(location.href).then(res => navigate(res.data))
  }

  handleOnChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmitUserLogin = e => {
    const { Email, Password } = this.state

    e.preventDefault()

    auth
      .userLogin(Email, Password)
      .then(res => {
        if (res.data.LoginData) {
          window.location.reload()
        }
      })
      .catch(() => this.setState({ loginFailed: true }))
  }

  render() {
    const { userAccess, adminAccess, children } = this.props
    const { AllowUser, AllowAdmin, loginFailed } = this.state


    if (userAccess) {
      return AllowUser ? (
        <div>{children}</div>
      ) : (
        <Layout>
          <Grid container>
            <Grid row>
              <Grid colSm={6} colMd={4} util={[position.centered, margin.t10]}>
                <Card>
                  <Form onSubmit={this.handleSubmitUserLogin}>
                    <Card content>
                      <Text h3 util={margin.b0}>
                        Login
                      </Text>
                    </Card>

                    <Card content grey>
                      {loginFailed && (
                        <Alert danger util={margin.b2}>
                          Please check your email and password.
                        </Alert>
                      )}
                      <Form group>
                        <Form label>Email</Form>
                        <Form input type="email" name="Email" onChange={this.handleOnChange} />
                      </Form>

                      <Form group>
                        <Form label>Password</Form>
                        <Form input type="password" name="Password" onChange={this.handleOnChange} />
                      </Form>
                    </Card>

                    <Card content>
                      <Button success block>
                        Login
                      </Button>

                      <Button 
                        link 
                        block 
                        type="button" 
                        onClick={() => navigate('/forgot-password')}
                      >
                        Forgot password
                      </Button>
                    </Card>
                  </Form>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      )
    }

    if (adminAccess) {
      return AllowAdmin ? (
        <div>{children}</div>
      ) : (
        <Layout>
          <Grid container>
            <Grid row>
              <Grid colSm={6} colMd={4} util={[position.centered, margin.t10]}>
                <Card>
                  <Card content>
                    <Text h3 util={margin.b0}>
                      Authentication required
                    </Text>
                  </Card>

                  <Card content grey>
                    <Text util={margin.b0}>
                      You must login through the Arnold Clark authentication portal to access this area.
                    </Text>
                  </Card>

                  <Card content>
                    <Button success block onClick={this.handleAdminLoginClick}>
                      Admin login
                    </Button>
                  </Card>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      )
    }
  }
}

AuthGate.propTypes = {
  children: PropTypes.node,
  userAccess: PropTypes.bool,
  adminAccess: PropTypes.bool
}

export default AuthGate
