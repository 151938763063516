import React from 'react'
import PropTypes from 'prop-types'
import { Grid, position, padding } from 'chassis-react'

const ContentWrap = props => {
  const { children, slim, thin, tiny, collapse, util, pad } = props
  const pageWidth = (slim && 7) || (thin && 6) || (tiny && 4) || 8
  const utils = [position.centered]

  if (util) {
    utils.push(util)
  }

  if (pad) {
    utils.push(padding.v4)
  }

  return (
    <Grid container util={[collapse && padding.h0]}>
      <Grid row>
        <Grid colMd={pageWidth} util={[utils]}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

ContentWrap.propTypes = {
  children: PropTypes.node,
  collapse: PropTypes.bool,
  pad: PropTypes.bool,
  slim: PropTypes.bool,
  thin: PropTypes.bool,
  tiny: PropTypes.bool,
  util: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Array)])
}

export default ContentWrap
