const overviewContractDocuments = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  ...data.map(item => ({
    dt: 'Document',
    dd: item.Title
  }))
]
}
export default overviewContractDocuments
