import React, { useContext } from 'react'
import { Button, Form, Text, margin, padding, position, width, shape } from 'chassis-react'
import { navigate } from '@reach/router'
import { UserContext } from '../../../../context/UserContext'
import stepperSteps from '../../../../config/stepperSteps'
import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormTextInput from '../../../../components/FormTextInput'

import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import imgSrc from '../../../../static/images/healthAndSafety/2.png'
import './style.scss'

const HealthQuestion1 = () => {
  const user = useContext(UserContext)

  const { Loaded, HandArmVibration, handleChangeTools, handleChangeFingersWhiteWhenExposedToCold } = user
  const {
    DiagnosedWithRaynaudsSyndrome,
    DiagnosedWithRaynaudsSyndromeDetails,
    AnyTinglingAfterUsingVibratingEquipment,
    TinglingAnyOtherTime,
    FingersWhiteWhenExposedToCold,
    NighttimePainTinglingOrNumbness,
    FingersWhiteAtAnyOtherTime,
    DifficultyGettingFingersWarm,
    OneOrMoreFingersNumbAfterUsingVibratingEquipment,
    FingersWhiteAtAnyOtherTimeDetails
  } = HandArmVibration

  const handleSubmit = e => {
    e.preventDefault()
    return navigate('/health-and-safety/health-question-2')
  }

  return Loaded ? (
    <Layout>
      {<Stepper steps={stepperSteps.healthAndSafetyQuestionnaire} currentStep={2} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>

            <Text util={[padding.t2]}>
              Have you been diagnosed with Raynaud’s syndrome (primary or secondary)?
            </Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: DiagnosedWithRaynaudsSyndrome === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: DiagnosedWithRaynaudsSyndrome === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="DiagnosedWithRaynaudsSyndrome"
              data-format="bool"
            />

            {DiagnosedWithRaynaudsSyndrome === true && (
              <>
                <Text util={[padding.t2]}>Please give details of this</Text>
                <Form hint>maximum length of 500 characters</Form>
                <FormTextInput
                  required
                  onChange={handleChangeTools}
                  name="DiagnosedWithRaynaudsSyndromeDetails"
                  textarea
                  value={DiagnosedWithRaynaudsSyndromeDetails}
                  rows="5"
                />
              </>
            )}

            <Text util={[padding.t2]}>
              Do you have any tingling of the fingers lasting more than 20 minutes after using vibrating
              equipment?
            </Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: AnyTinglingAfterUsingVibratingEquipment === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: AnyTinglingAfterUsingVibratingEquipment === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="AnyTinglingAfterUsingVibratingEquipment"
              data-format="bool"
            />

            <Text util={[padding.t2]}>Do you have tingling of the fingers at any other time?</Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: TinglingAnyOtherTime === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: TinglingAnyOtherTime === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="TinglingAnyOtherTime"
              data-format="bool"
            />

            <Text util={[padding.t2]}>
              Do you wake at night with pain, tingling, or numbness in your hand or wrist?
            </Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: NighttimePainTinglingOrNumbness === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: NighttimePainTinglingOrNumbness === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="NighttimePainTinglingOrNumbness"
              data-format="bool"
            />

            <Text util={[padding.t2]}>
              Do one or more of your fingers go numb more than 20 minutes after using vibrating equipment?
            </Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: OneOrMoreFingersNumbAfterUsingVibratingEquipment === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: OneOrMoreFingersNumbAfterUsingVibratingEquipment === false
                }
              ]}
              required
              onChange={handleChangeTools}
              name="OneOrMoreFingersNumbAfterUsingVibratingEquipment"
              data-format="bool"
            />

            <Text util={[padding.t2]}>Have any of your fingers gone white* when exposed to cold?</Text>
            <Form hint>
              Whiteness means a clear discolouration of the fingers with a sharp edge, usually next to a red
              flush.
            </Form>
            <img src={imgSrc} alt="" className="exposed-img" />
            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: FingersWhiteWhenExposedToCold === true
                },
                {
                  label: 'No',
                  value: false,
                  checked: FingersWhiteWhenExposedToCold === false
                }
              ]}
              required
              onChange={handleChangeFingersWhiteWhenExposedToCold}
              name="FingersWhiteWhenExposedToCold"
              data-format="bool"
            />
            {FingersWhiteWhenExposedToCold && (
              <>
                <Text util={[padding.t2]}>If yes, do you have difficulty getting them warm again?</Text>

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: DifficultyGettingFingersWarm === true
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: DifficultyGettingFingersWarm === false
                    }
                  ]}
                  onChange={handleChangeTools}
                  name="DifficultyGettingFingersWarm"
                  data-format="bool"
                  required
                />

                <Text util={[padding.t2]}>Do your fingers go white at any other time?</Text>

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: FingersWhiteAtAnyOtherTime === true
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: FingersWhiteAtAnyOtherTime === false
                    }
                  ]}
                  required
                  onChange={handleChangeTools}
                  name="FingersWhiteAtAnyOtherTime"
                  data-format="bool"
                />
                {FingersWhiteAtAnyOtherTime === true && (
                  <>
                    <Text util={[padding.t2]}>Please give details of this</Text>
                    <Form hint>maximum length of 500 characters</Form>
                    <FormTextInput
                      required
                      onChange={handleChangeTools}
                      name="FingersWhiteAtAnyOtherTimeDetails"
                      textarea
                      value={FingersWhiteAtAnyOtherTimeDetails}
                      rows="5"
                    />
                  </>
                )}
              </>
            )}
          </Panel>

          <Button
            type="buttom"
            success
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          >
            Next: Health questions 2
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default HealthQuestion1
