import React, { Component } from 'react'
import Layout from '../../../../components/Layout'

class Account extends Component {
  render() {
    return <Layout />
  }
}

export default Account
