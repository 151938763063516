import React from 'react'
import PropTypes from 'prop-types'

import { Base, Text, margin, padding } from 'chassis-react'

import FormSelectInput from '../../../../../components/FormSelectInput'
import FormTextInput from '../../../../../components/FormTextInput'
import Panel from '../../../../../components/Panel'

import getErrorForField from '../../../../../utilities/getErrorForField'
import handleBlur from '../../../../../utilities/handleBlur'

const sizeOptions = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'].map(size => <option key={size}>{size}</option>)

const ClothingForm = ({ user, errors, setErrors, schema }) => {
  const { Apprentice } = user

  return (
    <Panel borderedSm bgWhiteSm util={[margin.b3, padding.a3Sm]}>
      <Text h3>Uniform</Text>
      <Text>Please provide your size for:</Text>

      <Base util={margin.b3}>
        <FormTextInput
          error={getErrorForField('BootSize', errors)}
          onBlur={e => handleBlur(e, schema, Apprentice, setErrors)}
          id="BootSize"
          label="Boots"
          name="BootSize"
          type="number"
          min={3}
          max={15}
          value={Apprentice.BootSize}
          onChange={user.handleChangeApprentice}
        />
      </Base>

      <Base util={margin.b3}>
        <FormSelectInput
          error={getErrorForField('OverallsSize', errors)}
          onBlur={e => handleBlur(e, schema, Apprentice, setErrors)}
          id="OverallsSize"
          label="Overalls"
          name="OverallsSize"
          util={margin.b2}
          value={Apprentice.OverallsSize}
          onChange={user.handleChangeApprentice}
        >
          <option value="">Choose</option>
          {sizeOptions}
        </FormSelectInput>
      </Base>

      <Base util={margin.b3}>
        <FormSelectInput
          error={getErrorForField('PoloShirtsSize', errors)}
          onBlur={e => handleBlur(e, schema, Apprentice, setErrors)}
          id="PoloShirtsSize"
          label="Polo Shirts"
          name="PoloShirtsSize"
          util={margin.b2}
          value={Apprentice.PoloShirtsSize}
          onChange={user.handleChangeApprentice}
        >
          <option value="">Choose</option>
          {sizeOptions}
        </FormSelectInput>
      </Base>
    </Panel>
  )
}

ClothingForm.propTypes = {
  user: PropTypes.shape({
    Apprentice: PropTypes.shape({
      BootSize: PropTypes.string,
      OverallsSize: PropTypes.string,
      PoloShirtsSize: PropTypes.string
    })
  }),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      message: PropTypes.string
    })
  ),
  setErrors: PropTypes.func,
  schema: PropTypes.shape({})
}

export default ClothingForm
