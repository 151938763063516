const buildStyles = ({ hasError }) => ({
  container: base => ({
    ...base,
    ...(hasError ? { border: '3px solid #C23131' } : {})
  }),
  control: base => ({
      ...base,
      height: 50,
      width: '100%',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focus': {
        background: '#fdfef3'
      }
  }),
  dropdownIndicator: base => ({
      ...base,
      backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMiAxMiI+PHBhdGggZmlsbD0iIzk5OSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMjAuNzkzIDBjLS4zMiAwLS42MjguMTI2LS44NTMuMzQ5bC04Ljk2IDguNzkzTDIuMDIuMzVBMS4yMjQgMS4yMjQgMCAwIDAgLjM1NC4zODdhMS4xNyAxLjE3IDAgMCAwLS4wNCAxLjYzNmw5LjgxMiA5LjYzMmMuMjI3LjIyMi41MzQuMzQ3Ljg1NC4zNDcuMzIgMCAuNjI3LS4xMjUuODU0LS4zNDdsOS44MS05LjYzMmMuMzQ3LS4zMzguNDUxLS44NDguMjY1LTEuMjkxQTEuMjA3IDEuMjA3IDAgMCAwIDIwLjc5MyAweiIvPjwvc3ZnPg==)',
      color: 'transparent',
      backgroundPosition: 'right 14px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '18px 18px',
      '&:hover': {
      color: 'transparent',
      }
  })
});

export default buildStyles;
