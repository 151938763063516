import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Base, border, Button, color, Form, margin, padding, position, scale, Text} from 'chassis-react'
import { navigate } from '@reach/router'

import api from '../../../api'

import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import Layout from '../../../components/Layout'
import FormTextInput from '../../../components/FormTextInput'
import FormSelectInput from '../../../components/FormSelectInput'
import accessRole from '../../../services/accessRole'
import ValidationAlertList from '../../../components/ValidationAlertList'

import { deleteUserSchema } from './schema'

const Delete = (props) => {
  if (!accessRole.allow(['recruitment', 'itDev'])) return null

  const { id } = props
  const [user, setUser] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [allReasons, setAllReasons] = useState([])
  const [ValidationErrors, setValidationErrors] = useState([])
  const [submissionData, setSubmissionData] = useState({
    NewStartId: id,
    Reason: '',
    Comment: ''
  })


  const name = user && `${user.Firstname} ${user.Surname}`

  useEffect(() => {
    (async () => {
      await api.exitReason.getAll().then(({ data }) => setAllReasons(data))
      await api.newStart.getById(id).then(({ data }) => setUser(data))
      setLoaded(true)
    })()
  }, [])
  
  const deleteUser = event => {
    event.preventDefault()

    deleteUserSchema.validate(submissionData, { abortEarly: false })
      .then(() => {
        api.newStartExit.abort(submissionData)
          .then(() => navigate('/admin'))
          .catch(() => setValidationErrors([{
            message: 'Could not delete user. Please try again.'
          }]))
      })
      .catch(error => setValidationErrors(error.inner))
  }

  return loaded ? (
    <Layout>
      <ValidationAlertList errors={ValidationErrors} />

      <ContentWrap util={margin.t3}>
        <Text h1 util={scale.fs8}>
          Delete user
        </Text>
      </ContentWrap>

      <ContentWrap pad>
        <Base util={[margin.b3, padding.v4, padding.h3, color.bgWhite, border.a1, color.bcGrey3]}>
          <Text h5>{`You are deleting the new start ${name}.`}</Text>

          <FormSelectInput 
            label='Please choose a reason: *'
            onChange={e => setSubmissionData({ ...submissionData, Reason: e.target.value })}
            id="Reason"
            util={margin.b3}
          >
            <Form option value="">
              Select reason...
            </Form>
            
            {allReasons.map(item => (
              <Form option key={item.Id} value={item.Reason}>
                {item.Reason}
              </Form>
            ))}
          </FormSelectInput>

          <FormTextInput
            label='Please enter further information: *'
            name="comment"
            textarea
            rows="5"
            onChange={e => setSubmissionData({ ...submissionData, Comment: e.target.value })}
          />
        </Base>
        
        <Button
          danger
          onClick={deleteUser}
          util={[position.floatRight]}
        >
          Delete User
        </Button>

        <Button 
          secondary
          onClick={() => navigate(`/admin/new-start/${id}/overview`)}
          util={[margin.r3, position.floatRight]}
        >
          Cancel
        </Button>
      </ContentWrap>
    </Layout>
  ):(
    <ContentLoader />
  )
}

Delete.propTypes = {
  id: PropTypes.string
}

export default Delete