const getErrorForField = (fieldId, validationErrors) => {
  if (!fieldId || !validationErrors) {
    return ''
  }

  // handle array of validationErrors
  if (Array.isArray(validationErrors)) {
    const foundError = validationErrors.find(error => error.path === fieldId)
    return foundError ? foundError.message : ''
  }

  // handle single validationError
  return fieldId === validationErrors.path ? validationErrors.message : ''
}

export default getErrorForField
