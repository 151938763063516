import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const MedicalRoot = props => {
  const { children } = props

  return <Fragment>{children}</Fragment>
}

MedicalRoot.propTypes = {
  children: PropTypes.node
}

export default MedicalRoot
