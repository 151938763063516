const overviewMedical = data => {  
  if (!Object.keys(data).length) {
    return null
  }
  return [
  { dt: 'Number of days absent in the last 12 months', dd: data.NoDaysAbsentInLastTwelveMonths },
  { dt: 'How many periods of absence', dd: data.HowManyPeriodsOfAbsence },
  { dt: 'Details of each absence', dd: data.DetailsOfEachAbsence || 'No details' },
  { dt: 'Circulatory problems', dd: data.CirculatoryProblems ? 'Yes' : 'No' },
  { dt: 'Heart problems', dd: data.HeartProblems ? 'Yes' : 'No' },
  { dt: 'Chest problems', dd: data.ChestProblems ? 'Yes' : 'No' },
  { dt: 'Epilepsy or fainting attacks', dd: data.EpilepsyOrFainting ? 'Yes' : 'No' },
  { dt: 'Skin disorders', dd: data.SkinDisorders ? 'Yes' : 'No' },
  { dt: 'Recent operations or fractures', dd: data.OperationsOrFractures ? 'Yes' : 'No' },
  { dt: 'Any current medication', dd: data.AnyCurrentMedication ? 'Yes' : 'No' },
  { dt: 'Back problems, arthritis and rheumatism', dd: data.BackArthritisRheumatism ? 'Yes' : 'No' },
  { dt: 'Injury to bones, joints or tendons', dd: data.BonesJointsTendons ? 'Yes' : 'No' },
  { dt: 'Any other significant health problems', dd: data.OtherSignificantHealthProblems ? 'Yes' : 'No' },
  {
    dt: 'Have you worked in an industry with high noise levels',
    dd: data.WorkedWithHighNoiseLevels ? 'Yes' : 'No'
  },
  { dt: 'Have you ever made a claim for industrial injury', dd: data.IndustrialInjuryClaim ? 'Yes' : 'No' },
  { dt: 'Please give details of any conditions', dd: data.DetailsOfConditions || 'No details' },
  { dt: 'Any other conditions', dd: data.AnyOtherConditions || 'No Other Conditions' },
  { dt: 'Share with Managers', dd: data.ShareWithManagers ? 'Yes': 'No' }
]
}
export default overviewMedical
