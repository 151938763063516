import React, { useState, useContext } from 'react'
import { Button, Form, Grid, Text, margin, padding, position, shape, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'
import { AddressValidationSchema } from './schema'

import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'
import handleBlur from '../../../../utilities/handleBlur'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormSelectInput from '../../../../components/FormSelectInput'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const DetailsEditAddress = () => {
  const user = useContext(UserContext)
  const [ValidationErrors, setValidationErrors] = useState([])
  const { Line1, Line2, Town, Postcode } = Object.assign({}, user.Addresses[0])

  const handleSubmit = e => {
    e.preventDefault()

    AddressValidationSchema.validate({ Line1, Town, Postcode }, { abortEarly: false })
      .then(user.handleSubmitPersonalDetails)
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  return user.Loaded ? (
    <Layout>
      <ContentWrap slim pad>
        <Text h2>Edit address details</Text>

        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <Grid row>
              <Grid colSm={6}>
                <Form label>Postcode</Form>
                <Grid row>
                  <Grid colXs={12}>
                    <FormTextInput
                      name="Postcode"
                      type="text"
                      value={Postcode || ''}
                      onChange={user.handleChangeAddressDetails}
                      onBlur={e => handleBlur(e, AddressValidationSchema, {Line1, Town, Postcode}, setValidationErrors)}
                      error={getErrorForField('Postcode', ValidationErrors)}
                    />

                  </Grid>

                  <Grid colXs={6} util={margin.b3}>
                    <Button block onClick={e => user.handleAddressSearch(e, user.Addresses[0].Postcode)}>
                      Look up
                    </Button>
                  </Grid>
                </Grid>

                {user.AddressList.length > 0 && (
                  <FormSelectInput
                    name="AddressSelect"
                    label="Select Address"
                    value={Line1 || ''}
                    onChange={user.handleAddressSelect}
                  >
                    <option />

                    {user.AddressList.map((item, i) => (
                      <option key={item.Line1} value={i}>
                        {item.Line1}
                      </option>
                    ))}
                  </FormSelectInput>
                )}

                <FormTextInput
                  label="Line1"
                  name="Line1"
                  type="text"
                  value={Line1 || ''}
                  onChange={user.handleChangeAddressDetails}
                  onBlur={e => handleBlur(e, AddressValidationSchema, {Line1, Town, Postcode}, setValidationErrors)}
                  error={getErrorForField('Line1', ValidationErrors)}
                />

                <FormTextInput
                  label="Line2 (optional)"
                  name="Line2"
                  type="text"
                  value={Line2 || ''}
                  onChange={user.handleChangeAddressDetails}
                />

                <FormTextInput
                  label="Town"
                  name="Town"
                  type="text"
                  value={Town || ''}
                  onChange={user.handleChangeAddressDetails}
                  onBlur={e => handleBlur(e, AddressValidationSchema, {Line1, Town, Postcode}, setValidationErrors)}
                  error={getErrorForField('Town', ValidationErrors)}
                />
              </Grid>
            </Grid>
          </Panel>

          <Button success block util={[width.resetSm, position.floatRight]}>
            Update
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DetailsEditAddress
